/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */

//In this setting functionality, user can manage the settings. there are different setting options like general, special and girl settings.
//If user is kid then his all change setting request is sent to parent, if his parent is in night mode then kid is not able to sent request.
//If parent is in "Kid settings" then he also see the manage kid setting.
//For "Manage kid" setting "ManageKid.js" file will be running.

import { useEffect, useState } from "react";
import db, { auth } from "config/firebase";
import {
    handleDeniedActionReasons,
    handleShowChannelNames,
} from "shared/CommanFunction";
import { useSelector, useDispatch } from "react-redux";
import { LS_VARIABLE, TYPE_OF_STATUS } from "description/constant";
import { useRouter } from "hooks/use-router";
import { useTranslation } from "react-i18next";
import firebase from 'firebase/app'
const status = [TYPE_OF_STATUS.DENIED, TYPE_OF_STATUS.REQUESTED];
const successStatus = [TYPE_OF_STATUS.APPROVED]

export default function settings() {
    const { t } = useTranslation();
    const [blockAppleLogout, setBlockAppleLogout] = useState(false);
    const [blockAndroidLogout, setBlockAndroidLogout] = useState(false);
    const [urlDefaultPolicyApple, seturlDefaultPolicyApple] = useState("");
    const [appFuturePolicyAndroid, setappFuturePolicyAndroid] = useState("");
    const [appDefaultPolicyAndroid, setappDefaultPolicyAndroid] = useState("");
    const [urlDefaultPolicyAndroid, seturlDefaultPolicyAndroid] = useState("");
    // const [urlDefaultPolicyAndroidReason, seturlDefaultPolicyAndroidReason] = useState([]);
    // const [pendingRequestList, setPendingRequestList] = useState([]);
    const [deniedSettingRequest, setDeniedSettingRequest] = useState("");
    // const [openAlert, setOpenAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [DTAFlag, setDTAFlag] = useState(false);
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const { push } = useRouter();
    const userInfo = JSON.parse(
        localStorage.getItem(LS_VARIABLE.LOGGED_USER_INFO)
    );
    // eslint-disable-next-line no-unused-vars
    const [deviceId, setDeviceId] = useState("");
    let actionsChanges = db.collection(
        `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions`
    );
    const { selectedId, selectedCategory } = useSelector((state) => state.users);
    const userSettingInfo = useSelector(
        (state) => state.users.userList[selectedId]
    );
    const parentSettingInfo = useSelector(
        (state) => state.users.userList[userInfo?.email]
    );
    // useEffect(() => {
    //   if (userInfo && Object.keys(userInfo)?.length) {
    //     handelGetPendingStatusDataList();
    //   }
    // }, []);

    useEffect(() => {
        handleSetUpdate();
        // handelGetPendingStatusDataList();
    }, [userSettingInfo, DTAFlag, parentSettingInfo]);

    useEffect(() => {
        if (loading) {
            // setOpenAlert(true);
            handleSetUpdate();
        }
    }, [loading]);

    useEffect(() => {
        getUpdatedStatus();
    }, []);

    // useEffect(() => {
    //   if (deniedSettingRequest) {
    //     handelGetPendingStatusDataList();
    //   }
    // }, [deniedSettingRequest]);

    //For adding requests which is currently pending.
    // const handleUpdatePendingRequestData = (value) => {
    //   setPendingRequestList([...pendingRequestList, value]);
    // };

    const handleLogout = () => {
        try {
            auth.signOut();
            dispatch({
                type: "SET_LOGIN_FLAG",
                payload: false,
            });
            dispatch({
                type: "PURGE",
            });
            localStorage.removeItem(LS_VARIABLE.LOGGED_USER_INFO);
            localStorage.removeItem(LS_VARIABLE.DEVICE);
            localStorage.removeItem(LS_VARIABLE.IDS);
            dispatch({
                type: "SET_USER_DATA",
                payload: {},
            });
        } catch (error) {
            console.error(`error`, error);
        }
    };
    //For updating the value of settings.
    const handleSetUpdate = () => {
        if (userSettingInfo && Object.keys(userSettingInfo)?.length) {
            const { blockAppleLogout, blockAndroidLogout, urlDefaultPolicyApple, appFuturePolicyAndroid,
                appDefaultPolicyAndroid, urlDefaultPolicyAndroid } = userSettingInfo;
            setBlockAppleLogout(blockAppleLogout);
            setBlockAndroidLogout(blockAndroidLogout)
            seturlDefaultPolicyApple(urlDefaultPolicyApple);
            setappFuturePolicyAndroid(appFuturePolicyAndroid);
            setappDefaultPolicyAndroid(appDefaultPolicyAndroid);
            seturlDefaultPolicyAndroid(urlDefaultPolicyAndroid);
            // seturlDefaultPolicyAndroidReason(urlDefaultPolicyAndroid_reason)
            loading && setLoading(false);
        }
    };
    const urlDefaultPolicyAndroidReason = userSettingInfo?.urlDefaultPolicyAndroid_reason || []
    const urlDefaultPolicyAppleReason = userSettingInfo?.urlDefaultPolicyApple_reason || []
    const blockAndroidLogoutReason = userSettingInfo?.blockAndroidLogout_reason || []
    const blockAppleLogoutReason = userSettingInfo?.blockAppleLogout_reason || []
    const appDefaultPolicyAndroidReason = userSettingInfo?.appDefaultPolicyAndroid_reason || []
    const appFuturePolicyAndroidReason = userSettingInfo?.appFuturePolicyAndroid_reason || []
    const deleteAccountReason = userSettingInfo?.deleteAccount_reason || []

    //To get updated status of action which is pass for changing the settings
    const getUpdatedStatus = () => {
        actionsChanges.onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {

                if (change.type === "modified") {
                    console.log("change", change.doc.data())
                    if (change.doc.data()?.actionType.includes('deleteMyAccount') &&
                        successStatus.includes(change.doc.data()?.status)) {
                        handleLogout()
                        push("/login")
                    }
                    if (
                        change.doc.data()?.type &&
                        change.doc.data()?.type.includes("Settings") &&
                        status.includes(change.doc.data()?.status)
                    ) {
                        if (change.doc.data()?.name === "removeKid") {
                            setLoader(false);
                        }
                        !loading && setLoading(true);
                        JSON.stringify(deniedSettingRequest) !==
                            JSON.stringify(change.doc.data()) &&
                            setDeniedSettingRequest(change.doc.data());
                    }
                    else if (
                        change.doc.data()?.actionType.includes('deleteMyAccount') &&
                        status.includes(change.doc.data()?.status)
                    ) {
                        !loading && setLoading(true);
                        JSON.stringify(deniedSettingRequest) !==
                            JSON.stringify(change.doc.data()) &&
                            setDeniedSettingRequest(change.doc.data());
                    }
                    else {
                        if (change.doc.data()?.name === "removeKid") {
                            setLoader(false);
                            dispatch({
                                type: "SET_KID_REMOVED_MESSAGE",
                                payload: t("kidRemoved_i18n"),
                            });
                            push("/u");
                        }
                    }
                }
            });
        });
    };

    //For passing action of changing settings
    const handleCallActions = async (value, type) => {
        type === "removeKid" && !loader && setLoader(true);
        // openAlert && setOpenAlert(false);
        const date = new Date().getTime();
        const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date}-${type}`;
        const payload = {
            // userId: userInfo?.id,
            userId:
                type === "manageKidAccess" || type === "removeKid"
                    ? userInfo?.email
                    : parseInt(userSettingInfo?.userId),
            actionId: date + "-" + type,
            actionType: `${type}:${value}`,
            status: TYPE_OF_STATUS.PENDING,
            type: "Settings",
            name: type === "girlMode" ? value : type,
            createdTime: new Date(),
        };
        // handleUpdatePendingRequestData(payload);
        db.doc(databaseUrl).set(payload);
    };

    //For delete user account
    const handleDeleteActions = async () => {
        let type = 'deleteMyAccount'
        // openAlert && setOpenAlert(false);
        const date = new Date().getTime();
        const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date}-${type}`;
        const payload = {
            userId: userInfo?.email,
            actionId: date + "-" + type,
            actionType: `${type}:${userInfo?.email}`,
            status: TYPE_OF_STATUS.PENDING,
            name: 'Delete account',
            reason: '',
            createdTime: new Date(),
        };
        // handleUpdatePendingRequestData(payload);
        db.doc(databaseUrl).set(payload);
        const userSettingRef = db.collection("Google users").doc(userInfo?.email.toString());

        const updateData = {
            [`${type}_reason`]: firebase.firestore.FieldValue.arrayUnion('pending')
        };

        try {
            await userSettingRef.set(updateData, { merge: true });
        } catch (error) {
            console.error("Error updating document:", error);
        }
    };
    //To get actions which is in PENDING status
    // const handelGetPendingStatusDataList = async () => {
    //   const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions`;
    //   let arrayInfo = [];
    //   await db.collection(process.env.REACT_APP_FIREBASE_COLLECTION).doc(userInfo?.email)
    //     .collection('actions')
    //     .where("status", "==", TYPE_OF_STATUS.PENDING)
    //     .where("type", "==", "Settings")
    //     .get()
    //     .then((snapshot) => {
    //       if (snapshot.docs.length) {
    //         snapshot.docs.forEach((d, i) => {
    //         //  if(d?.status ==  TYPE_OF_STATUS.PENDING && d?.type =='Settings' ){
    //           arrayInfo.push(snapshot.docs[i].data());
    //           arrayInfo[i].id = snapshot.docs[i].id;
    //         //  }
    //         });
    //       }
    //     }).catch(e=>console.log("1",e))
    //   // setPendingRequestList(arrayInfo);
    // };

    //For displaying the checkboxes when request is pending and loader is running.
    // const handleDisabledCheckBox = (type, value) => {
    //   let flagValue = false;
    //   if (value && pendingRequestList && pendingRequestList.length) {
    //     pendingRequestList.forEach((data) => {
    //       console.log(data)
    //       if (!flagValue && type == data.name){ if (data?.actionType.includes(value) || data.name=="manageKidAccess") flagValue = true;}
    //     });
    //   }
    //   return flagValue;
    // };

    // const handleDefaultPolicyDisabled = (value) => {
    //   let flagValue = false;
    //   if (value && pendingRequestList && pendingRequestList.length) {
    //     flagValue = pendingRequestList.some(({ name }) => name === value);
    //   }
    //   return flagValue;
    // };

    const checkReason = (reason) => {
        if (reason && reason.length > 0) {
            if (reason.filter(name => typeof name != 'object' && name.includes('premium'))[0]) {
                return "reason-premium";
            }
            else if (reason.filter(name => typeof name != 'object' && name.includes('deniedByHumanParent'))[0]) {
                return "reason-deniedByHumanParent";
            }
            else if (reason.filter(name => typeof name != 'object' && name.includes('deniedSimpleLock'))[0]) {
                return "reason-deniedSimpleLock";
            }
            else if (reason.filter(name => typeof name != 'object' && name.includes('pending'))[0]) {
                return "reason-pending";
            }
            else if (reason.filter(name => typeof name != 'object' && name.includes('melting'))[0]) {
                return "reason-melting";
            }
            else if (reason.filter(name => typeof name != 'object' && name.includes('sentToHumanParent'))[0]) {
                return "reason-sentToHumanParent";
            }
            else if (reason.filter(name => typeof name != 'object' && name.includes('morning'))[0]) {
                return "reason-morning";
            } else if (reason.filter(name => typeof name != 'object' && name.includes('night'))[0]) {
                return "reason-night";
            }
        }
        return "";
    }

    //For closing the alert notification.
    const handleCloseAlert = () => {
        // setOpenAlert(false);
        setDeniedSettingRequest("");
    };

    const handleChangeSetting = async (type, value) => {
        const date = new Date().getTime();
        const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date}-${type}`;
        const payload = {
            userId: userSettingInfo?.email,
            actionId: date + "-" + type,
            actionType: `${type}:${value}`,
            status: TYPE_OF_STATUS.PENDING,
            type: "Settings",
            name: type,
            createdTime: new Date(),
        };
        // handleUpdatePendingRequestData(payload);
        db.doc(databaseUrl).set(payload);
        const userSettingRef = db.collection("Google users").doc(userSettingInfo?.email.toString());

        const updateData = {
            [`${type}_reason`]: firebase.firestore.FieldValue.arrayUnion('pending')
        };

        try {
            await userSettingRef.set(updateData, { merge: true });
        } catch (error) {
            console.error("Error updating document:", error);
        }

    };


    const handleOkbutton = async (key, reasons) => {
        let reason = checkReason(reasons).split('-')[1]
        const userSettingRef = db.collection("Google users").doc(userSettingInfo?.email.toString());

        const updateData = {
            [`${key}_reason`]: firebase.firestore.FieldValue.arrayRemove(reason)
        };

        try {
            await userSettingRef.set(updateData, { merge: true });
        } catch (error) {
            console.error("Error updating document:", error);
        }

    };

    return {
        userSettingInfo,
        // handleDisabledCheckBox,
        selectedCategory,
        // openAlert,
        handleShowChannelNames,
        deniedSettingRequest,
        handleDeniedActionReasons,
        handleCloseAlert,
        loader,
        handleCallActions,
        selectedId,
        userInfo,
        handleOkbutton,
        // pendingRequestList,
        blockAppleLogout,
        blockAndroidLogout,
        handleChangeSetting,
        urlDefaultPolicyApple,
        appFuturePolicyAndroid,
        appDefaultPolicyAndroid,
        urlDefaultPolicyAndroid,
        urlDefaultPolicyAndroidReason,
        urlDefaultPolicyAppleReason,
        blockAndroidLogoutReason,
        blockAppleLogoutReason,
        appDefaultPolicyAndroidReason,
        appFuturePolicyAndroidReason,
        // handleDefaultPolicyDisabled,
        handleDeleteActions,
        checkReason,
        deleteAccountReason
    };
}
