import React, {useState,useEffect,useRef} from "react"
import { useLocation } from "react-router-dom";
import { LS_VARIABLE, USER_TYPE } from "description/constant";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spin, Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function ApprovalLists() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [loading,setLoading]= useState(true);
  const iframeRef = useRef(null);
  const paramsData = pathname.split('/')
  const role = paramsData[2]
  const id = paramsData[3]
  // http://localhost:8080/u/myaccounts/0/whitelist/teleteens.co@gmail.com
  const loggedUserInfo = JSON.parse(localStorage.getItem(LS_VARIABLE.LOGGED_USER_INFO));
  // const iframeSrc = `http://localhost:8080/u/${role}/${id}/whitelist/${loggedUserInfo?.email}`
  const iframeSrc = `https://whitelist.lockteens.com/u/${role}/${id}/whitelist/${loggedUserInfo?.email}`

  const hideLoader= () =>setLoading(false)

  useEffect(() => {
    if(role === 'myparents') setLoading(false)
  },[role])

// useEffect(() => {
    
//     const timeoutId = setTimeout(() => {
//       console.log("Time Out");

//       if (iframeRef.current) {
//         iframeRef.current.contentWindow.postMessage(
//           JSON.stringify({
//             type: 'AUTH_DATA',
//             token: JSON.parse(localStorage.getItem(LS_VARIABLE.LOGGED_USER_INFO)),
//             userToken: localStorage.getItem(LS_VARIABLE.FB_USER_TOKEN),
//             userResult: JSON.parse(localStorage.getItem(LS_VARIABLE.GOOGLE_RESULT))
//           }),
//           '*'
//         );
//       }
//     }, 2000);

//     // Cleanup function
//     return () => {
//       console.log("Cleanup effect");

//       // Clear the timeout to avoid memory leaks
//       clearTimeout(timeoutId);
//     };

//   }, []);

  
  return (
    <>
    {loading && <div className="wishlist-loader-section"><Spin indicator={<LoadingOutlined />}></Spin></div>}
      {role === 'myparents'? (
        <div className="row mb-3" style={{
          marginTop: '64px',
          marginLeft: '112px',
          overflow: 'hidden'
        }}>{t("notAllowed_i18n")}</div>
       ) : 
      <div className="iframe-vue" style={{
        marginTop: '64px',
        marginLeft: '112px'
      }}>
        <iframe style={{
          height: '100vh',
          width: "100%"
        }} src={iframeSrc}  ref={iframeRef} id="whitelistIframe" title="web-youteens" onLoad={() => {
          
          setTimeout(() => {
            
            if (iframeRef.current) {

              const SEND_DATA_TO_VUE =  JSON.stringify({
                type: 'AUTH_DATA',
                token: JSON.parse(localStorage.getItem(LS_VARIABLE.LOGGED_USER_INFO)),
                userToken: localStorage.getItem(LS_VARIABLE.FB_USER_TOKEN),
                userResult: JSON.parse(localStorage.getItem(LS_VARIABLE.GOOGLE_RESULT))
              });

              iframeRef.current.contentWindow.postMessage(
                SEND_DATA_TO_VUE,
                '*'
              );
            }
            hideLoader()
          },1500)
    

        }
       
        }/>
      </div>
      }
    </>  
  );
}
