import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";
import { LS_VARIABLE } from "description/constant";
import { getLanguageCountry,changeLanguage } from "shared/CommanFunction";

const LanguageSwitcher = () => {
  const [selected, setSelected] = useState("");

  const { i18n } = useTranslation();

  // useEffect(() => {
  //   document.body.setAttribute(
  //     "dir",
  //     ["en", "es", "hi", "ru", "pt", "uk"].includes(i18n.language || "en")
  //       ? "ltr"
  //       : "rtl"
  //   );
  // }, [selected, i18n.language]);

  const changeLang = (code) => {
    setSelected(code);
    switch (code) {
      case "US":
        changeLanguage("en", i18n);
        break;
      case "IL":
        changeLanguage("he",i18n);
        break;
      case "ES":
        changeLanguage("es",i18n);
        break;
      case "RU":
        i18n.changeLanguage("ru",i18n);
        break;
      case "IN":
        changeLanguage("hi",i18n);
        break;
      case "PT":
        changeLanguage("pt",i18n);
        break;
      case "UA":
        changeLanguage("uk",i18n);
        break;
      case "IR":
        changeLanguage("fa",i18n);
        break;
      case "AE":
        changeLanguage("ar",i18n);
        break;
      default:
        changeLanguage("en",i18n);
    }
  };

  return (
    <div className="language-switcher">
      <ReactFlagsSelect
        countries={["US", "IL", "ES", "RU", "PT", "IN", "UA", "AE", "IR"]}
        customLabels={{
          US: "Eng",
          ES: "Esp",
          IL: "He",
          IN: "Hi",
          RU: "Rus",
          PT: "Pt",
          UA: "UA",
          AE: "Ae",
          IR: "Ir",
        }}
        selectedSize={24}
        optionsSize={18}
        showSelectedLabel={false}
        showOptionLabel={false}
        placeholder="Select Language"
        selected={
          selected ||
          getLanguageCountry(
            localStorage.getItem(LS_VARIABLE.I18_NEXT_LANGUAGE)
          ) ||
          "US"
        }
        onSelect={(code) => changeLang(code)}
        className="language-list"
      />
    </div>
  );
};

export default LanguageSwitcher;
