/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */

//This is night mode hours functionality, user can set night mode hours and free from all the notifications and requests.
//If user is kid then his request is sent to parent, if his parent is in night mode then kid is not able to sent request.

import { useEffect, useState } from "react";
import db from "config/firebase";
import { handleDeniedActionReasons } from "shared/CommanFunction";
import { useSelector } from "react-redux";
import { scheduleList, TYPE_OF_STATUS, TYPE_OF_ACTION } from "description/constant";

export default function nightModeHours() {
  const [saveButtonFlag, setSaveButtonFlag] = useState(true);
  const [scheduleLists, setScheduleLists] = useState(scheduleList.slice());
  const [nightModesTimeList, setNightModesTimeList] = useState({});
  const [addNightTimeList, setAddNightTimeList] = useState([]);
  const [removeNightTimeList, setRemoveNightTimeList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [deniedNightHourSlot, setDeniedNightHourSlot] = useState("");
  const [openAlert, setOpenAlert] = useState(false);


  const { selectedCategory, selectedId, userInfo } = useSelector((state) => state.users);
  const userSettingInfo = useSelector((state) => state.users.userList[selectedId]);
  
  const { nightModeItems } = userSettingInfo || { nightModeItems: {} };
  const {timeZone} = userSettingInfo || null;
  let actionsChanges = db.collection(
    `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions`
  );
  const allDaySelected = "all";

  useEffect(() => {
    handleSetNightModes();
  }, [nightModeItems]);

  useEffect(() => {
    if (loading) {
      loader && setLoader(false);
      !openAlert && setOpenAlert(true);
      !openAlert &&
        setTimeout(() => {
          setOpenAlert(false);
          setLoading(false);
        }, 3000);
      handleSetNightModes();
    }
  }, [loading]);

  // useEffect(() => {
  //   if (nightModesTimeList && nightModesTimeList.length) {
  //     let cloneList = scheduleList.slice(),
  //       min = null,
  //       max = null;
  //     nightModesTimeList.forEach((info) => {
  //       min = Number(info.startTime);
  //       max = Number(info.endTime);
  //       scheduleList.forEach((data, i) => {
  //         if (Number(data.value) > min && Number(data.value) <= max) {
  //           cloneList[i].className = "select";
  //           cloneList[i].default = true;
  //         }
  //       });
  //     });
  //     if (nightModesTimeList.length === 24) {
  //       scheduleList.forEach((data, i) => {
  //         cloneList[i].className = "select";
  //         cloneList[i].default = true;
  //       });
  //     }
  //     setScheduleLists(cloneList);
  //   } else {
  //     setScheduleLists(scheduleList);
  //   }
  // }, [nightModesTimeList, loading]);

  useEffect(() => {
    if (
      addNightTimeList.length ||
      addNightTimeList.includes(allDaySelected) ||
      removeNightTimeList.length
    ) {
      saveButtonFlag && setSaveButtonFlag(false);
    } else {
      !saveButtonFlag && setSaveButtonFlag(true);
    }
  }, [addNightTimeList, removeNightTimeList]);

  useEffect(() => {
    getUpdatedStatus();
  });

  const handleSetNightModes = () => {
    if (nightModeItems && Object.entries(nightModeItems).length) {
      setNightModesTimeList(nightModeItems);
    } else {
      Object.entries(nightModeItems).length && setNightModesTimeList({});
    }
    // !saveButtonFlag && setSaveButtonFlag(true);
    // addNightTimeList.length && setAddNightTimeList([]);
    // removeNightTimeList.length && setRemoveNightTimeList([]);
    // isModalVisible && setIsModalVisible(false);
    // loader && setLoader(false);
  };
  const handleState=(key,obj,action)=>{
    if(action=='add'){
      // setNightModeObj({...nightModeObj,[key]:nightModeObj.key.push(obj)})
      // setNightModeObj({
      //   ...nightModeObj,
      //   [key]:[...nightModeObj[key],obj],  
      // })
      return {
        ...nightModesTimeList,
        [key]:[...nightModesTimeList[key],obj],  
      }
    }
    else if(action=='remove'){
      //  setNightModeObj({
      //   ...nightModeObj,
      //   [key]:nightModeObj[key].filter((item) => item.key !== obj.key)  
      // })
      return {
        ...nightModesTimeList,
        [key]:nightModesTimeList[key].filter((item) => item.key !== obj.key) 
      }
    }
    else{
      return 
    }
  }
  //get updated status of action which is passed for set the night hours
  const getUpdatedStatus = () => {
    actionsChanges.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "modified") {
          if (
            change.doc.data()?.actionType &&
            (change.doc.data()?.actionType.includes(TYPE_OF_ACTION.NIGHT_MODE) ||
              change.doc.data()?.actionType.includes(TYPE_OF_ACTION.REMOVE_NIGHT_MODE)) &&
            [TYPE_OF_STATUS.DENIED, TYPE_OF_STATUS.REQUESTED].includes(change.doc.data()?.status)
          ) {
            !loading && setLoading(true);
            loader && setLoader(false);

            JSON.stringify(deniedNightHourSlot) !== JSON.stringify(change.doc.data()) &&
              setDeniedNightHourSlot(change.doc.data());
          }
        }
      });
    });
  };

  //For adding the night mode hours.
  const addNightTimeHours = (value, index) => {
    let cloneAddNightTimeList = addNightTimeList.slice();
    let cloneScheduleLists = scheduleLists.slice();
    const alreadyExist = cloneAddNightTimeList.includes(value?.value);
    if (alreadyExist) {
      const indexValue = cloneAddNightTimeList.findIndex((time) => time === value?.value);
      if (~indexValue) {
        if (value?.value === allDaySelected) {
          for (var i = 0; i < cloneScheduleLists.length; i++) {
            cloneAddNightTimeList.length = 0;
            cloneScheduleLists[i].className = "";
          }
        } else {
          cloneAddNightTimeList.splice(indexValue, 1);
          cloneScheduleLists[index].className = "";
        }
      }
    } else {
      if (value?.value === allDaySelected) {
        if (!nightModeItems?.length) {
          for (var j = 0; j < cloneScheduleLists.length; j++) {
            cloneAddNightTimeList.push(cloneScheduleLists[j]?.value);
            cloneScheduleLists[j].className = "select";
          }
        } else {
          handleManageModal();
        }
      } else {
        cloneAddNightTimeList.push(value?.value);
        cloneScheduleLists[index].className = "select";
      }
    }
    setAddNightTimeList(cloneAddNightTimeList);
    setScheduleLists(cloneScheduleLists);
    // }
  };

  const removeNightTimeHours = (value, index) => {
    const temp = scheduleLists?.filter(
      (el) => el.value === value?.value && el.className && el.default
    );
    let cloneRemoveNightTimeList = removeNightTimeList.slice();
    if (temp?.length) {
      if (removeNightTimeList.includes(value?.value)) {
        const index = cloneRemoveNightTimeList.indexOf(value?.value);
        cloneRemoveNightTimeList.splice(index, 1);
      } else {
        if (!removeNightTimeList.includes(allDaySelected)) {
          cloneRemoveNightTimeList.push(value?.value);
        }
      }
    }
    setRemoveNightTimeList(cloneRemoveNightTimeList);
  };

  const handleManageModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  //For save button which is pass the action.
  const handleSaveNightHours = () => {
    if (addNightTimeList?.length) {
      if (addNightTimeList && addNightTimeList.includes(allDaySelected) && nightModeItems.length) {
        handleManageModal();
      } else {
        const temp = addNightTimeList.map((el) => {
          return `${el - 1}_${el}`;
        });
        if (temp && temp.length) {
          handleCallNightHourActions(temp);
        } else {
          handleManageModal();
        }
      }
    }
    if (removeNightTimeList?.length) {
      var removeNightMode = [];
      if (removeNightTimeList.includes(allDaySelected)) {
        removeNightMode = nightModeItems?.map((val) => val.key);
      } else {
        removeNightMode = nightModeItems
          ?.filter((el) => removeNightTimeList.includes(Number(el.endTime)))
          .map((val) => val.key);
      }
      removeNightMode?.length && removeNightHourActions(JSON.stringify(removeNightMode));
    }
  };

  //For pass the action of set night modes
  const handleCallNightHourActions = (arr) => {
    openAlert && setOpenAlert(false);
    setLoader(true);
    const date = new Date().getTime();
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date}-${TYPE_OF_ACTION.NIGHT_MODE}`;
    db.doc(databaseUrl).set({
      userId: userSettingInfo?.email,
      actionId: `${date}-${TYPE_OF_ACTION.NIGHT_MODE}`,
      actionType: `${TYPE_OF_ACTION.NIGHT_MODE}:${JSON.stringify(arr)}`,
      status: TYPE_OF_STATUS.PENDING,
      createdTime: new Date(),
      type: "NightMode",
    });
  };

  const removeNightHourActions = (key) => {
    openAlert && setOpenAlert(false);
    setLoader(true);
    const date = new Date().getTime();
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${
      userInfo?.email
    }/actions/${date}-${TYPE_OF_ACTION.REMOVE_NIGHT_MODE}-${Math.floor(Math.random() * 1000000)}`;
    db.doc(databaseUrl).set({
      userId: userSettingInfo?.email,
      actionId: `${date}-${TYPE_OF_ACTION.REMOVE_NIGHT_MODE}`,
      actionType: `${TYPE_OF_ACTION.REMOVE_NIGHT_MODE}:${key}`,
      status: TYPE_OF_STATUS.PENDING,
      createdTime: new Date(),
      type: TYPE_OF_ACTION.REMOVE_NIGHT_MODE,
    });
  };

  const handleTimeZoneChange = (event)=>{
    let value=event.target.value
    const date = new Date().getTime();
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date}-${TYPE_OF_ACTION.CHANGE_TIME_ZONE}`;
    db.doc(databaseUrl).set({
      userId: userSettingInfo?.email,
      actionId: `${date}-${TYPE_OF_ACTION.CHANGE_TIME_ZONE}`,
      actionType: `${TYPE_OF_ACTION.CHANGE_TIME_ZONE}:${value}`,
      name:"change time zone",
      status: TYPE_OF_STATUS.PENDING,
      createdTime: new Date(),
      reason:'' ,
      detail:null
    });
  }

  const AddTimeActions = (key,obj) => {
    const date = new Date().getTime();
    obj.key=`${date}-${TYPE_OF_ACTION.ADD_NIGHT_MODE}`
    let newObj= handleState(key,obj,'add')
    obj.isApproved=false

    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date}-${TYPE_OF_ACTION.ADD_NIGHT_MODE}`;
    db.doc(databaseUrl).set({
      userId: userSettingInfo?.email,
      actionId: `${date}-${TYPE_OF_ACTION.ADD_NIGHT_MODE}`,
      actionType: `${TYPE_OF_ACTION.ADD_NIGHT_MODE}:${date}-${TYPE_OF_ACTION.ADD_NIGHT_MODE}`,
      name:"add night mode",
      status: TYPE_OF_STATUS.PENDING,
      createdTime: new Date(),
      type: TYPE_OF_ACTION.ADD_NIGHT_MODE,
      nightRequestDetail:newObj

    });
    
    db.collection("Google users").doc(userSettingInfo?.email?.toString())
      .set({ nightModeItems:{
        ...nightModesTimeList,
        [key]:[...nightModesTimeList[key],obj],  
      } }, { merge: true});
  };
  const RemoveTimeActions = (key,obj) => {
    // openAlert && setOpenAlert(false);
    // setLoader(true);

    const date = new Date().getTime();
    let newObj= handleState(key,obj,'remove')
    
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date}-${TYPE_OF_ACTION.REMOVE_NIGHT_MODE}`;
    db.doc(databaseUrl).set({
      userId: userSettingInfo?.email,
      actionId: `${date}-${TYPE_OF_ACTION.REMOVE_NIGHT_MODE}`,
      actionType: `${TYPE_OF_ACTION.REMOVE_NIGHT_MODE}:${obj.key}`,
      name:"remove night mode",
      status: TYPE_OF_STATUS.PENDING,
      createdTime: new Date(),
      type: TYPE_OF_ACTION.REMOVE_NIGHT_MODE,
      nightRequestDetail:newObj
    });
    const existingNightModes = nightModesTimeList[key] || [];
    const updatedNightModes = existingNightModes.map((item) => {
      if (item.key === obj.key) {
        return { ...item, reason: (item?.reason || []).concat('pending') };
      }
      return item;
    });
  
    db.collection("Google users")
      .doc(userSettingInfo?.email?.toString())
      .set({ nightModeItems: { ...nightModesTimeList, [key]: updatedNightModes } }, { merge: true });
  };

  const handleOkbutton = async (key,obj) => {
    let reason = checkReason(obj.reason).split('-')[1]
    console.log("reason",reason)
    const existingNightModes = nightModesTimeList[key] || [];
    const updatedNightModes = existingNightModes.map((item) => {
      if (item.key === obj.key) {
        return { ...item, reason: (item?.reason || []).filter(r => r !== reason)};
      }
      return item;
    });
  
    db.collection("Google users")
      .doc(userSettingInfo?.email?.toString())
      .set({ nightModeItems: { ...nightModesTimeList, [key]: updatedNightModes } }, { merge: true });

  };

  //For closing the alert notification
  const handleCloseAlert = () => {
    setOpenAlert(false);
    setLoading(false);
  };

  const checkReason=(reason)=> {
    if (reason && reason.length > 0) {
      if (reason.filter(name => typeof name != 'object' && name.includes('premium'))[0]) {
        return "reason-premium";
      }
      else if (reason.filter(name =>  typeof name != 'object' && name.includes('deniedByHumanParent'))[0]) {
        return "reason-deniedByHumanParent";
      } 
       else if (reason.filter(name =>  typeof name != 'object' &&name.includes('deniedSimpleLock'))[0]) {
        return "reason-deniedSimpleLock";
      } 
      else if (reason.filter(name => typeof name != 'object' && name.includes('pending'))[0]) {
        return "reason-pending";
      }
      else if (reason.filter(name => typeof name != 'object'&& name.includes('melting'))[0]) {
        return "reason-melting";
      }
      else if (reason.filter(name => typeof name != 'object' && name.includes('sentToHumanParent'))[0]) {
        return "reason-sentToHumanParent";
      }    
      else if (reason.filter(name =>  typeof name != 'object' &&name.includes('morning'))[0]) {
        return "reason-morning";
      } else if (reason.filter(name =>  typeof name != 'object' &&name.includes('night'))[0]) {
        return "reason-night";
      }
    }
    return "";
  }

  return {
    selectedCategory,
    openAlert,
    deniedNightHourSlot,
    handleDeniedActionReasons,
    handleCloseAlert,
    scheduleLists,
    removeNightTimeList,
    allDaySelected,
    addNightTimeList,
    removeNightTimeHours,
    addNightTimeHours,
    saveButtonFlag,
    loader,
    handleSaveNightHours,
    isModalVisible,
    handleManageModal,
    AddTimeActions,
    RemoveTimeActions,
    nightModesTimeList,
    timeZone,
    handleTimeZoneChange,
    checkReason,
    handleOkbutton
  };
}
