import React from "react";
import Contacts from "./Contacts.jsx";
import FAQMain from "./FAQMain.jsx";
import contact from "component/Containers/NewLandingPage/Contacts";
import faq from "component/Containers/NewLandingPage/FAQ";

import { useTranslation } from "react-i18next";

const Support = () => {
  const { t } = useTranslation();
  const { data } = contact();
  const { questions } = faq();

  return (
    <div className="support" id="support">
      <div className="container">
        <div className="support__inner">
          <Contacts title={t("ContactsTitle_i18n")} list={data} />
          <FAQMain title={t("FAQTitle_i18n")} list={questions} />
        </div>
      </div>
    </div>
  );
};

export default Support;
