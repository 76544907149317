/* eslint-disable */
//In this file two common functions are defined
//handleShowChannelNames is used in "Setting.js" file for showing name of setting in which user change.
//handleDeniedActionReasons is used for displaying reason when action is denied.

import db from "../config/firebase";

export const handleDeniedActionReasons = (value, t) => {
  switch (value) {
    case "childOrPackageUser":
      return t("childOrPackageUser_i18n");
    case "underLock":
      return t("underLock_i18n");
    case "onNightMode":
      return t("onNightMode_i18n");
    case "overlapNightMode":
      return t("overlapNightMode_i18n");
    case "girlModeNotValid":
      return t("girlModeNotValid_i18n");
    case "deviceNotFound":
      return t("deviceNotFound_i18n");
    case "noTimezone":
      return t("noTimezone_i18n");
    case "wrongNightMode":
      return t("wrongNightMode_i18n");
    case "inValidData":
      return t("inValidData_i18n");
    case "wrongFrozenLockDetail":
      return t("wrongFrozenLockDetail_i18n");
    default:
      return value;
  }
};

export const handleShowChannelNames = (value, t) => {
  switch (value) {
    case "blockAppleLogout":
      return t("blockAppleLogout_i18n");
    case "defaultPolicy":
      return t("defaultPolicy_i18n");
  }
};

export const handleTimer = (
  flag,
  duration,
  timers = [],
  setTypeofLock = () => { },
  dispatch
) => {
  if (timers && timers.length) {
    timers.map((id) => clearInterval(id));
    timers.length = 0;
    dispatch({
      type: "SET_TIMERS",
      payload: [],
    });
  }
  var durationSeconds = duration;
  const timer = setInterval(() => {
    durationSeconds = durationSeconds - 1000;
    if (durationSeconds <= 0) {
      timers && timers.length && timers.map((id) => clearInterval(id));
      timers.length = 0;
      setTypeofLock("");
    }
  }, 1000);
  if (timer) {
    timers.push(timer);
  }
};

export const getNotificationIdByEmail = async (email) => {
  const databaseUrl = `Google users/${email}`;
  const userNotification = (await db.doc(`${databaseUrl}`).get()).data()?.notificationsId;
  return userNotification[0];
}

export const handleSetSeen = async (id, notifications) => {


  // console.log(`notifications`, notifications)
  const temp = notifications?.map((el) => {
    return { ...el, isSeen: true };
  });

  var temp2 = {};
  temp?.map((el) => (temp2[el.notificationId] = el));
  const databaseUrl = `Google users/${id}`;
  const userNotification = (await db.doc(`${databaseUrl}`).get()).data()?.notificationsId;

  if(userNotification){

    db.doc(
      `${process.env.REACT_APP_FIREBASE_COLLECTION
      }/${id}/notification/${userNotification[0]}`
    ).update({ notification: temp2 });
  }
 

  // const result = temp?.reduce((r, e) => {
  //   var k = e.notificationId.split("_")[0];
  //   if (r[k] || (r[k] = [])) r[k].push(e);
  //   return r;
  // }, {});

  // result &&
  //   Object.entries(result)?.length &&
  //   Object.entries(result)?.forEach(([key, val]) => {
  //     var temp2 = {};
  //     val?.map((el) => (temp2[el.notificationId] = el));
  //     console.log(`temp2`, temp2)
  //     db.doc(
  //       `${process.env.REACT_APP_FIREBASE_COLLECTION
  //       }/${id}/notification/${key.toString()}`
  //     ).update({ notification: temp2 });
  //   });
};

export const getInitialData = (email) => {
  return {
    children: [],
    userType: 'parent',
    frozenToMeltedTargetDate: null,
    frozenToMeltedTimeSpan: null,
    parents: [],
    meltedToFrozenTargetDate: null,
    timeZone: null,
    userId: email,
    gpsLocation: null,
    simpleToNullTimeSpan: null,
    nightModeItems: [],
    urlDefaultPolicyApple: "blockALL",
    appFuturePolicyAndroid: "blockALL",
    appDefaultPolicyAndroid: "open",
    urlDefaultPolicyAndroid: "blockALL",
    blockAppleLogout: false,
    blockAndroidLogout: false,
    simpleToNullTargetDate: new Date(),
    childAccessIds: [],
    childDontAccessIds: [],
    language: "en",
  };
};

export const getData = (initialData, userData) => {
  let temp = userData;
  const arr = Object.keys(initialData);
  arr?.forEach((el) => {
    if (userData[el]) {
    } else {
      temp[el] = initialData[el];
    }
  });
  return temp;
};

export const handleSidebarTitle = (value, t) => {
  switch (value) {
    case "whitelist":
      return t("approval-lists_i18n");
    case "settings":
      return t("settings_i18n");
    case "night-mode":
      return t("night-mode_i18n");
    case "morning-mode":
      return t("morning-mode_i18n");
    case "who-am-i":
      return t("who-am-i_i18n");
    case "add-kid":
      return t("add-kid_i18n");
    case "self-lock":
      return t("self-lock_i18n");
    case "apps":
      return "Black List of Apps";
    default:
      return "";
  }
};

export const getLanguageCode = (value) => {
  switch (value) {
    case "US":
      return "en";
    case "IN":
      return "hi";
    case "IL":
      return "he";
    case "PT":
      return "pt";
    case "RU":
      return "ru";
    case "IR":
      return "fa";
    case "ES":
      return "es";
    case "UA":
      return "uk";
    case "AE":
      return "ar";
    default:
      return "";
  }
};

export const getLanguageCountry = (value) => {
  switch (value) {
    case "en":
      return "US";
    case "hi":
      return "IN";
    case "he":
      return "IL";
    case "pt":
      return "PT";
    case "ru":
      return "RU";
    case "fa":
      return "IR";
    case "es":
      return "ES";
    case "uk":
      return "UA";
    case "ar":
      return "AE";
    default:
      return "";
  }
};

export const changeLanguage = (lng, i18next) => {
  // document.body.setAttribute(
  //   "dir",
  //   ["en", "es", "hi", "ru", "pt", "uk"].includes(lng || "en") ? "ltr" : "rtl"
  // );
  document.body.setAttribute(
    "class",
    ["en", "es", "hi", "ru", "pt", "uk"].includes(lng || "en") ? "dir-ltr" : "dir-rtl"
  );
  console.log('added')
  i18next.changeLanguage(lng || "en");
};
