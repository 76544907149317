/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { LS_VARIABLE, USER_TYPE } from "description/constant";

import db from "config/firebase";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "hooks/use-router";

const homePage = ["My kids", "parents", "", "home"];

export default function user() {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState("");
  const [deviceIdInfo, setDeviceIdInfo] = useState("");
  const [childrenList, setChildrenList] = useState([]);
  const [parentList, setParentList] = useState([]);
  // const [AiParentList, setAiParentList] = useState([])
  const [packagers, setPackagers] = useState([]);
  const { push } = useRouter();

  const {
    userInfo,
    selectedCategory,
    packageDetail,
    userList,
    kidRemovedMessage,
    hideFlags,
  } = useSelector((state) => state.users);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userSettingInfo = userList[userInfo?.email];
  const children = userSettingInfo?.children;
  const parents = userSettingInfo?.parents;
  const currentPackage = packageDetail;

  const AiParentList = (userSettingInfo?.parentsAi || []).filter(parent=>parent.isConfirmed)
  const pairedTelegram = (userSettingInfo?.pairedTelegram || null)

  const changeTypeOfLock = (val) =>
    dispatch({
      type: "SET_LOCK_TYPE",
      payload: val,
    });

  useEffect(() => {
    if (window.location.pathname === "/u/notifications") {
      setSelectedTab("notification");
      history.replace("/u/notifications");
    } else if (
      window.location.pathname !== "/u" &&
      window.location.pathname !== "/"
    ) {
      history.replace("/u");
    }
  }, []);

  useEffect(() => {
    filterConfirmUserList("child", children);
  }, [children, userList]);

  useEffect(() => {
    filterConfirmUserList("parent", parents);
  }, [parents, userList]);

  useEffect(() => {
    const loggedUserInfo = JSON.parse(
      localStorage.getItem(LS_VARIABLE.LOGGED_USER_INFO)
    );
    const device = localStorage.getItem(LS_VARIABLE.DEVICE);
    device && setDeviceIdInfo(device || "");
    if (loggedUserInfo) {
      // setUserInfo(loggedUserInfo || {});
    } else {
      history.push("/not-authorized");
    }
  }, []);

  useEffect(() => {
    handleGetUserDetails();
  }, [userInfo, packagers]);

  useEffect(() => {
    let temp = [];
    const fetchData = async () => {
      await db
        .collection(`filtering-companies`)
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            temp.push({ ...doc.data() });
            if (temp.length === snapshot.docs.length) {
              setPackagers(temp);
            }
          });
        }).catch(e=>console.log("Lobby js error---->",e))
    };
    fetchData();
  }, []);

  useEffect(() => {
    changeTypeOfLock(userSettingInfo?.typeOfLock || "");
    // userSettingInfo && getTimerCount(userSettingInfo, timers, dispatch);
  }, [userSettingInfo]);

  //For filtering the list of child and parent array.
  const filterConfirmUserList = (type, value) => {
    if (value && value.length) {
      let childIds = [];
      value.forEach(({ childId, parentId, isConfirmed }) => {
        if (isConfirmed === true)
          childIds.push(type === "child" ? childId : parentId);
      });
      if (childIds.length)
        if (!cmp(JSON.parse(localStorage.getItem(LS_VARIABLE.IDS)), childIds)) {
          getChildrenList(type, childIds);
        } else {
          let temp = [];
          type === "child"
            ? childIds.forEach((el, i) => {
              temp[i] = userList[el];
              childIds.length === temp.length && setChildrenList(temp);
            })
            : childIds.forEach((el, i) => {
              temp[i] = userList[el];
              childIds.length === temp.length && setParentList(temp);
            });
        }
    } else {
      type === "child"
        ? childrenList.length && setChildrenList([])
        : parentList.length && setParentList([]);
    }
  };

  //For getting list of child and parent.
  const getChildrenList = async (type, value) => {
    value = value.filter(function (element) {
      return element !== undefined;
    });
  
    if (value && value.length) {
      const databaseUrl = process.env.REACT_APP_FIREBASE_COLLECTION;
      let childUserLists = [];
  
      try {
        const snapshot = await db.collection(databaseUrl)
          .where("userId", "in", value)
          .get();
  
        if (snapshot && snapshot.docs && snapshot.docs.length) {
          snapshot.docs.forEach((d, i) => {
            childUserLists.push(snapshot.docs[i].data());
            childUserLists[i].id = snapshot.docs[i].id;
          });
  
          childUserLists.forEach((el) => {
            dispatch({
              type: "USER_LIST",
              payload: el,
            });
          });
  
          type === "child"
            ? setChildrenList(childUserLists)
            : setParentList(childUserLists);
        }
      } catch (error) {
        console.log("getChildrenList error---->", error);
      }
    }
  };

  const cmp = (a, b) => {
    let flag = false;
    if (a && a.length) {
      if (b && b.length) {
        for (let i = 0; i < b.length; i++) {
          if (a.includes(b[i])) flag = true;
          else return false;
        }
      }
    } else return false;
    return flag;
  };

  //For getting details of user when click on the any options in index file.
  const handleGetUserDetails = async (flag, id) => {
    if (Object.keys(userInfo).length) {
      const snapshotData = !Object.keys(userList).includes(userInfo?.email)
        ? await (
          await db
            .doc(
              `${process.env.REACT_APP_FIREBASE_COLLECTION}/${!flag ? userInfo?.email.toString() : id.toString()
              }`
            )
            .get()
        ).data()
        : userSettingInfo;
      if (snapshotData) {
        dispatch({
          type: "USER_LIST",
          payload: snapshotData,
        });
        var typeOfUser = userSettingInfo?.userType || USER_TYPE.PARENT;
        if (typeOfUser !== selectedCategory) {
          if (typeOfUser === USER_TYPE.PACKAGE_USER) {
            const packageDetail = (
              await db
                .collection(`filtering-companies`)
                .doc(snapshotData?.filteringCompanyPackageName.toString())
                .get()

            ).data();
            dispatch({
              type: "SET_PACKAGE",
              payload: packageDetail,
            });
          }
        }
        dispatch({
          type: "USER_TYPE",
          payload: { id: snapshotData.email, typeOfUser: typeOfUser },
        });
      }
    }
  };

  //For updating the route when user click on any option in sidebar.
  const handleUpdatePath = (value) => {
    // history.push(`/${value}`);
    // console.log("value===>", value)
    // history.push('/u/parent/7/whitelist')
  };

  //For toggling the setting info when user is in kid, parent or moderator settings.
  const handleSettingToggle = (type, value) => {
    if (
      !JSON.parse(localStorage.getItem(LS_VARIABLE.IDS)).includes(value?.userId)
    ) {
      handleGetUserDetails(true, value?.userId);
    }
    dispatch({
      type: "USER_TYPE",
      payload: {
        id:
          type === USER_TYPE.PACKAGE_IN_MODERATOR
            ? userSettingInfo?.email
            : value?.email,
        typeOfUser: type,
      },
    });
    // }
    handleSelectedTab("My Self", type);
  };


  //For set the state which is store which tab is selected currently.
  const handleSelectedTab = (value, id) => {
    handleUpdatePath(
      value === "My kids"
        ? "child"
        : value === "My Self"
          ? "approval-lists"
          : "parent"
    );
    setSelectedTab(value === "My Self" ? "approval-lists" : value);
  };

  kidRemovedMessage &&
    setTimeout(() => {
      dispatch({
        type: "SET_KID_REMOVED_MESSAGE",
        payload: "",
      });
    }, 3000);

  const handleCloseAlert = () => {
    dispatch({
      type: "SET_KID_REMOVED_MESSAGE",
      payload: "",
    });
  };

  const onEyeClick = (flag) => {
    const flagObj = { ...hideFlags, [flag]: !hideFlags[flag] };
    dispatch({
      type: "SET_HIDE_FLAG",
      payload: flagObj,
    });
  };

  return {
    kidRemovedMessage,
    homePage,
    selectedTab,
    handleCloseAlert,
    userInfo,
    handleSelectedTab,
    t,
    selectedCategory,
    parentList,
    handleSettingToggle,
    childrenList,
    push,
    currentPackage,
    onEyeClick,
    hideFlags,
    AiParentList,
    pairedTelegram
  };
}
