import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import en from "locales/en/translation.json"
import ar from "locales/ar/translation.json"
import es from "locales/es/translation.json"
import fa from "locales/fa/translation.json"
import he from "locales/he/translation.json"
import hi from "locales/hi/translation.json"
import pt from "locales/pt/translation.json"
import ru from "locales/ru/translation.json"
import uk from "locales/uk/translation.json"
import { initReactI18next } from "react-i18next";

let lng = 'en';
if (localStorage.getItem('i18nextLng')) {
  if (localStorage.getItem('i18nextLng') === "en") {
    lng = 'en'
  }
  if (localStorage.getItem('i18nextLng') === "he") {
    lng = 'he'
  }
  if (localStorage.getItem('i18nextLng') === "es") {
    lng = 'es'
  }
  if (localStorage.getItem('i18nextLng') === "hi") {
    lng = 'hi'
  }
  if (localStorage.getItem('i18nextLng') === "ar") {
    lng = 'ar'
  }
  if (localStorage.getItem('i18nextLng') === "fa") {
    lng = 'fa'
  }
  if (localStorage.getItem('i18nextLng') === "ru") {
    lng = 'ru'
  }
  if (localStorage.getItem('i18nextLng') === "pt") {
    lng = 'pt'
  }
  if (localStorage.getItem('i18nextLng') === "uk") {
    lng = 'uk'
  }
}
else {
  lng = 'en';
}
// document.body.setAttribute(
//   "dir",
//   ["en", "es", "hi", "ru", "pt", "uk"].includes(lng || "en") ? "ltr" : "rtl"
// );
document.body.setAttribute(
  "class",
  ["en", "es", "hi", "ru", "pt", "uk"].includes(lng || "en") ? "dir-ltr" : "dir-rtl"
);
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: lng,
    whitelist: ["en", "he", "es", "hi", "ar", "fa", "ru", "pt", "uk"],
    debug: false,
    resources: {
      en: {
        translation: en
      },
      ar: {
        translation: ar
      },
      es: {
        translation: es
      }, fa: {
        translation: fa
      }, he: {
        translation: he
      }, hi: {
        translation: hi
      }, pt: {
        translation: pt
      }, ru: {
        translation: ru
      }, uk: {
        translation: uk
      }
    },
    // have a common namespace used around the full app
    ns: ["translation"],
    defaultNS: "translation",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
    },

    // react: {
    //   wait: true,
    //   useSuspense: false,
    // },
  });

export default i18n;
