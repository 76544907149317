/* eslint-disable react-hooks/rules-of-hooks */
import {
  parentControl,
  preventHarassements,
  selfLock,
  specialSettings,
  sync,
} from "component/Containers/NewLandingPage/imports";

let features = [
  {
    title: "FeatureTitle1_i18n",
    text: "FeaturesText1_i18n",
    image: parentControl,
  },
  {
    title: "FeatureTitle2_i18n",
    text: "FeaturesText2_i18n",
    image: selfLock,
  },
  {
    title: "FeatureTitle3_i18n",
    text: "FeaturesText3_i18n",
    image: preventHarassements,
  },
  {
    title: "FeatureTitle4_i18n",
    text: "FeaturesText4_i18n",
    image: specialSettings,
  },
  {
    title: "FeatureTitle5_i18n",
    text: "FeaturesText5_i18n",
    image: sync,
  },
];

const featuresMainPage = () => {
  return { features };
};

export default featuresMainPage;
