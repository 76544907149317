import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { TYPE_OF_STATUS, USER_TYPE , modeTimes } from "description/constant";
import { Spin, Alert, Modal } from "antd";
import nightModeHours from "component/Containers/User/NightModeHours";
import { useTranslation } from "react-i18next";
import { ReactComponent as Plus } from "assets/images/plus2.svg";
import { ReactComponent as Minus } from "assets/images/minus.svg";
import { ReactComponent as NightMode } from "assets/images/night-mode-mirror.svg";
import { ReactComponent as TimeZone } from "assets/images/timezone.svg";
import Picker from "react-mobile-picker-scroll";
import Coundown from "./Coundown.jsx"

export default function NightModeHours() {
    const { t } = useTranslation();
    const {
        selectedCategory,
        openAlert,
        deniedNightHourSlot,
        handleDeniedActionReasons,
        handleCloseAlert,
        scheduleLists,
        removeNightTimeList,
        allDaySelected,
        addNightTimeList,
        removeNightTimeHours,
        addNightTimeHours,
        saveButtonFlag,
        loader,
        handleSaveNightHours,
        isModalVisible,
        handleManageModal,
        AddTimeActions,
        RemoveTimeActions,
        handleOkbutton,
        nightModesTimeList,
        timeZone,
        checkReason,
        handleTimeZoneChange,
    } = nightModeHours();
    let fromOptionsGroup = { ...modeTimes };
    let blankTimeSlot = {
        beginNightHour: "",
        endNightHour: "",
    };
    let blankValuesGroup = {
        hour: "00",
        minute: "00",
    };
    fromOptionsGroup.hour = fromOptionsGroup.hour.filter((hour) => hour !== "24");
    const [fromOptions, setFromOptions] = useState({ ...fromOptionsGroup });
    const [untillOptions, setUntillOptions] = useState({ ...modeTimes });
    const [newFromOptions, setNewFromOptions] = useState({ ...fromOptionsGroup });
    const [newUntillOptions, setNewUntillOptions] = useState({
        ...modeTimes,
    });
    const [editFromValue, setEditFromValue] = useState(blankValuesGroup);
    const [editUntillValue, setEditUntillValue] = useState(blankValuesGroup);
    const [fromValue, setFromValue] = useState(blankValuesGroup);
    const [untillValue, setUntillValue] = useState(blankValuesGroup);
    const [tempNightObj, setTempNightObj] = useState(null);
    const [tempTimeSlot, setTempTimeSlot] = useState(blankTimeSlot);
    const [showUntill, setShowUntill] = useState(false);
    const [addMode, setAddMode] = useState(false);
    const handleEditFromChange = (name, value) => {
        setEditFromValue({ ...editFromValue, [name]: value });
    };
    const handleEditUntillChange = (name, value) => {
        console.log("call-es");
        if (name == "hour" && editFromValue.hour == value) {
            let updated_min = [...modeTimes.minute].filter(
                (item) => parseInt(item) > editFromValue.minute
            );
            setEditUntillValue({ hour: value, minute: updated_min[0] });
            setUntillOptions({ ...untillOptions, minute: updated_min });
        } else if (
            (name == "hour" && value == "24") ||
            (name == "minute" && untillValue.hour == "24")
        ) {
            setEditUntillValue({ hour: "24", minute: "00" });
            setUntillOptions({ ...untillOptions, minute: ["00"] });
        } else {
            setEditUntillValue({ ...editUntillValue, [name]: value });
            setUntillOptions({ ...untillOptions, minute: [...modeTimes.minute] });
        }
    };
    const handleFromChange = (name, value) => {
        setFromValue({ ...fromValue, [name]: value });
    };
    const getReasonTimer = (reasonObj) => {
        let updatedData = reasonObj?.filter(name => typeof name == 'object')[0]
        return updatedData ? updatedData['reasonTimer'] : null
    }
    const handleUntillChange = (name, value) => {
        if (name == "hour" && fromValue.hour == value) {
            let updated_min = [...modeTimes.minute].filter(
                (item) => parseInt(item) > fromValue.minute
            );
            setUntillValue({ hour: value, minute: updated_min[0] });
            setNewUntillOptions({ ...newUntillOptions, minute: updated_min });
        } else if (
            (name == "hour" && value == "24") ||
            (name == "minute" && untillValue.hour == "24")
        ) {
            setUntillValue({ hour: "24", minute: "00" });
            setNewUntillOptions({ ...newUntillOptions, minute: ["00"] });
        } else {
            setUntillValue({ ...untillValue, [name]: value });
            setNewUntillOptions({
                ...newUntillOptions,
                minute: [...modeTimes.minute],
            });
        }
    };
    const onPickerHover = () => {
        document.body.classList.add("remove-scroll");
    };
    const onRemovePickerHover = () => {
        document.body.classList.remove("remove-scroll");
    };
    const handleNext = () => {
        setShowUntill(true);
        let temoobj = {
            ...tempTimeSlot,
            beginNightHour: `${fromValue.hour}:${fromValue.minute}`,
        };
        setTempTimeSlot({
            ...tempTimeSlot,
            beginNightHour: `${fromValue.hour}:${fromValue.minute}`,
        });
        let updated_options = updateUntillHours(temoobj);
        setUntillValue({
            hour: updated_options.hour[0],
            minute: updated_options.minute[0],
        });
        setNewUntillOptions(updated_options);
    };
    const handleSave = (key) => {
        let obj = tempTimeSlot;
        obj.endNightHour = `${untillValue.hour}:${untillValue.minute}`;
        AddTimeActions(key, obj);
        setShowUntill(false);
        setTempTimeSlot({ beginNightHour: "", endNightHour: "" });
        setAddMode(false);
    };
    const setEditData = (obj) => {
        setTempNightObj(obj);
        setEditFromValue({
            hour: obj.beginNightHour.split(":")[0],
            minute: obj.beginNightHour.split(":")[1],
        });
        setEditUntillValue({
            hour: obj.endNightHour.split(":")[0],
            minute: obj.endNightHour.split(":")[1],
        });
    };
    const updateUntillHours = (obj) => {
        let tempOption = { ...modeTimes };
        if (obj.beginNightHour.split(":")[1] == 45)
            tempOption.hour = tempOption.hour.filter(
                (item) => parseInt(item) > parseInt(obj.beginNightHour.split(":")[0])
            );
        else {
            tempOption.hour = tempOption.hour.filter(
                (item) => parseInt(item) >= parseInt(obj.beginNightHour.split(":")[0])
            );
            tempOption.minute = tempOption.minute.filter(
                (item) => parseInt(item) > parseInt(obj.beginNightHour.split(":")[1])
            );
        }
        console.log(tempOption);
        return tempOption;
    };
    const setNewUntillValue = () => {
        if (tempTimeSlot.beginNightHour == "")
            setFromValue({
                hour: "00",
                minute: "00",
            });
        else
            setFromValue({
                hour: tempTimeSlot.beginNightHour.split(":")[0],
                minute: tempTimeSlot.beginNightHour.split(":")[1],
            });
    };
    const nightModeLoop = () => {
        return (
            <>
                {Object.entries(nightModesTimeList)
                    .sort()
                    .map(([key, value]) => {
                        return (
                            <div className="accordion-item m-0 p-0 ">
                                <h2
                                    className="accordion-header androied-header"
                                    id={"daysAccordian" + key}
                                >
                                    <div
                                        className="accordion-button collapsed shadow-none border-0"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={"#day-collapse" + key}
                                        aria-expanded="false"
                                        aria-controls={"day-collapse" + key}
                                    >
                                        <div className="form-check website-detail">
                                            <label
                                                className="form-check-label d-flex"
                                                htmlFor="flexUrlAppleOpen"
                                            >
                                                <p>
                                                    {key.split("_")[1]} <br />
                                                    <span>
                                                        {value && value.length > 0
                                                            ? value.map((obj, index) => (
                                                                <span
                                                                    className={obj.isApproved ? "" : "red-text"}
                                                                >
                                                                    {index == 0 ? "" : " , "}
                                                                    {obj.beginNightHour}-{obj.endNightHour}
                                                                </span>
                                                            ))
                                                            : "OFF"}
                                                    </span>
                                                </p>
                                            </label>
                                        </div>
                                    </div>
                                </h2>
                                <div
                                    id={"day-collapse" + key}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={"daysAccordian" + key}
                                    data-bs-parent="#modeAccordian2"
                                >
                                    <div className="accordion-body p-0">
                                        <div className="accordion-details">
                                            <div
                                                className="accordion accordion-flush timeslot-items"
                                                id={"itemAccordion" + key}
                                            >
                                                {value &&
                                                    value.length > 0 &&
                                                    value.map((obj) => {
                                                        return (
                                                            <div className={"d-flex main-slot-section " + checkReason(obj.reason)} key={obj.key}>
                                                                {getReasonTimer(obj.reason) && checkReason(obj.reason) == 'reason-melting'
                                                                    && <Coundown reasonTimer={getReasonTimer(obj.reason)} />}
                                                                {
                                                                    (['reason-deniedSimpleLock', 'reason-deniedByHumanParent', 'reason-sentToHumanParent'].includes(checkReason(obj.reason))) &&
                                                                    <div className="ok-button"><button type="button" class="btn btn-light" onClick={() => handleOkbutton(key, obj)}>Ok</button></div>
                                                                }
                                                                <div
                                                                    className="remove-icon"
                                                                    onClick={() => RemoveTimeActions(key, obj)}
                                                                >
                                                                    <Minus />
                                                                </div>
                                                                <div className="w-100 main-section">
                                                                    <div className="accordion-item pb-0 pt-0 timeslot-items">
                                                                        <h2
                                                                            className="accordion-header androied-header"
                                                                            id="itemHeading"
                                                                        >
                                                                            <div
                                                                                className="accordion-button collapsed shadow-none"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={
                                                                                    "#item-collapse" + obj.key
                                                                                }
                                                                                aria-expanded="false"
                                                                                aria-controls={
                                                                                    "item-collapse" + obj.key
                                                                                }
                                                                                onClick={() => setEditData(obj)}
                                                                            >
                                                                                <div className="form-check website-detail">
                                                                                    <label
                                                                                        className="form-check-label d-flex"
                                                                                        htmlFor="flexUrlAppleOpen3"
                                                                                    >
                                                                                        <p>
                                                                                            From <br />
                                                                                            <span
                                                                                                className={
                                                                                                    obj.isApproved
                                                                                                        ? ""
                                                                                                        : "red-text"
                                                                                                }
                                                                                            >
                                                                                                {obj.beginNightHour}
                                                                                            </span>
                                                                                        </p>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </h2>
                                                                        <div
                                                                            id={"item-collapse" + obj.key}
                                                                            className="accordion-collapse collapse shadow-none "
                                                                            aria-labelledby="itemHeading"
                                                                            data-bs-parent={"#itemAccordion" + key}
                                                                        >
                                                                            <div className="accordion-body">
                                                                                <div className="accordion-details">
                                                                                    <div
                                                                                        className="d-picker"
                                                                                        onMouseEnter={onPickerHover}
                                                                                        onMouseLeave={onRemovePickerHover}
                                                                                        onTouchStart={onPickerHover}
                                                                                        onTouchEnd={onRemovePickerHover}
                                                                                    >
                                                                                        <Picker
                                                                                            optionGroups={fromOptions}
                                                                                            valueGroups={editFromValue}
                                                                                            onChange={handleEditFromChange}
                                                                                        />

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-item pb-0 pt-0 ">
                                                                        <h2
                                                                            className="accordion-header androied-header"
                                                                            id="itemHeading"
                                                                        >
                                                                            <div
                                                                                className="accordion-button collapsed shadow-none"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={
                                                                                    "#nightmode-collapse-two" + obj.key
                                                                                }
                                                                                aria-expanded="false"
                                                                                aria-controls={
                                                                                    "nightmode-collapse-two" + obj.key
                                                                                }
                                                                                onClick={() => setEditData(obj)}
                                                                            >
                                                                                <div className="form-check website-detail">
                                                                                    <label
                                                                                        className="form-check-label d-flex"
                                                                                        htmlFor="flexUrlAppleOpen3"
                                                                                    >
                                                                                        <p>
                                                                                            Untill <br />
                                                                                            <span
                                                                                                className={
                                                                                                    obj.isApproved
                                                                                                        ? ""
                                                                                                        : "red-text"
                                                                                                }
                                                                                            >
                                                                                                {obj.endNightHour}
                                                                                            </span>
                                                                                        </p>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </h2>
                                                                        <div
                                                                            id={"nightmode-collapse-two" + obj.key}
                                                                            className="accordion-collapse collapse shadow-none"
                                                                            aria-labelledby="itemHeading"
                                                                            data-bs-parent={"#itemAccordion" + key}
                                                                        >
                                                                            <div className="accordion-body">
                                                                                <div className="accordion-details">
                                                                                    <div
                                                                                        className="d-picker"
                                                                                        onMouseEnter={onPickerHover}
                                                                                        onMouseLeave={onRemovePickerHover}
                                                                                        onTouchStart={onPickerHover}
                                                                                        onTouchEnd={onRemovePickerHover}
                                                                                    >
                                                                                        <Picker
                                                                                            optionGroups={untillOptions}
                                                                                            valueGroups={editUntillValue}
                                                                                            onChange={handleEditUntillChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                <div className="d-flex main-slot-section border-0">
                                                    <div
                                                        className="remove-icon"
                                                        onClick={() => {
                                                            setAddMode(!addMode);
                                                        }}
                                                    >
                                                        <Plus />
                                                    </div>
                                                    <div className={addMode ? "w-100" : "w-100 d-none"}>
                                                        <div className="accordion-item pb-0 pt-0 timeslot-items">
                                                            <h2
                                                                className="accordion-header androied-header"
                                                                id="itemHeading"
                                                            >
                                                                <div
                                                                    className="accordion-button collapsed shadow-none"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={"#item-collapse" + key}
                                                                    aria-expanded="false"
                                                                    aria-controls={"item-collapse" + key}
                                                                    onClick={setNewUntillValue}
                                                                >
                                                                    <div className="form-check website-detail">
                                                                        <label
                                                                            className="form-check-label d-flex"
                                                                            htmlFor="flexUrlAppleOpen3"
                                                                        >
                                                                            <p>
                                                                                From <br />
                                                                                <span>
                                                                                    {tempTimeSlot.beginNightHour}
                                                                                </span>
                                                                            </p>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </h2>
                                                            <div
                                                                id={"item-collapse" + key}
                                                                className="accordion-collapse collapse shadow-none "
                                                                aria-labelledby="itemHeading"
                                                                data-bs-parent={"#itemAccordion" + key}
                                                            >
                                                                <div className="accordion-body">
                                                                    <div className="accordion-details">
                                                                        <div
                                                                            className="d-picker"
                                                                            onMouseEnter={onPickerHover}
                                                                            onMouseLeave={onRemovePickerHover}
                                                                            onTouchStart={onPickerHover}
                                                                            onTouchEnd={onRemovePickerHover}
                                                                        >
                                                                            <Picker
                                                                                optionGroups={newFromOptions}
                                                                                valueGroups={fromValue}
                                                                                onChange={handleFromChange}
                                                                            />
                                                                            <Button
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={
                                                                                    "#nightmode-collapse-two" + key
                                                                                }
                                                                                aria-expanded="false"
                                                                                aria-controls={
                                                                                    "nightmode-collapse-two" + key
                                                                                }
                                                                                onClick={handleNext}
                                                                            >
                                                                                Next
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`accordion-item pb-0 pt-0  ${showUntill ? "" : "d-none"
                                                                }`}
                                                        >
                                                            <h2
                                                                className="accordion-header androied-header"
                                                                id="itemHeading"
                                                            >
                                                                <div
                                                                    className="accordion-button collapsed shadow-none"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={
                                                                        "#nightmode-collapse-two" + key
                                                                    }
                                                                    aria-expanded="false"
                                                                    aria-controls={"nightmode-collapse-two" + key}
                                                                >
                                                                    <div className="form-check website-detail">
                                                                        <label
                                                                            className="form-check-label d-flex"
                                                                            htmlFor="flexUrlAppleOpen32"
                                                                        >
                                                                            <p>
                                                                                Untill <br />
                                                                            </p>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </h2>
                                                            <div
                                                                id={"nightmode-collapse-two" + key}
                                                                className="accordion-collapse collapse shadow-none"
                                                                aria-labelledby="itemHeading"
                                                                data-bs-parent={"#itemAccordion" + key}
                                                            >
                                                                <div className="accordion-body">
                                                                    <div className="accordion-details">
                                                                        <div
                                                                            className="d-picker"
                                                                            onMouseEnter={onPickerHover}
                                                                            onMouseLeave={onRemovePickerHover}
                                                                            onTouchStart={onPickerHover}
                                                                            onTouchEnd={onRemovePickerHover}
                                                                        >
                                                                            <Picker
                                                                                optionGroups={newUntillOptions}
                                                                                valueGroups={untillValue}
                                                                                onChange={handleUntillChange}
                                                                            />
                                                                            <Button onClick={() => handleSave(key)}>
                                                                                save
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </>
        );
    };
    useEffect(() => {
        if (tempNightObj) {
            let hour_options = [...modeTimes.hour];
            if (tempNightObj.beginNightHour.split(":")[1] == 45)
                hour_options = hour_options.filter(
                    (item) =>
                        parseInt(item) > parseInt(tempNightObj.beginNightHour.split(":")[0])
                );
            else
                hour_options = hour_options.filter(
                    (item) =>
                        parseInt(item) >=
                        parseInt(tempNightObj.beginNightHour.split(":")[0])
                );
            setUntillOptions({ ...untillOptions, hour: hour_options });
        }
    }, [tempNightObj]);

    const nightModeFaqsList = [
        {
            id: 1,
            question: t("night_mode_q1_faq___i18n"),
            answer: t("night_mode_a1_faq___i18n")
        },
        {
            id: 2,
            question: t("night_mode_q2_faq___i18n"),
            answer: t("night_mode_a2_faq___i18n")
        },
        {
            id: 3,
            question: t("night_mode_q3_faq___i18n"),
            answer: t("night_mode_a3_faq___i18n")
        },
        {
            id: 4,
            question: t("night_mode_q4_faq___i18n"),
            answer: t("night_mode_a4_faq___i18n")
        },
        {
            id: 5,
            question: t("night_mode_q5_faq___i18n"),
            answer: t("night_mode_a5_faq___i18n")
        },
    ]

    const faqs = () => {
        return (
            <div className="accordion accordion-flush" id="faqAccordian">
                {
                    nightModeFaqsList.map((el) => (
                        <div className="accordion-item">
                            <h2 className="accordion-header androied-header" id={"flush-headingfaq" + el.id}>
                                <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapsefaq" + el.id} aria-expanded="false" aria-controls={"flush-collapsefaq" + el.id}>
                                    <p>{el.question}<br /><span>{el.answer.slice(0, 40) + "..."}</span></p>
                                </div>
                            </h2>
                            <div id={"flush-collapsefaq" + el.id} className="accordion-collapse collapse" aria-labelledby={"flush-headingfaq" + el.id} data-bs-parent="#faqAccordian">
                                <div className="accordion-body">
                                    <p>{el.answer}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    const backFAQsAccordian = () => {
        return (
            <div className="accordion accordion-flush" id="backFAQsAccordian">
                <div className="accordion-item pb-0">
                    <h2 className="accordion-header androied-header" id="flush-headingOne-back-faq">
                        <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne-back-faq" aria-expanded="false" aria-controls="flush-collapseOne">
                            <p>{t("backToFaq___i18n")}<br /><span>{t("readItAnytimeYouWant___i18n")}</span></p>
                        </div>
                    </h2>
                    <div id="flush-collapseOne-back-faq" className="accordion-collapse collapse" aria-labelledby="flush-headingOne-back-faq" data-bs-parent="#backFAQsAccordian">
                        <div className="accordion-body p-0">
                            {faqs()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className="sidebar-right-side side-bar-menu accordion-menu">
            {selectedCategory === USER_TYPE.CHILD_IN_PARENT ? (
                <div className="row mb-3">{t("notAllowed_i18n")}</div>
            ) : (
                <div className="row night-hour-form justify-content-center">
                    <div className="col-12">
                        <div>
                            <div className="row">
                                {openAlert && (
                                    <div className="mb-3">
                                        <Alert
                                            message={
                                                deniedNightHourSlot?.status === TYPE_OF_STATUS.REQUESTED
                                                    ? "Sent"
                                                    : "Fail"
                                            }
                                            description={`${deniedNightHourSlot &&
                                                // deniedNightHourSlot?.timeSlot &&
                                                deniedNightHourSlot?.reason
                                                ? handleDeniedActionReasons(
                                                    deniedNightHourSlot?.reason,
                                                    t
                                                )
                                                : deniedNightHourSlot?.status ===
                                                    TYPE_OF_STATUS.REQUESTED
                                                    ? t("requestSentToParent_i18n")
                                                    : t("somethingWentWrong_i18n")
                                                }.`}
                                            type={
                                                deniedNightHourSlot?.status === TYPE_OF_STATUS.REQUESTED
                                                    ? "info"
                                                    : "error"
                                            }
                                            closable
                                            onClose={handleCloseAlert}
                                        />
                                    </div>
                                )}
                                <div className="accordion" id="timezoneAccordian">
                                    <div className="accordion-item shadow-none border-0 p-0">
                                        <h2
                                            className="accordion-header androied-header"
                                            id="timezoneAccordianHeading"
                                        >
                                            <div
                                                className="accordion-button collapsed shadow-none"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#timezone-collapse"
                                                aria-expanded="false"
                                                aria-controls="timezone-collapse"
                                            >
                                                <TimeZone />
                                                <p>
                                                    {t("timeZone___i18n")}
                                                    <br />
                                                    <span>{timeZone}</span>
                                                </p>
                                            </div>
                                        </h2>
                                        <div
                                            id="timezone-collapse"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="timezoneAccordianHeading"
                                            data-bs-parent="#timezoneAccordian"
                                        >
                                            <div className="accordion-body">
                                                <div className="accordion-details">
                                                    <select
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                        value={timeZone}
                                                        onChange={handleTimeZoneChange}
                                                    >
                                                        {Intl.supportedValuesOf("timeZone").map(
                                                            (timezoneItem) => (
                                                                <option value={timezoneItem}>
                                                                    {timezoneItem}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion" id="nightmodeAccordian">
                                    <div className="accordion-item shadow-none border-0 p-0">
                                        <h2
                                            className="accordion-header androied-header"
                                            id="nightmodeHeading"
                                        >
                                            <div
                                                className="accordion-button shadow-none"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#item-collapse"
                                                aria-expanded="false"
                                                aria-controls="item-collapse"
                                            >
                                                <NightMode />
                                                <p>
                                                    {t("night-mode_i18n")}
                                                    <br />
                                                    <span>{t("duringNightBlockedEverything___i18n")}</span>
                                                </p>
                                            </div>
                                        </h2>
                                        <div
                                            id="item-collapse"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="nightmodeHeading"
                                            data-bs-parent="#nightmodeAccordian"
                                        >
                                            <div className="accordion-body">
                                                <div className="accordion-details">
                                                    <h5 className="mb-3 accordion-sub-text">
                                                        {t("pickTimeNigthMode___i18n")}
                                                    </h5>

                                                    <div
                                                        className="accordion accordion-flush"
                                                        id="modeAccordian2"
                                                    >
                                                        {nightModeLoop()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isModalVisible && (
                                    <Modal
                                        centered
                                        title={t("modalTitleNightHour_i18n")}
                                        className="modal-dialog"
                                        visible={isModalVisible}
                                        onOk={handleManageModal}
                                        onCancel={handleManageModal}
                                        footer={[
                                            <Button
                                                key="back"
                                                className="primary"
                                                onClick={handleManageModal}
                                            >
                                                {t("ok_i18n")}
                                            </Button>,
                                            <Button
                                                key="submit"
                                                className="normal"
                                                onClick={handleManageModal}
                                            >
                                                {t("cancel_i18n")}
                                            </Button>,
                                        ]}
                                    >
                                        <p>{t("nightAlertModal1stLine_i18n")}</p>
                                        <p>{t("nightAlertModal2stLine_i18n")}</p>
                                        <p>{t("nightAlertModal3rdLine_i18n")}</p>
                                        <p>{t("nightAlertModal4thLine_i18n")}</p>
                                        <p>{t("nightAlertModal5thLine_i18n")}</p>
                                        <p>{t("nightAlertModal6thLine_i18n")}</p>
                                    </Modal>
                                )}
                            </div>
                        </div>

                        {backFAQsAccordian()}
                    </div>
                </div>
            )}
        </div>
    );
}
