import { Button, Modal, ModalHeader, ModalBody, Input, Form, FormGroup, Label, Col, ModalFooter } from 'reactstrap';
const EditModal = ({ showEditModal, editModaltoggle, tempApp, setTempApp, onEditApp }) => {
    return (
        <>
            <Modal className="blacklistmodal editpicture" isOpen={showEditModal} toggle={editModaltoggle}>
                <ModalHeader toggle={editModaltoggle}>
                    Edit Picture
                </ModalHeader>
                <ModalBody>
                    <div className="modalbodywrap blacklist-03">
                        <Form className="package-form">
                            <FormGroup row>
                                <Label
                                    for="exampleEmail"
                                    lg={3}
                                >
                                    Package Name
                                </Label>
                                <Col lg={9}>
                                    <Input
                                        id="packageName"
                                        name="packageName"
                                        placeholder="Enter Package Name"
                                        type="text"
                                        disabled={true}
                                        value={tempApp?.packageName}
                                        onChange={(e) => setTempApp({ ...tempApp, packageName: e.target.value })}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    for="exampleEmail"
                                    lg={3}
                                >
                                    Name
                                </Label>
                                <Col lg={9}>
                                    <Input
                                        id="name"
                                        name="name"
                                        placeholder="Enter Name"
                                        type="text"
                                        value={tempApp?.name}
                                        onChange={(e) => setTempApp({ ...tempApp, name: e.target.value })}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    for="exampleEmail"
                                    lg={3}
                                >
                                    Image
                                </Label>
                                <Col lg={9}>
                                    <Input
                                        id="previewImage"
                                        name="previewImage"
                                        placeholder="Enter preview Image URL"
                                        type="text"
                                        value={tempApp?.previewImage}
                                        onChange={(e) => setTempApp({ ...tempApp, previewImage: e.target.value })}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>

                    <Button color="secondary" onClick={onEditApp}>
                        save
                    </Button>
                </ModalFooter>

            </Modal>
        </>
    )
}

export default EditModal