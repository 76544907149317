import React from "react";
import { Alert } from "antd";
import CustomForm from "shared/customForm";
import { useTranslation } from "react-i18next";
import addKid from "component/Containers/User/AddKid";

export default function AddKid() {
    const { t } = useTranslation();
    const { message, typeOfAlert, handleCloseAlert, onSubmit, loader, fields } = addKid();

    const addKidParentFaqsList = [
        {
            id: 1,
            question: t("add_parent_or_kid_q1_faq___i18n"),
            answer: t("add_parent_or_kid_a1_faq___i18n")
        },
        {
            id: 2,
            question: t("add_parent_or_kid_q2_faq___i18n"),
            answer: t("add_parent_or_kid_a2_faq___i18n")
        },
        {
            id: 3,
            question: t("add_parent_or_kid_q3_faq___i18n"),
            answer: t("add_parent_or_kid_a3_faq___i18n")
        },
        {
            id: 4,
            question: t("add_parent_or_kid_q4_faq___i18n"),
            answer: t("add_parent_or_kid_a4_faq___i18n")
        },
        {
            id: 5,
            question: t("add_parent_or_kid_q5_faq___i18n"),
            answer: t("add_parent_or_kid_a5_faq___i18n")
        },
    ]

    const faqs = () => {
        return (
            <div className="accordion accordion-flush" id="faqAccordian">
                {
                    addKidParentFaqsList.map((el) => (
                        <div className="accordion-item">
                            <h2 className="accordion-header androied-header" id={"flush-headingfaq" + el.id}>
                                <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapsefaq" + el.id} aria-expanded="false" aria-controls={"flush-collapsefaq" + el.id}>
                                    <p>{el.question}<br /><span>{el.answer.slice(0, 40) + "..."}</span></p>
                                </div>
                            </h2>
                            <div id={"flush-collapsefaq" + el.id} className="accordion-collapse collapse" aria-labelledby={"flush-headingfaq" + el.id} data-bs-parent="#faqAccordian">
                                <div className="accordion-body">
                                    <p>{el.answer}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    const backFAQsAccordian = () => {
        return (
            <div className="accordion accordion-flush" id="backFAQsAccordian">
                <div className="accordion-item pb-0">
                    <h2 className="accordion-header androied-header" id="flush-headingOne-back-faq">
                        <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne-back-faq" aria-expanded="false" aria-controls="flush-collapseOne">
                            <p>{t("backToFaq___i18n")}<br /><span>{t("readItAnytimeYouWant___i18n")}</span></p>
                        </div>
                    </h2>
                    <div id="flush-collapseOne-back-faq" className="accordion-collapse collapse" aria-labelledby="flush-headingOne-back-faq" data-bs-parent="#backFAQsAccordian">
                        <div className="accordion-body p-0">
                            {faqs()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>
            <div className="sidebar-right-side mt-5 overflow-hidden">
                {message && (
                    <div>
                        <Alert
                            message={message}
                            type={typeOfAlert}
                            closable
                            showIcon
                            onClose={handleCloseAlert}
                        />
                    </div>
                )}
                <div className="row justify-content-center mt-5">
                    <div className="col-md-6">
                        <CustomForm
                            onSubmit={onSubmit}
                            labelName={t("addKidTitle_i18n")}
                            loader={loader}
                            fields={fields}
                        />
                        <div className="my-4 p-3 border rounded">
                            <p className="mb-0">
                                <strong>Write the Gmail account of the user you want to invite.</strong>
                            </p>
                            <p className="mb-0">Make sure this user has already signed in to Lockteens.</p>
                        </div>

                        {backFAQsAccordian()}
                    </div>
                </div>
            </div>
        </>
    );
}
