//This is file for users reducer.
//Different types of actions are handled in this file.

const initialState = {
  userList: {},
  selectedCategory: "parent",
  selectedId: null,
  userInfo: {},
  timers: [],
  typeofLock: null,
  packageDetail: {},
  notifications: [],
  kidRemovedMessage: "",
  loginStatus: false,
  isHomePage: false,
  hideFlags: {
    kid: true,
    parent: true,
    moderator: true,
  },
  nightModesTimeObj:{}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "USER_LIST":
      return {
        ...state,
        userList: { ...state.userList, [payload?.email]: payload },
      };

    case "USER_TYPE":
      return {
        ...state,
        selectedCategory: payload.typeOfUser,
        selectedId: payload.id,
      };

    case "SET_PACKAGE":
      return {
        ...state,
        packageDetail: payload,
      };
    case "SET_NIGHT_MODE_DATA":
      return {
        ...state,
        nightModesTimeObj: payload
      };
    case "SET_USER_DATA":
      return {
        ...state,
        userInfo: payload
      };

    case "SET_LOCK_TYPE":
      return {
        ...state,
        typeofLock: payload,
      };

    case "SET_TIMERS":
      return {
        ...state,
        timers: payload,
      };

    case "SET_NOTIFICATION":
      return {
        ...state,
        notifications: payload,
      };

    case "SET_KID_REMOVED_MESSAGE":
      return {
        ...state,
        kidRemovedMessage: payload,
      };

    case "SET_LOGIN_FLAG":
      return {
        ...state,
        loginStatus: payload,
      };

    case "PURGE":
      return initialState;

    case "LOCATION":
      return {
        ...state,
        isHomePage: payload,
      };

    case "SET_HIDE_FLAG":
      return {
        ...state,
        hideFlags: payload,
      };

    default:
      return state;
  }
};
