/* eslint-disable react-hooks/rules-of-hooks */
//It is add kid functionality, user can add kid by giving valid kid id.

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import db from "config/firebase";
import {
  TYPE_OF_ACTION,
  TYPE_OF_STATUS,
  REASON_OF_ACTION,
} from "description/constant";
import { useTranslation } from "react-i18next";
import { useRouter } from "hooks/use-router";

export default function addAiParent() {
  const { t } = useTranslation();
  const { push } = useRouter()

  const [prompt, setPrompt] = useState();
  const [loader, setLoader] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState("");
  const [typeOfAlert, setTypeOfAlert] = useState("");
  const { userInfo } = useSelector((state) => state.users);


  useEffect(() => {
    getUpdatedStatus();
  }, [loader]);

  // It is function for getting the status of action which is currently executed by user and set error or success message accordingly.
  const getUpdatedStatus = () => {
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions`;
    db.collection(databaseUrl)
      .where("status", "==", TYPE_OF_STATUS.PENDING)
      .where("actionType", "==", `${TYPE_OF_ACTION.ADD_AI_PARENT}`)
      .get()
      .then((snapshot) => {
        console.log("Feewwfewfewfewf")
        if (snapshot.docs.length) {
          setLoader(true);
          getUpdatedStatus();
        } else {
          setLoader(false);
          isSubmit &&
            db
              .collection(
                `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions`
              )
              .where(
                "actionType",
                "==",
                `${TYPE_OF_ACTION.ADD_AI_PARENT}`
              )
              .get()
              .then((snapshot) => {
                if (snapshot && snapshot.docs.length) {
                  const { status, reason } = snapshot.docs
                    .map((doc) => doc.data())
                    .pop();
                  if (status === TYPE_OF_STATUS.DENIED) {
                    reason === REASON_OF_ACTION.INVALID_PARENT &&
                      setAlert("You are trying to add invalid parent so your request is denied", "error");
                    reason === REASON_OF_ACTION.UNDER_LOCK &&
                      setAlert(t(`underLock_i18n`), "error");
                    reason === REASON_OF_ACTION.ADD_YOURSELF_AS_PARENT &&
                      setAlert('You can not add yourself as parent', "error");
                    reason === REASON_OF_ACTION.PARENT_ALREADY_EXISTS &&
                      setAlert('This account is already your parent', "error");
                  
                    }
                    if (status === TYPE_OF_STATUS.MELTING){
                        setAlert('your request is in melting timer ', "success");
                    }
                   else {
                    setAlert('your request is approved or sent to human parent if you are child', "success");
                  }
                }
              });
          setIsSubmit(false);
        }
      });
  };

  const setAlert = async (message, type) => {
    setTypeOfAlert(type);
    setMessage(message);
  };

  const onChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const { selectionStart, selectionEnd } = e.target;
      const newValue = `${prompt.slice(0, selectionStart)}\r\n${prompt.slice(selectionEnd)}`;
      setPrompt(newValue);

      // Move the cursor to the next line
      // const newPosition = selectionStart + 2;
      // e.target.setSelectionRange(newPosition, newPosition);
    }
  };

  // It is onSubmit function, it pass the action if kid id is valid and not already kid.
  const onSubmit = (e) => {
    e.preventDefault();

    setLoader(true);
    const date = new Date().getTime();
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date} - ${TYPE_OF_ACTION.ADD_AI_PARENT}`;
    db.doc(databaseUrl).set({
    userId: userInfo?.email,
    actionId: `${date}-${TYPE_OF_ACTION.ADD_AI_PARENT}`,
    actionType: `${TYPE_OF_ACTION.ADD_AI_PARENT}`,
    status: TYPE_OF_STATUS.PENDING,
    createdTime: new Date(),
    name: TYPE_OF_ACTION.ADD_AI_PARENT,
    prompt:JSON.stringify(prompt),
    reason: ""
    });
    setIsSubmit(true);
  };

  //For automatically close the alert after 5 seconds and clearing the field value.
  message &&
    setTimeout(() => {
      setMessage("");
      setTypeOfAlert("");
      setPrompt();
      push('/u')
    }, 3000);

  //For closing the alert notifications and clearing the field value.
  const handleCloseAlert = () => {
    setMessage("");
    setTypeOfAlert("");
    setPrompt();
  };
 
const AiParents = {
    aiParent1:"You are now a strict parent.You don't like any website that might wasteyour kid's time.",
    aiParent2:"You are now an easy going parent.You will approve most websites for your kid,but you don't like any website with explicit nudity or violence.",
    aiParent3:"You are now a smart parent, who is veryfamiliar and well educated with computer systems. You are not easy to fool.You will approve websites for your kid if it contribute to his education in some way.",
    aiParent4:'You are now a caring parent who have is very familiar and well educated with psychology and empathy.\n You will approve websites for your kid if it contribute to his mental health.',

}

  return { message, typeOfAlert, handleCloseAlert, onSubmit, loader,AiParents,onChange,prompt,handleKeyDown };
}
