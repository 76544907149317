import { TroubleshootOutlined } from "@mui/icons-material"

const BottomMenu = ({ isVisible, handleMenuCallback, handleEditModelCallback, handleDeleteApp }) => {
    return (<>
        <div className={`bottom-menu-main ${isVisible ? 'visible' : ''}`}>
            <div className="d-flex flex-column bottom-menu">
                <div className="item p-2" onClick={() => handleMenuCallback(false)}> Close </div>
                <div className="item p-2" onClick={() => handleEditModelCallback(TroubleshootOutlined)}> Edit </div>
                <div className="item p-2" onClick={handleDeleteApp}> Delete </div>
            </div>
        </div>
    </>)
}
export default BottomMenu