/* eslint-disable react-hooks/rules-of-hooks */
//It is add kid functionality, user can add kid by giving valid kid id.

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import db from "config/firebase";
import {
  TYPE_OF_ACTION,
  TYPE_OF_STATUS,
  REASON_OF_ACTION,
} from "description/constant";
import { useTranslation } from "react-i18next";
import { useRouter } from "hooks/use-router";

export default function removeAiParent() {
  const { t } = useTranslation();
  const { push } = useRouter()
  const [isSubmit, setIsSubmit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [id,setId] = useState('')
  const [typeOfAlert, setTypeOfAlert] = useState("");
  const { userInfo } = useSelector((state) => state.users);

  const userSettingInfo = useSelector(
    (state) => state.users.userList[userInfo?.email]
  );
//   const { parentsAi } = userSettingInfo || { parentsAi: [] };

  const parentsAi = (userSettingInfo?.parentsAi || []).filter(parent => parent.isConfirmed);

  useEffect(() => {
    getUpdatedStatus();
  }, [loader]);

  // It is function for getting the status of action which is currently executed by user and set error or success message accordingly.
  const getUpdatedStatus = () => {
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions`;
    db.collection(databaseUrl)
      .where("status", "==", TYPE_OF_STATUS.PENDING)
      .where("actionType", "==", `${TYPE_OF_ACTION.REMOVE_AI_PARENT}:${id}`)
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length) {
          setLoader(true);
          getUpdatedStatus();
        } else {
          setLoader(false);
          isSubmit &&
            db
              .collection(
                `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions`
              )
              .where(
                "actionType",
                "==",
                `${TYPE_OF_ACTION.REMOVE_AI_PARENT}:${id}`
              )
              .get()
              .then((snapshot) => {
                if (snapshot && snapshot.docs.length) {
                  const { status, reason } = snapshot.docs
                    .map((doc) => doc.data())
                    .pop();
                  if (status === TYPE_OF_STATUS.DENIED) {
                      setAlert('Some error occurred', "error");
                  
                    }
                    if (status === TYPE_OF_STATUS.MELTING){
                        setAlert('your request is in melting timer ', "success");
                    }
                   else {
                    push('/u')
                    setAlert('your request is approved and AI parent is removed', "success");
                  }
                }
              });
              setIsSubmit(false);
        }
      });
  };

  const setAlert = async (message, type) => {
    setTypeOfAlert(type);
    setMessage(message);
  };

  // It is onSubmit function, it pass the action if kid id is valid and not already kid.
  const onSubmit = (e,id,prompt) => {
    e.preventDefault();
    setLoader(true);
    const date = new Date().getTime();
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date} - ${TYPE_OF_ACTION.REMOVE_AI_PARENT}`;
    db.doc(databaseUrl).set({
    userId: userInfo?.email,
    actionId: `${date}-${TYPE_OF_ACTION.REMOVE_AI_PARENT}`,
    actionType: `${TYPE_OF_ACTION.REMOVE_AI_PARENT}:${id}`,
    status: TYPE_OF_STATUS.PENDING,
    createdTime: new Date(),
    name: TYPE_OF_ACTION.REMOVE_AI_PARENT,
    prompt:prompt,
    reason: ""
    });
    setId(id)
    setIsSubmit(true);
  };

  //For automatically close the alert after 5 seconds and clearing the field value.
  message &&
    setTimeout(() => {
      setMessage("");
      setTypeOfAlert("");
      setId('')
      push('/u')
    }, 3000);

  //For closing the alert notifications and clearing the field value.
  const handleCloseAlert = () => {
    setMessage("");
    setTypeOfAlert("");
    setId('')
    push('/u')
  };
 

  return { message, typeOfAlert, handleCloseAlert, onSubmit, loader,parentsAi };
}
