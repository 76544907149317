import React from "react";
import { premiumHero } from "component/Containers/NewLandingPage/imports";
import { useTranslation } from "react-i18next";

const PremiumHero = () => {
  const { t } = useTranslation();

  return (
    <div className="premium__hero">
      <div className="container">
        <div className="preamium__hero-inner">
          <div className="premium__hero-content">
            <h1 className="premium__hero-title">
              {t("PremiumHeroTitle_i18n")}{" "}
              <span className="blue">{t("PremiumHeroTitleSpan_i18n")}</span>
            </h1>
            <p className="premium__hero-text">{t("PremiumHeroText_i18n")}</p>
          </div>
          <div className="premium__hero-image">
            <img src={premiumHero} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumHero;
