import React, { Suspense, useEffect, useLayoutEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import NewHeader from "./component/Presentation/NewLandingPage/Header.jsx";
import UserDetails from "./component/Presentation/User/Lobby.jsx";
import AddKid from "./component/Presentation/User/AddKid.jsx";
import ApprovalLists from "./component/Presentation/User/ApprovalLists.jsx";
import Setting from "./component/Presentation/User/Settings.jsx";
import NightModeHours from "./component/Presentation/User/NightModeHours.jsx";
import WhoAmI from "./component/Presentation/User/WhoAmI.jsx";
import Notification from "./component/Presentation/User/Notification.jsx";
import SelfLock from "./component/Presentation/User/SelfLock.jsx";
import Diamond from "./component/Presentation/User/Diamond.jsx";
import Logout from "./component/Presentation/User/Logout.jsx";
import Blacklists from "./component/Presentation/User/BlackLists.jsx";
import { useDispatch, useSelector } from "react-redux";
import db from "./config/firebase";
import "antd/dist/antd.css";
import { LS_VARIABLE } from "./description/constant";
import Language from "./component/Presentation/User/Language.jsx";
import Main from "./component/Presentation/NewLandingPage/Main.jsx";

import FAQView from "./component/Presentation/NewLandingPage/Pages/FAQView.jsx";
import SupportView from "./component/Presentation/NewLandingPage/Pages/SupportView.jsx";
import PremiumView from "./component/Presentation/NewLandingPage/Pages/PremiumView.jsx";
import LoginPage from "./component/Presentation/NewLandingPage/Pages/LoginPage.jsx";
import NotFound from "./component/Presentation/NewLandingPage/ui/NotFound.jsx";
import NotAuthorize from "./component/Presentation/NewLandingPage/ui/NotAuthorize.jsx"
import AddParent from "./component/Presentation/User/AddParent.jsx";
import AddAiParent from "./component/Presentation/User/AddAiParent.jsx";
import RemoveAiParent from "./component/Presentation/User/RemoveAiParent.jsx";
import AppleBlockedUrl from "./component/Presentation/NewLandingPage/Pages/AppleBlockedUrl.jsx";
import Deceleration from "./component/Presentation/NewLandingPage/Pages/Deceleration.jsx"
import PairedTelegram from "./component/Presentation/User/PairedTelegram.jsx"
import MorningMode from "./component/Presentation/User/MorningMode.jsx";
import Header from "component/Presentation/NewLandingPage/ui/Header";

const App = () => {
  const userLists = useSelector((state) => state.users.userList);
  const { isHomePage, loginStatus, userInfo } = useSelector(
    (state) => state.users
  );
  const [addClassFlag, setAddClassFlag] = useState(false);
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");

  // useLayoutEffect(() => {
  //   if (loginStatus || localStorage.getItem(LS_VARIABLE.I18_NEXT_LANGUAGE)) {
  //     document.body.setAttribute(
  //       "dir",
  //       ["en", "es", "hi", "ru", "pt", "uk"].includes("en" ||
  //         localStorage.getItem(LS_VARIABLE.I18_NEXT_LANGUAGE)
  //       )
  //         ? "ltr"
  //         : "rtl"
  //     );
  //   }
  // }, []);

  useEffect(() => {
    JSON.parse(localStorage.getItem(LS_VARIABLE.IDS))?.length &&
      handleSnapshots();
  }, [loginStatus]);

  const handleSnapshots = () => {
    const userIds = JSON.parse(localStorage.getItem(LS_VARIABLE.IDS)) || [];
  
    userIds.forEach((userId) => {
      const userDocRef = db.collection(process.env.REACT_APP_FIREBASE_COLLECTION).doc(userId);
      userDocRef.onSnapshot((snapshot) => {
        if (snapshot.exists) {
          const userData = snapshot.data();
  
          dispatch({
            type: "USER_LIST",
            payload: userData,
          });
  
          const loggedUserInfo = JSON.parse(localStorage.getItem(LS_VARIABLE.LOGGED_USER_INFO));
          if (userData.userId === loggedUserInfo?.email) {
            const tempChildIds = userData.children
              ?.filter((val) => val?.isConfirmed)
              ?.map((el) => el?.childId);
            const tempParentIds = userData.parents
              ?.filter((val) => val?.isConfirmed)
              ?.map((el) => el?.parentId);
  
            const payload = {
              firstName: userData.firstName || null,
              lastName: userData.lastName || null,
              email: userData.email || null,
              id: userData.id || null,
              name: userData.name || null,
              picture: userData.picture || null,
              verified_email: userData.verified_email || null,
            };
            localStorage.setItem(LS_VARIABLE.LOGGED_USER_INFO, JSON.stringify(payload));
  
            const updatedIds = [...new Set([...userIds, ...tempChildIds, ...tempParentIds])];
            localStorage.setItem(LS_VARIABLE.IDS, JSON.stringify(updatedIds));
          }
        }
      });
    });
  };

  useEffect(() => {
    const temp = userLists[userInfo?.email]?.children
      ?.filter((val) => val?.isConfirmed)
      ?.map((el) => {
        return el?.childId;
      });
    temp?.forEach((el) => {
      db.collection(process.env.REACT_APP_FIREBASE_COLLECTION)
        .doc(el?.toString())
        .onSnapshot((doc) => {
          dispatch({
            type: "USER_LIST",
            payload: doc.data(),
          });
        });
    });
    const temp1 = userLists[userInfo?.email]?.parents
      ?.filter((val) => val?.isConfirmed)
      ?.map((el) => {
        return el?.parentId;
      });
    temp1?.forEach((el) => {
      db.collection(process.env.REACT_APP_FIREBASE_COLLECTION)
        .doc(el?.toString())
        .onSnapshot((doc) => {
          dispatch({
            type: "USER_LIST",
            payload: doc.data(),
          });
        });
    });
  }, [userLists[userInfo?.email]]);

  return (
    <>
      {/* <ApprovalLists /> */}
      <Router>
        {/* <Header
          handleAddClass={() => setAddClassFlag(!addClassFlag)}
          addClassFlag={addClassFlag}
        /> */}
        {!token && (
          <>
            {window.location.pathname === "/" || isHomePage ? (
              <NewHeader />
            ) : (
              <Header
                handleAddClass={() => setAddClassFlag(!addClassFlag)}
                addClassFlag={addClassFlag}
              />
            )}
          </>
        )}
        {/* <approval-list message="test Hello test" /> */}
        <Suspense fallback="Loading...">
          <Switch>
            <Route path="/faq" render={() => <FAQView />}></Route>
            <Route path="/support" render={() => <SupportView />}></Route>
            <Route path="/premium" render={() => <PremiumView />}></Route>
            <Route path="/i-doa" render={() => <Deceleration />}></Route>
            <Route path="/AppleBlockedURL" render={() => <AppleBlockedUrl />}></Route>
            {/* <Route exact path="/" render={() => <HomePage />} /> */}
            <Route exact path="/u" render={() => <UserDetails />} />
            <Route path="/u/language" render={() => <Language />} />
            <Route path="/u/premium" render={() => <Diamond />} />
            <Route path="/u/logout" render={() => <Logout />} />
            <Route path="/u/notifications" render={() => <Notification />} />
            <Route path="/u/:role/:id/whitelist"><ApprovalLists /></Route>
            <Route path="/u/:role/:id/AIbrain"><RemoveAiParent /></Route>
            <Route path="/u/:role/:id/apps" render={() => <Blacklists />} />
            <Route path="/login" render={() => <LoginPage />} />
            <Route path="/u/:role/:id/settings" render={() => <Setting />} />
            <Route path="/u/:role/:id/night-mode" render={() => <NightModeHours />} />
            <Route path="/u/:role/:id/morning-mode" render={() => <MorningMode />} />
            <Route path="/u/:role/:id/who-am-i" render={() => <WhoAmI />} />
            <Route path="/u/add-kid" render={() => <AddKid />} />
            <Route path="/u/add-parent" render={() => <AddParent />} />
            <Route path="/u/paired-telegram" render={() => <PairedTelegram />} />
            <Route path="/u/add-AI-parent" render={() => <AddAiParent />} />
            <Route path="/u/:role/:id/self-lock" render={() => <SelfLock />} />
            <Route exact path="/" render={() => <Main />}></Route>
            <Route exact path="/not-authorized" render={() => <NotAuthorize />}></Route>
            <Route path="*" render={() => <NotFound />} />
          </Switch>
        </Suspense>
      </Router>
      
    </>
  );
};

export default App;
