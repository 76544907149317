import React from "react";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import SentimentVeryDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentVeryDissatisfiedOutlined";
import { ReactComponent as ParentChild } from "assets/images/ParentChild.svg";
import { ReactComponent as WaterDrop } from "assets/images/waterDrop.svg";
import { ReactComponent as Location } from "assets/images/location.svg";
import { ReactComponent as Triangle } from "assets/images/triangle.svg";
import { ReactComponent as ApprovedList } from "assets/images/approved-list.svg";
import { ReactComponent as Settings } from "assets/images/settings.svg";
import { ReactComponent as NightMode } from "assets/images/night-mode.svg";
import { ReactComponent as WhoAmI } from "assets/images/who-am-i.svg";
import { ReactComponent as SelfLock } from "assets/images/self-lock.svg";
import { ReactComponent as Language } from "assets/images/language.svg";
import { ReactComponent as Android } from "assets/images/android.svg";
import { ReactComponent as Logout } from "assets/images/logout.svg";
import { ReactComponent as Freeze } from "assets/images/freeze.svg";
import { ReactComponent as Gps } from "assets/images/gps.svg";
import { ReactComponent as SimpleLock } from "assets/images/simple-lock.svg";
import { ReactComponent as Blacklist } from "assets/images/window-icon.svg"
import { ReactComponent as ManageKid } from "assets/images/manageKid.svg"
import { ReactComponent as RemoveKid } from "assets/images/removeKid.svg"
import { ReactComponent as MorningMode } from "assets/images/morning-mode.svg"
import { ReactComponent as DeleteAccount } from "assets/images/deleteAccount.svg"
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import AppBlockingOutlinedIcon from "@mui/icons-material/AppBlockingOutlined";
import SendToMobileOutlinedIcon from "@mui/icons-material/SendToMobileOutlined";

export const componentList = {
    parent: ["whitelist", "settings", "night-mode", "morning-mode", "apps", "self-lock", "who-am-i"],
    child: ["whitelist", "settings", "night-mode", "morning-mode", "apps", "who-am-i"],
    parentInChild: ["whitelist", "settings", "night-mode", "morning-mode", "apps", "who-am-i",],
    childInParent: ["whitelist", "settings", "night-mode", "morning-mode", "apps", "who-am-i"],
    packageUser: ["whitelist", "settings", "morning-mode", "apps", "who-am-i", "morning-mode", "apps"],
    packageInModerator: ["whitelist", "settings", "morning-mode", "apps", "who-am-i"],
};

export const classNames = {
    "approval-lists": "bi bi-list-ul",
    settings: "bi bi-gear-fill",
    "night-mode": "bi bi-moon-fill",
    "who-am-i": "bi bi-person-fill",
    "add-kid": "bi bi-person-plus-fill",
    "self-lock": "bi bi-lock-fill",
};

export const sidebarIcon = {
    "whitelist": <ApprovedList />,
    "global-approval-lists": <ApprovedList />,
    settings: <Settings />,
    "night-mode": <NightMode />,
    "who-am-i": <WhoAmI />,
    "add-kid": "bi bi-person-plus-fill",
    "self-lock": <SelfLock />,
    "apps": <Blacklist />,
    "morning-mode": <MorningMode />
};

export const settingPageIcons = {
    "language": <Language />,
    "android": <Android />,
    "logout": <Logout />,
    "whitelist": <ApprovedList />,
    "manageKid": <ManageKid />,
    "removeKid": <RemoveKid />,
    "deleteAccount": <DeleteAccount />
};
export const selfLockPageIcons = {
    "simple-lock": <SimpleLock />,
    "freeze-lock": <Freeze />,
    "gps-lock": <Gps />
}
export const showPageTitle = {
    "whitelist": "The Whitelist",
    "global-approval-lists": "Global Approval Lists",
    "settings": "Settings",
    "night-mode": "Night Mode",
    "who-am-i": "Who am I",
    "add-kid": "bi bi-person-plus-fill",
    "self-lock": "Self Lock",
    "apps": "Blacklist of apps",
    "morning-mode": "Morning Mode",
    "AIbrain": "Settings",
};
export const modeTimes = {
    hour: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
    ],
    minute: ["00", "15", "30", "45"],
};
export const icons = {
    child: {
        title: "kid_i18n",
        whoAmI: [
            {
                icon: <FingerprintIcon />,
                toolTip: "fingerprint",
            },
            {
                icon: <GroupsOutlinedIcon />,
                toolTip: "parent",
            },
            {
                icon: <ParentChild />,
                toolTip: "child",
            },
            {
                icon: <FaceOutlinedIcon />,
                toolTip: "face",
            },
            {
                icon: <AccessTimeOutlinedIcon />,
                toolTip: "clock",
            },
            {
                icon: <MonetizationOnOutlinedIcon />,
                toolTip: "dollar",
            },
        ],
        relation: [
            {
                icon: <PersonAddOutlinedIcon />,
                toolTip: "plusPerson",
            },
            {
                icon: <ThumbUpAltOutlinedIcon />,
                toolTip: "thumbsUp",
            },
            {
                icon: <ParentChild />,
                className: "not-allow",
                toolTip: "childKidNotAllow",
            },
            {
                icon: <SentimentVeryDissatisfiedOutlinedIcon />,
                className: "not-allow",
                toolTip: "dissatisfiedNotAllow",
            },
            {
                icon: <ThumbDownAltOutlinedIcon />,
                toolTip: "thumbsDown",
            },
        ],
        stop: [
            {
                icon: <SentimentVeryDissatisfiedOutlinedIcon />,
                toolTip: "dissatisfiedKid",
            },
            {
                icon: <ParentChild />,
                toolTip: "childKid",
            },
        ],
    },
    melting: {
        title: "frozenLockUser_i18n",
        whoAmI: [
            {
                icon: <FingerprintIcon />,
                toolTip: "fingerprint",
            },
            {
                icon: <GroupsOutlinedIcon />,
                toolTip: "parent",
            },
            {
                icon: <ParentChild />,
                toolTip: "child",
            },
            {
                icon: <AcUnitOutlinedIcon />,
                toolTip: "acUnit",
            },
            {
                icon: <AccessTimeOutlinedIcon />,
                toolTip: "clock",
            },
            {
                icon: <MonetizationOnOutlinedIcon />,
                toolTip: "dollar",
            },
        ],
        relation: [
            {
                icon: <PersonAddOutlinedIcon />,
                toolTip: "plusPerson",
            },
            {
                icon: <ThumbUpAltOutlinedIcon />,
                toolTip: "thumbsUp",
            },
            {
                icon: <ParentChild />,
                className: "not-allow",
                toolTip: "childNotAllow",
            },
            {
                icon: <SentimentVeryDissatisfiedOutlinedIcon />,
                className: "not-allow",
                toolTip: "dissatisfiedNotAllow",
            },
        ],
        stop: [
            {
                icon: <WaterDrop />,
                toolTip: "waterDrop",
            },
        ],
    },
    simple: {
        title: "simpleLockUser_i18n",
        whoAmI: [
            {
                icon: <FingerprintIcon />,
                toolTip: "fingerprint",
            },
            {
                icon: <GroupsOutlinedIcon />,
                toolTip: "parent",
            },
            {
                icon: <ParentChild />,
                toolTip: "child",
            },
            {
                icon: <LockOutlinedIcon />,
                toolTip: "lock",
            },
            {
                icon: <AccessTimeOutlinedIcon />,
                toolTip: "clock",
            },
            {
                icon: <MonetizationOnOutlinedIcon />,
                toolTip: "dollar",
            },
        ],
        relation: [
            {
                icon: <PersonAddOutlinedIcon />,
                toolTip: "plusPerson",
            },
            {
                icon: <ThumbUpAltOutlinedIcon />,
                toolTip: "thumbsUp",
            },
            {
                icon: <ParentChild />,
                className: "not-allow",
                toolTip: "childNotAllow",
            },
            {
                icon: <SentimentVeryDissatisfiedOutlinedIcon />,
                className: "not-allow",
                toolTip: "dissatisfiedNotAllow",
            },
        ],
        stop: [
            {
                icon: <LockOpenIcon />,
                toolTip: "unlock",
            },
        ],
    },
    gps: {
        title: "gpsLockUser_i18n",
        whoAmI: [
            {
                icon: <FingerprintIcon />,
                toolTip: "fingerprint",
            },
            {
                icon: <GroupsOutlinedIcon />,
                toolTip: "parent",
            },
            {
                icon: <ParentChild />,
                toolTip: "child",
            },
            {
                icon: <Location />,
                toolTip: "location",
            },
            {
                icon: <AccessTimeOutlinedIcon />,
                toolTip: "clock",
            },
            {
                icon: <MonetizationOnOutlinedIcon />,
                toolTip: "dollar",
            },
        ],
        relation: [
            {
                icon: <PersonAddOutlinedIcon />,
                toolTip: "plusPerson",
            },
            {
                icon: <ThumbUpAltOutlinedIcon />,
                toolTip: "thumbsUp",
            },
            {
                icon: <ParentChild />,
                className: "not-allow",
                toolTip: "childNotAllow",
            },
            {
                icon: <SentimentVeryDissatisfiedOutlinedIcon />,
                className: "not-allow",
                toolTip: "dissatisfiedNotAllow",
            },
        ],
        stop: [
            {
                icon: <Location />,
                toolTip: "locationUnlock",
            },
        ],
    },
    parent: {
        title: "freeUser_i18n",
        whoAmI: [
            {
                icon: <FingerprintIcon />,
                toolTip: "fingerprint",
            },
            {
                icon: <GroupsOutlinedIcon />,
                toolTip: "parent",
            },
            {
                icon: <ParentChild />,
                toolTip: "child",
            },
            {
                icon: <LightbulbOutlinedIcon />,
                toolTip: "bulb",
            },
            {
                icon: <AccessTimeOutlinedIcon />,
                toolTip: "clock",
            },
            {
                icon: <MonetizationOnOutlinedIcon />,
                toolTip: "dollar",
            },
        ],
        relation: [
            {
                icon: <PersonAddOutlinedIcon />,
                toolTip: "plusPerson",
            },
            {
                icon: <ThumbUpAltOutlinedIcon />,
                toolTip: "thumbsUp",
            },
            {
                icon: <ParentChild />,
                toolTip: "childRelation",
            },
            {
                icon: <SentimentVeryDissatisfiedOutlinedIcon />,
                toolTip: "dissatisfied",
            },
        ],
        stop: [
            {
                icon: <LockOutlinedIcon />,
                toolTip: "lockFree",
            },
            {
                icon: <ParentChild />,
                toolTip: "childFree",
            },
            {
                icon: <SentimentVeryDissatisfiedOutlinedIcon />,
                toolTip: "dissatisfiedFree",
            },
        ],
    },
    melted: {
        title: "meltLockUser_i18n",
        whoAmI: [
            {
                icon: <FingerprintIcon />,
                toolTip: "fingerprint",
            },
            {
                icon: <GroupsOutlinedIcon />,
                toolTip: "parent",
            },
            {
                icon: <ParentChild />,
                toolTip: "child",
            },
            {
                icon: <WaterDrop />,
                toolTip: "waterDropMelted",
            },
            {
                icon: <AccessTimeOutlinedIcon />,
                toolTip: "clock",
            },
            {
                icon: <MonetizationOnOutlinedIcon />,
                toolTip: "dollar",
            },
        ],
        relation: [
            {
                icon: <PersonAddOutlinedIcon />,
                toolTip: "plusPerson",
            },
            {
                icon: <ThumbUpAltOutlinedIcon />,
                toolTip: "thumbsUp",
            },
            {
                icon: <ParentChild />,
                className: "not-allow",
                toolTip: "childNotAllow",
            },
            {
                icon: <SentimentVeryDissatisfiedOutlinedIcon />,
                className: "not-allow",
                toolTip: "dissatisfiedNotAllow",
            },
        ],
        stop: [
            {
                icon: <LightbulbOutlinedIcon />,
                toolTip: "bulbMelted",
            },
            {
                icon: <AcUnitOutlinedIcon />,
                toolTip: "acUnitMelted",
            },
        ],
    },
    packageUser: {
        title: "packageUser_i18n",
        whoAmI: [
            {
                icon: <FingerprintIcon />,
                toolTip: "fingerprint",
            },
            {
                icon: <AppBlockingOutlinedIcon />,
                toolTip: "appBlockPackageUser",
            },
            {
                icon: <MonetizationOnOutlinedIcon />,
                toolTip: "dollar",
            },
        ],
        relation: [
            {
                icon: <ThumbUpAltOutlinedIcon />,
                toolTip: "thumbsUpPackageUser",
            },
        ],
        stop: [
            {
                icon: <SendToMobileOutlinedIcon />,
                toolTip: "sendToMobile",
            },
        ],
    },
    packageModerator: {
        title: "moderatorUser_i18n",
        whoAmI: [
            {
                icon: <FingerprintIcon />,
                toolTip: "fingerprint",
            },
            {
                icon: <AppBlockingOutlinedIcon />,
                toolTip: "appBlockModerator",
            },
            {
                icon: <MonetizationOnOutlinedIcon />,
                toolTip: "dollar",
            },
        ],
        relation: [
            {
                icon: <ThumbUpAltOutlinedIcon />,
                toolTip: "thumbsUpModerator",
            },
        ],
        stop: [
            {
                icon: <Triangle />,
                toolTip: "triangle",
            },
        ],
    },
};

export const settings = {
    generalSettings: {
        value: [
            "allowAllChannels",
            "allowBots",
            "allowAllPublicGroups",
            "allowAllPrivateGroups",
            "allowAllPublicUsers",
        ],
        label: [
            "allChannel",
            "allBots",
            "allPublicGroups",
            "allHugePrivateGroups",
            "allPublicUser",
        ],
    },
    specialSettings: {
        value: ["allowAllComments", "allowDTA", "allowAllPictures"],
        label: ["allComment", "DTA", "profilePicture"],
    },
    girlSettings: {
        value: ["FREE", "TEEN", "LITTLE"],
        label: ["free", "teen", "little"],
    },
};

export const TYPE_OF_ACTION = {
    ADD_CHAT: "addChat",
    PARENT_ADD_KID: "parentAddKid",
    PAIR_GMAIL_TO_TELEGRAM: "pairGmailToTelegram",
    STOP_PAIR_TELEGRAM: "googleStopPairToTelegram",
    CONFIRM_PAIR_TO_TELEGRAM: "gmailConfirmPairToTelegram",
    DENIED_PAIR_TO_TELEGRAM: "gmailDeniedPairToTelegram",
    KID_ADD_HUMAN_PARENT: "pleaseHumanBecomeMyParent",
    ADD_AI_PARENT: "pleaseAiBecomeMyParent",
    REMOVE_AI_PARENT: "pleaseAiStopParenting",
    ACCEPTING_TO_BECOMING_PARENT: "acceptingToBecomingParent",
    DENIED_TO_BECOMING_PARENT: "deniedToBecomingParent",
    REMOVE_CHANNEL: "removeChannel",
    NIGHT_MODE: "nightMode",
    // REMOVE_NIGHT_MODE: "removeNightMode",
    REMOVE_NIGHT_MODE: "removeNightMode",
    ADD_NIGHT_MODE: "addNightMode",
    KID_REQUEST: "kidRequest",
    PARENT_APPROVED: "parentApproved",
    KID_APPROVED: "kidApproved",
    PARENT_DENIED: "parentDenied",
    KID_DENIED: "kidDenied",
    NULL_TO_SIMPLE_LOCK: "typeOfLockNullToSimple",
    NULL_TO_FROZEN_LOCK: "typeOfLockNullToFrozen",
    FROZEN_TO_MELTING_LOCK: "typeOfLockFrozenToMelting",
    MELTING_TO_FROZEN_LOCK: "typeOfLockMeltingToFrozen",
    MELTED_TO_NULL_LOCK: "typeOfLockMeltedToNull",
    GPS_LOCK: "gpsLock",
    CHANGE_TIME_ZONE: "changeTimeZone",
    ADD_MORNING_MODE: "addMorningMode",
    REMOVE_MORNING_MODE: "removeMorningMode",
    ADD_BLACKLIST_APP_ANDROID: 'add_AppItemBlackListAppAndroid',
    DELETE_BLACKLIST_APP_ANDROID: 'delete_AppItemBlackListAppAndroid'
};

export const USER_TYPE = {
    PARENT: "parent",
    CHILD: "child",
    PARENT_IN_CHILD: "parentInChild",
    CHILD_IN_PARENT: "childInParent",
    PACKAGE_USER: "packageUser",
    PACKAGE_IN_MODERATOR: "packageInModerator",
    PACKAGE_MODERATOR: "packageModerator",
};

export const TYPE_OF_STATUS = {
    PENDING: "PENDING",
    REQUESTED: "REQUESTED",
    APPROVED: "APPROVED",
    DENIED: "DENIED",
    MELTING: "MELTING",
};

export const HIDE_SHOW_FLAG_NAME = {
    PARENT: "parent",
    KID: "kid",
    MODERATOR: "moderator",
};

export const regExs = {
    regExpPrivateLinkTg:
        /^(http|https|Https):\/\/(t|T).(m|M)e\/(joinchat\/|Joinchat\/|join\/Join\/)(.*)$/,
    regExpLinkTg: /^(http|https|Https):\/\/(t|T|telegram).(m|M)e\/(.*)$/,
    regExpLinkTgOld:
        /^(http|https|Https):\/\/(t|T|telegram).(m|M)e\/(joinchat\/|Joinchat\/|join\/|Join\/|)(.*)$/,
    regExpStartWithAt: /^@(.*)/,
    regExpStartWithoutHttp: /^(t|T|telegram).(m|M)e\/(.*)$/,
};



export const faqsList = [
    {
        id: 1,
        question: "Do I need this self lock ?",
        answer: "If you don’t have a parent , than this self lock is perfect for you, because it allows you to have zero distractions, and also allows you to approve new websites if you need to."
    },
    {
        id: 2,
        question: "Does it last forever ?",
        answer: "If you want to, than yes. But if you regret this self lock, you can always ask to delete it in this control panel, just remember you have to wait until  your timer’s request is finished"
    },
    {
        id: 3,
        question: "Can I delete this self lock later ?",
        answer: "Yes of course, all you have to do is 3 things: request the self lock to finish, wait the agreed time, and than ask to delete this timer forever."
    },
    {
        id: 4,
        question: "Can I approve new websites and settings ?",
        answer: "Yes, but only after you wait the duration of the melt timer, which dictates how much time you need to wait from the moment you request a website, until the moment you actually get this website."
    },
    {
        id: 5,
        question: "What is the “melt timer” ?",
        answer: `The amount of time it takes for any request to be accepted, for example: if your melt timer is 3 hours, and you added new website and 8 am – this website will be approved at 11 am.
    Melt time also dictates how much time it will take the frozen self lock to be deleted after you request it. for example: if your melt time is 7 days, and you request to cancel the self lock at 1.1.2022 ,  it will only be canceled at 8.1.2022`
    },
    {
        id: 6,
        question: "What is the “Freeze back” timer ?",
        answer: `This is a timer that is used only after the frozen self lock is canceled.
    After the “freeze back” timer is finished, it automatically restore back the self lock.
    
    For example if your “freeze back” timer is 1 hour, it means you have 1 hour to completely delete the frozen ice lock. If you missed this  window – than the self lock is restored and you have to wait the “melt timer again”.`
    }

];
export const scheduleList = [
    { value: 1, label: "01:00", className: "", default: false },
    { value: 2, label: "02:00", className: "", default: false },
    { value: 3, label: "03:00", className: "", default: false },
    { value: 4, label: "04:00", className: "", default: false },
    { value: 5, label: "05:00", className: "", default: false },
    { value: 6, label: "06:00", className: "", default: false },
    { value: 7, label: "07:00", className: "", default: false },
    { value: 8, label: "08:00", className: "", default: false },
    { value: 9, label: "09:00", className: "", default: false },
    { value: 10, label: "10:00", className: "", default: false },
    { value: 11, label: "11:00", className: "", default: false },
    { value: 12, label: "12:00", className: "", default: false },
    { value: 13, label: "13:00", className: "", default: false },
    { value: 14, label: "14:00", className: "", default: false },
    { value: 15, label: "15:00", className: "", default: false },
    { value: 16, label: "16:00", className: "", default: false },
    { value: 17, label: "17:00", className: "", default: false },
    { value: 18, label: "18:00", className: "", default: false },
    { value: 19, label: "19:00", className: "", default: false },
    { value: 20, label: "20:00", className: "", default: false },
    { value: 21, label: "21:00", className: "", default: false },
    { value: 22, label: "22:00", className: "", default: false },
    { value: 23, label: "23:00", className: "", default: false },
    { value: 24, label: "24:00", className: "", default: false },
    { value: "all", label: "All Day", className: "", default: false },
];

export const REASON_OF_ACTION = {
    INVALID_LINK: "inValidLink",
    ON_NIGHT_MODE: "onNightMode",
    PARENT_NIGHT_MODE_ON: "parentNightModeOn",
    UNDER_LOCK: "underLock",
    INVALID_KID: "inValidKid",
    INVALID_PARENT: "inValidParent",
    KID_HAVE_ANOTHER_PARENT: "kidHaveAnotherParent",
    ADD_YOURSELF_AS_KID: "addYourselfAsKid",
    ADD_YOURSELF_AS_PARENT: "addYourselfAsParent",
    KID_ALREADY_EXISTS: "kidAlreadyExists",
    PARENT_ALREADY_EXISTS: "parentAlreadyExists"
};

export const TYPE_OF_USER = {
    CHILD: "child",
    PACKAGE_USER: "packageUser",
    SIMPLE_LOCK: "simpleLock",
    FREEZE_LOCK: "freeze",
    MELTING: "melting",
    GPS_LOCK: "gpsLock",
    PARENT: "parent",
    PACKAGE_MODERATOR: "packageModerator",
};

export const TYPE_OF_LOCK = {
    SIMPLE_LOCK: "simple",
    FROZEN: "frozen",
    MELTED: "melted",
    MELTING: "melting",
    GPS: "gps",
};

export const TIMES = {
    "2 minutes": 2,
    "5 minutes": 5,
    "1 hour": 1,
    "3 hours": 3,
    "6 hours": 6,
    "24 hours": 24,
    "2 days": 2,
    "7 days": 7,
    "30 days": 30,
    NO_FREEZE: "noFreeze",
};

export const LS_VARIABLE = {
    IDS: "ids",
    I18_NEXT_LANGUAGE: "i18nextLng",
    LOGGED_USER_INFO: "loggedUserInfo",
    DEVICE: "device",
    FCM_TOKEN: "FCMToken",
    FB_USER_TOKEN: "FB_USER_TOKEN",
    GOOGLE_RESULT: "GOOGLE_RESULT",
};
export const AI_PARENT_MESSAGE = 'Your AI parent has decided the following:'