/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */

//This file is for manage the flag of "Manage Kid" setting.

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const manageKid = ({ handleCallActions, userInfo, userSettingInfo }) => {
  const [manageKidFlag, setManageKidFlag] = useState(true);
  const parentSettingInfo = useSelector((state) => state.users.userList[userInfo?.email]);

  useEffect(() => {
    parentSettingInfo?.childDontAccessIds.includes(userSettingInfo.userId)
      ? setManageKidFlag(false)
      : setManageKidFlag(true);
  }, [userSettingInfo, parentSettingInfo]);

  //For manage the flag change event.
  const handleUpdateManageKid = (name) => {
    handleCallActions(userSettingInfo.userId, name);
  };

  return { userId:userSettingInfo?.userId, handleUpdateManageKid, manageKidFlag };
};

export default manageKid;
