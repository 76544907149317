import { useMemo,useState } from "react";
import { useLocation  } from 'react-router-dom';

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  
  const AppleBlockedUrl = () => {
    
  const [copied, setCopied] = useState(false);
  let query = useQuery();

  const handleCopyClick = () => {
    navigator.clipboard.writeText(query.get("url"))
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 5000); // Reset the "copied" state after 1.5 seconds
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };
  return (
    <>
      <div className="notauthorize AppleBlockedUrl">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="row mb-5">
                <div className="col-lg-12">
                  <div className="page-title">
                    <h2>This URL is blocked</h2>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-4">
                  <p className="block-url-label">URL</p>
                </div>
                <div className="col-lg-6">
                  <p className="block-url-text">{query.get("url") || '-'}</p>
                </div>
                 {query.get("url")&&<div className="col-lg-2" >
                    <button className="copy-url-btn" onClick={handleCopyClick}>{copied ? 'Copied!' : 'Copy URL'}</button>
                 </div>}
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <p className="block-url-label">Event of block</p>
                </div>
                <div className="col-lg-6">
                  <p className="block-url-text">{query.get("eventType")?`It was blocked in a ${ query.get("eventType")} event`:'-'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppleBlockedUrl;
