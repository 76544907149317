import React from "react";
import { Alert , Spin } from "antd";
import pairedTelegram from "component/Containers/User/pairedTelegram";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function PairedTelegram() {
  const {
    message,
    typeOfAlert,
    handleCloseAlert,
    onSubmit,
    loader,
    pairedTelegramID,
  } = pairedTelegram();
  const { t } = useTranslation();
  return (
    <>
      <div className="ai-parent-wrp sidebar-right-side mt-5 overflow-hidden">
        {message && (
          <div>
            <Alert
              message={message}
              type={typeOfAlert}
              closable
              showIcon
              onClose={handleCloseAlert}
            />
          </div>
        )}
        {pairedTelegramID ? (
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="ai-parent-sugetions">
                  {t("pairedTelegramAccountsID__i18n:")}
                </div>
                <div className="telegram-id-text font-weight-bold">{pairedTelegramID}</div>
                <div className="ai-parent-sugetions mt-3">
                 {t("unpairedAnyTime_i18n")}
                </div>
                <div className="col-sm-12 mt-2">
                  <button className={"btn btn-danger"} onClick={(e)=>onSubmit(e)}>
                    {t("removePairing_i18n")}
                  </button>
                  {loader && (
                    <Spin indicator={<LoadingOutlined />} className="mx-2"></Spin>
                  )}
            </div>
            </div>
          </div>
        </div>
        ) : (
          <>
            <div className="container">
              <div className="row">{t("currentlyDontPaired__i18n")}</div>
            </div>
          </>
        )}
      </div>
    </>
  );
}