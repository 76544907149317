/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import db, { signInWithGoogle, auth } from "config/firebase";
import { useRouter } from "hooks/use-router";
import { useDispatch, useSelector } from "react-redux";
import {
  changeLanguage,
  getData,
  getInitialData,
} from "shared/CommanFunction";
import { LS_VARIABLE } from "description/constant";
import i18next from "i18next";

let data = [
  {
    label: "Download_i18n",
    link: "download",
  },
  {
    label: "whatIsIt_i18n",
    link: "whatIsIt",
  },
  {
    label: "Features_i18n",
    link: "features",
  },
  {
    label: "Cooporation_i18n",
    link: "cooporation",
  },
  {
    label: "Contact_i18n",
    link: "contact",
  },
  {
    label: "About_i18n",
    link: "about",
  },
];

function header({ handleAddClass, addClassFlag }) {
  const history = useHistory();
  const [notification, setNotification] = useState(0);
  const [loginUser, setLoginUser] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const language = localStorage.getItem(LS_VARIABLE.I18_NEXT_LANGUAGE);
  const { push, pathname } = useRouter();
  const dispatch = useDispatch();
  const { userInfo, loginStatus, notifications } = useSelector(
    (state) => state.users
  );

  const parentSettingInfo = useSelector(
    (state) => state.users.userList[userInfo?.email]
  );

  useEffect(() => {
    changeLanguage(parentSettingInfo?.language || "en", i18next);
  }, [parentSettingInfo]);

  useEffect(() => {
    const fetchData = async () => {
      const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/notification`;
      db.doc(
        `${databaseUrl}/${parentSettingInfo?.notificationsId?.sort((a, b) => a - b)[
        parentSettingInfo?.notificationsId?.length - 1
        ]
        }`
      ).onSnapshot((snapshot) => {
        snapshot &&
          snapshot?.data()?.notification &&
          setNotification(
            Object.values(snapshot?.data()?.notification)?.filter(
              (el) => el.isSeen === false
            ).length
          );
      });
    };
    parentSettingInfo?.notificationsId?.length && fetchData();
  });

  // const handleSelect = (event) => {
  //   document.body.setAttribute(
  //     "dir",
  //     event.target.value === "en" ? "ltr" : "rtl"
  //   );
  //   changeLanguage(event.target.value);
  // };

  useEffect(() => {
    dispatch({
      type: "LOCATION",
      payload:
        pathname === "/" ||
        pathname === "/faq" ||
        pathname === "/support" ||
        pathname === "/premium" ||
        pathname === "/i-doa" ||
        pathname === "/login",
    });
  }, [pathname]);

  useEffect(() => {
    if (!loginStatus) {
      const loggedUserInfo = JSON.parse(
        localStorage.getItem(LS_VARIABLE.LOGGED_USER_INFO)
      );
      setLoginUser(loggedUserInfo);
    }
  }, [loginStatus]);

  useEffect(() => {
    const header = document.querySelector("header");
    document.addEventListener("scroll", () => {
      var scroll_position = window.scrollY;
      if (scroll_position > 20) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });
  }, []);

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      const userInfo = JSON.parse(
        localStorage.getItem(LS_VARIABLE.LOGGED_USER_INFO)
      );
      dispatch({
        type: "SET_USER_DATA",
        payload: userInfo ? userInfo : {},
      });
      dispatch({
        type: "SET_LOGIN_FLAG",
        payload: user ? true : false,
      });
    });
  }, []);

  const handleAddClassName = () => {
    toggle();
  };

  const handleHideMenu = () => {
    addClassFlag && handleAddClass();
  };

  const handleLogin = async () => {
    try {
      const result = await signInWithGoogle();
      if (result?.additionalUserInfo?.profile) {
        const profile = result?.additionalUserInfo?.profile;
        const {
          email,
          family_name,
          given_name,
          id,
          name,
          picture,
          verified_email,
        } = profile;
        const databaseUrl = `Google users/${email}`;
        const initialData = getInitialData(email);
        const snapshotData = await (await db.doc(databaseUrl).get()).data();
        if (snapshotData) {
          const temp = snapshotData
            ? {
              firstName: snapshotData?.firstName || null,
              lastName: snapshotData?.lastName || null,
              email: snapshotData?.email || email || null,
              id: snapshotData?.id || null,
              name: snapshotData?.name || null,
              picture: snapshotData?.picture || null,
              verified_email: snapshotData?.verified_email || null,
            }
            : {};
          localStorage.setItem(
            LS_VARIABLE.LOGGED_USER_INFO,
            JSON.stringify(temp)
          );
          const data = getData(initialData, snapshotData);
          await db
            .collection("Google users")
            .doc(email.toString())
            .set({ ...data, ...temp }, { merge: true });
          dispatch({
            type: "SET_USER_DATA",
            payload: temp ? temp : {},
          });
        } else {
          const payload = {
            firstName: given_name || null,
            lastName: family_name || null,
            email: email || null,
            id: id || null,
            name: name || null,
            picture: picture || null,
            verified_email: verified_email || null,
          };
          localStorage.setItem(
            LS_VARIABLE.LOGGED_USER_INFO,
            JSON.stringify(payload)
          );
          dispatch({
            type: "SET_USER_DATA",
            payload: payload ? payload : {},
          });
          await db
            .collection("Google users")
            .doc(email.toString())
            .set({ ...initialData, ...payload }, { merge: true });
        }
        dispatch({
          type: "SET_LOGIN_FLAG",
          payload: true,
        });
        history.push("/u");
      }
    } catch (error) {
      console.error(`error`, error);
    }
  };

  const handleLogout = () => {
    try {
      auth.signOut();
      dispatch({
        type: "SET_LOGIN_FLAG",
        payload: false,
      });
      dispatch({
        type: "PURGE",
      });
      localStorage.removeItem(LS_VARIABLE.LOGGED_USER_INFO);
      localStorage.removeItem(LS_VARIABLE.DEVICE);
      localStorage.removeItem(LS_VARIABLE.IDS);
      dispatch({
        type: "SET_USER_DATA",
        payload: {},
      });
      history.push("/");
    } catch (error) {
      console.error(`error`, error);
    }
  };

  const handleOpenUserDetails = () => {
    push("/u");
  };

  return {
    pathname,
    handleHideMenu,
    toggle,
    isOpen,
    data,
    // handleSelect,
    // language,
    loginStatus,
    handleLogin,
    push,
    handleLogout,
    userInfo,
    handleAddClassName,
    notification,
    handleOpenUserDetails,
    notifications,
  };
}

export default header;
