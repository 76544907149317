import React from "react";
import { HIDE_SHOW_FLAG_NAME, USER_TYPE } from "description/constant";
import userThumbnail from "assets/images/userThumbnail.png";
import telegamIcon from "assets/images/Telegram-icon.png";
import lobby from "component/Containers/User/Lobby";
import { Alert } from "antd";
import { ReactComponent as PlusIcon } from "assets/images/plus-icon.svg";
import AIProfilePictureImage from "assets/images/AI_Profile_Picture.png";
import { ReactComponent as AiIcon } from "assets/images/memory.svg";
import { ReactComponent as EyeIcon } from "assets/images/eye.svg";
import { ReactComponent as ClosedEyeIcon } from "assets/images/eye-closed.svg";
import { useHistory } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
export default function Lobby() {
    const {
        kidRemovedMessage,
        homePage,
        selectedTab,
        handleCloseAlert,
        userInfo,
        handleSelectedTab,
        t,
        selectedCategory,
        parentList,
        handleSettingToggle,
        childrenList,
        push,
        currentPackage,
        onEyeClick,
        hideFlags,
        AiParentList,
        pairedTelegram
    } = lobby();
    const history = useHistory();
    const loggedUserInfo = JSON.parse(localStorage.getItem("loggedUserInfo"))
    return (
        <div>
            <div className="sidebar-right-side">
                <div className="tab-content" id="pills-tabContent">
                    {kidRemovedMessage && (
                        <Alert
                            message={kidRemovedMessage}
                            type="success"
                            closable
                            showIcon
                            onClose={handleCloseAlert}
                        />
                    )}
                    <div
                        className={`tab-pane ${homePage.includes(selectedTab) && "active"}`}
                        id="home-lists"
                        role="tabpanel"
                        aria-labelledby="home-lists-tab"
                    >
                        <div className="heading">
                            <h5>{t("myAccount_i18n")} </h5>
                        </div>
                        <div className="list-box border-bottom mb-3 pb-3">
                            <div
                                className="mb-3 cursor-pointer"
                                onClick={() => {
                                    handleSelectedTab("My Self", "parent");
                                    const roleId = 0;
                                    const role = "myaccount";
                                    history.push(`/u/${role}/${roleId}/whitelist`);
                                }}
                            >
                                <div className="home-detail boldName">
                                    <div className="chat-user-info align-items-center">
                                        <div className="user-img">
                                            {userInfo && userInfo?.picture ? (
                                                <img
                                                    src={userInfo?.picture || userThumbnail}
                                                    alt="info"
                                                />
                                            ) : (
                                                <UserOutlined className="dummy-img" />
                                                // <img
                                                //   src={userThumbnail}
                                                //   alt="info"
                                                //   className="dummy-img"
                                                // />
                                            )}
                                        </div>
                                        <div className="account-detail">
                                            <h4 className="d-flex">
                                                {userInfo?.firstName || <div>fName</div>} &nbsp; {userInfo?.lastName || <div>lName</div>}
                                            </h4>
                                            <h5>{userInfo?.email || <div>Email</div>}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                pairedTelegram &&
                                <div
                                    className="mb-3 cursor-pointer"
                                    onClick={() => {
                                        history.push(`/u/paired-telegram`);
                                    }}
                                >
                                    <div className="home-detail boldName h-100">
                                        <div className="chat-user-info align-items-center">
                                            <div className="user-img">
                                                <img
                                                    src={telegamIcon}
                                                    alt="telegramicon"
                                                />
                                            </div>
                                            <div className="account-detail">
                                                <h4 className="d-flex">
                                                    {pairedTelegram || <div>ERROR</div>}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>

                        <div className="heading">
                            <h5>
                                {t("myParent_i18n")}{" "}
                                <span onClick={() => onEyeClick(HIDE_SHOW_FLAG_NAME.PARENT)}>
                                    {hideFlags[HIDE_SHOW_FLAG_NAME.PARENT] ? (
                                        <EyeIcon />
                                    ) : (
                                        <ClosedEyeIcon />
                                    )}
                                </span>
                            </h5>
                        </div>
                        {hideFlags[HIDE_SHOW_FLAG_NAME.PARENT] && (
                            <div className="list-box border-bottom mb-3 pb-3">
                                {![
                                    USER_TYPE.PACKAGE_USER,
                                    USER_TYPE.PACKAGE_IN_MODERATOR,
                                ].includes(selectedCategory) &&
                                    parentList &&
                                    parentList.length ? (
                                    parentList.map((child, index) => {
                                        return (
                                            <>
                                                <div
                                                    className="mb-3 user-box-main"
                                                    key={index}
                                                    onClick={() => {
                                                        handleSettingToggle(
                                                            USER_TYPE.CHILD_IN_PARENT,
                                                            child
                                                        );
                                                        const role = "myparents";
                                                        const roleId = index;
                                                        history.push(`/u/${role}/${roleId}/whitelist`);
                                                    }}
                                                >
                                                    <div className="home-detail">
                                                        <div className="chat-user-info align-items-center">
                                                            <div className="user-img">
                                                                {child?.picture ? (
                                                                    <img
                                                                        src={
                                                                            child?.picture || userThumbnail
                                                                        }
                                                                        alt="info"
                                                                    />
                                                                ) : (
                                                                    <UserOutlined className="dummy-img" />
                                                                    // <img
                                                                    //   src={userThumbnail}
                                                                    //   className="dummy-img"
                                                                    //   alt="info"
                                                                    // />
                                                                )}
                                                            </div>
                                                            <div className="account-detail">
                                                                <h4>
                                                                    {child?.firstName
                                                                        ? `${child?.firstName + child?.lastName}`
                                                                        : child?.userName || <div>uName</div>}
                                                                </h4>
                                                                <h5>{child?.userId || <div>uId</div>}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })
                                ) :
                                    <div
                                        className="mb-3 user-box-main"
                                        onClick={() => {
                                            push("/u/add-parent");
                                        }}
                                    >
                                        <div className="add-box">
                                            <PlusIcon />
                                        </div>

                                    </div>}

                                {![
                                    USER_TYPE.PACKAGE_USER,
                                    USER_TYPE.PACKAGE_IN_MODERATOR,
                                ].includes(selectedCategory) &&
                                    AiParentList &&
                                    AiParentList.length ? (
                                    AiParentList.map((aiParent, index) => {
                                        return (
                                            <>
                                                <div
                                                    className="mb-3 mx-3 ai-parent-main user-box-main"
                                                    key={index}
                                                    onClick={() => {
                                                        const role = "myparents";
                                                        const roleId = index;
                                                        history.push(`/u/${role}/${roleId}/AIbrain`);
                                                    }}
                                                >

                                                    <div className="add-box" style={{ backgroundColor: "#fcfcf9" }}>
                                                        <div className="position-relative w-100" style={{ height: "80%" }}>
                                                            <img
                                                                className="img-fluid position-absolute top-50 start-50 translate-middle"
                                                                src={AIProfilePictureImage}
                                                                alt="AI Profile"
                                                            />
                                                        </div>
                                                        <div className="add-box-bottom-icon" style={{ bottom: "60px", right: "5px" }}>
                                                            <AiIcon />
                                                        </div>
                                                        <div className="position-absolute bottom-0 p-2 text-start w-100" style={{ backgroundColor: "#f4f8fd" }}>
                                                            <div
                                                                className="mb-1"
                                                                style={{
                                                                    fontSize: "16px",
                                                                    fontWeight: "bold",
                                                                    color: "#333333"
                                                                }}
                                                            >
                                                                Artificial Parent
                                                            </div>
                                                            <div className="py-1" style={{ fontSize: "14px", color: "gray" }}>GPT-4</div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </>
                                        );
                                    })
                                ) :
                                    <div
                                        className="mb-3 mx-3 user-box-main"
                                        onClick={() => {
                                            push("/u/add-AI-parent");
                                        }}
                                    >
                                        <div className="add-box">
                                            <PlusIcon />
                                            <div className="add-box-bottom-icon">
                                                <AiIcon />
                                            </div>
                                        </div>
                                    </div>}


                            </div>
                        )}

                        <div className="heading">
                            <h5>
                                {t("myKids_i18n")}{" "}
                                <span onClick={() => onEyeClick(HIDE_SHOW_FLAG_NAME.KID)}>
                                    {hideFlags[HIDE_SHOW_FLAG_NAME.KID] ? (
                                        <EyeIcon />
                                    ) : (
                                        <ClosedEyeIcon />
                                    )}
                                </span>
                            </h5>
                        </div>
                        {hideFlags[HIDE_SHOW_FLAG_NAME.KID] && (
                            <div className="list-box">
                                {![
                                    USER_TYPE.PACKAGE_USER,
                                    USER_TYPE.PACKAGE_IN_MODERATOR,
                                ].includes(selectedCategory) && (
                                        <>
                                            {childrenList.map((child, index) => {
                                                return (
                                                    <div
                                                        className="mb-3 user-box-main"
                                                        key={index}
                                                        onClick={() => {
                                                            handleSettingToggle(
                                                                USER_TYPE.PARENT_IN_CHILD,
                                                                child
                                                            );
                                                            const role = "mykids";
                                                            const roleId = index;
                                                            history.push(`/u/${"mykids"}/${roleId}/whitelist`);
                                                        }}
                                                    >
                                                        <div className="home-detail">
                                                            <div className="chat-user-info align-items-center">
                                                                <div className="user-img">
                                                                    {child?.picture ? (
                                                                        <img
                                                                            src={
                                                                                child?.picture || userThumbnail
                                                                            }
                                                                            alt="info"
                                                                        />
                                                                    ) : (
                                                                        <UserOutlined className="dummy-img" />
                                                                        // <img
                                                                        //   src={userThumbnail}
                                                                        //   className="dummy-img"
                                                                        //   alt="info"
                                                                        // />
                                                                    )}
                                                                </div>
                                                                <div className="account-detail">
                                                                    <h4>
                                                                        {child?.firstName
                                                                            ? `${child?.firstName + child?.lastName}`
                                                                            : child?.userName || <div>uName</div>}
                                                                    </h4>
                                                                    <h5>{child?.userId || <div>uId</div>}</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            <div
                                                className="mb-3 user-box-main"
                                                onClick={() => {
                                                    push("/u/add-kid");
                                                }}
                                            >
                                                <div className="add-box">
                                                    <PlusIcon />
                                                </div>
                                            </div>
                                        </>
                                    )}
                            </div>
                        )}
                        {selectedCategory === USER_TYPE.PACKAGE_USER && (
                            <>
                                <div className="heading">
                                    <h5>
                                        {t("moderator_i18n")}{" "}
                                        <span
                                            onClick={() => onEyeClick(HIDE_SHOW_FLAG_NAME.MODERATOR)}
                                        >
                                            {hideFlags[HIDE_SHOW_FLAG_NAME.MODERATOR] ? (
                                                <EyeIcon />
                                            ) : (
                                                <ClosedEyeIcon />
                                            )}
                                        </span>
                                    </h5>
                                </div>
                                {hideFlags[HIDE_SHOW_FLAG_NAME.MODERATOR] && (
                                    <div className="list-box">
                                        {currentPackage ? (
                                            <div
                                                className="mb-3 user-box-main"
                                                onClick={() =>
                                                    handleSettingToggle(USER_TYPE.PACKAGE_IN_MODERATOR)
                                                }
                                            >
                                                <div className="home-detail">
                                                    <div className="chat-user-info align-items-center">
                                                        <div className="user-img">
                                                            <UserOutlined className="dummy-img" />
                                                            {/* <img
                                src={userThumbnail}
                                className="dummy-img"
                                alt="info"
                              /> */}
                                                        </div>
                                                        <div className="account-detail">
                                                            <h4>
                                                                {currentPackage?.name
                                                                    ? currentPackage?.name
                                                                    : currentPackage?.moderatorId || <div>ERROR</div>}
                                                            </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="mb-4">
                                                {t("childNotAvailableMSG_i18n")}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
