import React from "react";
import { TYPE_OF_ACTION,AI_PARENT_MESSAGE } from "description/constant";
import InfiniteScroll from "react-infinite-scroll-component";
import { notifications } from "component/Containers/User/Notification";
import { useTranslation } from "react-i18next";
import approve from 'assets/images/approve.png'
import denite from 'assets/images/denite.png'
import noNotificationsImage from "assets/images/no_notifications.png";
import { ReactComponent as InfoIcon } from "assets/images/info.svg"
import {Tooltip } from "antd";

export default function Notification() {
  const { t } = useTranslation();
  const {
    myRef,
    nextCall,
    currentDoc,
    parentSettingInfo,
    multipleRef,
    handleRequest,
    button,
    notification,
  } = notifications();
  const arr = notification?.notification

  const getFormatedDate = (data) => {
    const temp = new Date(data * 1000)
    var monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dd = temp.getDate().toString()
    var month = monthList[temp.getMonth()]
    // var time = temp.getTime()
    var yyyy = temp.getFullYear().toString()
    const fulldate = dd + " " + month + " "
      + yyyy
    return fulldate
  }

  const getDateListWise = (index) => {
    const prevIndex = index - 1
    const currentDate = new Date(arr[index].createdAt.seconds)
    const prevDate = new Date(arr[prevIndex]?.createdAt.seconds)

    const getPrevDate = getFormatedDate(currentDate)
    const getCurrentDate = getFormatedDate(prevDate)

    if (getPrevDate !== getCurrentDate) {

      var date = new Date();
      var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      var todayDate = date.getDate().toString() + " " + month[date.getMonth()] + " " + date.getFullYear().toString()
      var yestDate = (date.getDate() - 1).toString() + " " + month[date.getMonth()] + " " + date.getFullYear().toString()

      if (todayDate == getFormatedDate(arr[index].createdAt.seconds)) {
        return "Today";
      } else if (yestDate == getFormatedDate(arr[index].createdAt.seconds)) {
        return "Yesterday";
      } else {
        return getFormatedDate(arr[index].createdAt.seconds)
      }
    }
  }
  const getReason = (message,iconDetails) => {
    console.log(message , AI_PARENT_MESSAGE)
    if (message === AI_PARENT_MESSAGE) {
        return iconDetails.isOpen ? "" : "reason-deniedByAiParent";
    }
    return "";
  }
  
  return (
    <div className="sidebar-right-side">
      <div className="row justify-content-center">
        <div
          className="notification-list"
        >
          <div
            className="notification-list"
            ref={myRef}
            id="scrollableDiv"
            style={{
              // height: window.innerHeight - 100,
              // overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
              alignItems: "center"
            }}
          >
            {notification?.notification?.length ? (
              <InfiniteScroll
                dataLength={notification?.notification?.length}
                next={nextCall}
                inverse={true}
                hasMore={
                  parentSettingInfo?.notificationsId?.length >= currentDoc + 1
                }
                scrollableTarget="scrollableDiv"
              >
                {notification?.notification ? (
                  notification?.notification?.map(
                    (
                      {
                        notificationType,
                        value,
                        message,
                        status,
                        isSeen,
                        request,
                        notificationId,
                        createdAt,
                        iconDetails = null
                      },
                      index
                    ) => {
                      return (
                        <div>
                          <div
                            key={index}
                          >
                            {/* {index === notification.unReadMessageIndex && (
                              <li className="unread-notifications" id="unread">
                                <span>{t("unreadNotification_i18n")}</span>
                              </li>
                            )} */}
                            {getDateListWise(index) && (
                              <div className="middle-date text-center" style={{ backGround: "#eeeff3" }}>
                                <p className="date-formet" id="dateId" style={{
                                }}>
                                  {
                                    getDateListWise(index)
                                  }
                                </p>
                              </div>)
                            }

                            <li style={{ borderRadius: '16px', boxShadow: '2px 6px 32px rgba(0, 9, 43, 0.1);', border: isSeen === false ? '2px solid #369FFF' : "" }}>
                              <div ref={multipleRef}>
                                <p className="message">{message}</p>
                                {
                                  iconDetails && (Array.isArray(iconDetails) ?
                                    <div className="icon-notification-main">
                                      {
                                        iconDetails?.map(iconDetail =>
                                          <div className={"icon notification-box " + getReason(message,iconDetail)}>
                                            {iconDetail?.explanationOfAi &&
                                              <Tooltip
                                              placement="bottom"
                                              title={
                                                iconDetail?.explanationOfAi
                                              }
                                            >
                                              <span className="ai-info">
                                                <InfoIcon />
                                              </span>
                                          </Tooltip>
                                            } 
                                            <a href={iconDetail?.url} target="_blank" rel="noreferrer">
                                              <div className="icon-container">
                                                <div className="icon-image-box"><img src={iconDetail?.previewImage} draggable="false" /></div>
                                                <div className="fav d-flex align-items-center justify-content-center"><img src={iconDetail?.favIcon} draggable="false" /></div>
                                              </div>
                                            </a>
                                            <div className="generic-item d-flex align-items-center justify-content-center">
                                              <p className="m-0 view-name w-100"> {iconDetail?.name}</p>
                                            </div>
                                          </div>)}
                                    </div>
                                    :
                                    <div className="icon notification-box">
                                      <a href={iconDetails?.url} target="_blank" rel="noreferrer">
                                        <div className="icon-container">
                                          <div className="icon-image-box"><img src={iconDetails?.previewImage} draggable="false" /></div>
                                          <div className="fav d-flex align-items-center justify-content-center"><img src={iconDetails?.favIcon} draggable="false" /></div>
                                        </div>
                                      </a>
                                      <div className="generic-item d-flex align-items-center justify-content-center">
                                        <p className="m-0 view-name w-100"> {iconDetails?.name}</p>
                                      </div>
                                    </div>)
                                }
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="d-flex justify-content-between align-items-center">
                                    {[
                                      TYPE_OF_ACTION.KID_REQUEST,
                                      TYPE_OF_ACTION.PARENT_ADD_KID,
                                      TYPE_OF_ACTION.KID_ADD_HUMAN_PARENT,
                                      TYPE_OF_ACTION.PAIR_GMAIL_TO_TELEGRAM
                                    ].includes(notificationType) && (

                                        <div className="d-flex justify-content-end mb-md-2 mb-3">
                                          {button.map((el, i) => {
                                            if (status && !status.includes(el))
                                              return (
                                                <React.Fragment
                                                  key={i}
                                                ></React.Fragment>
                                              );
                                            else
                                              return (
                                                <>
                                                  {
                                                    !status && <input
                                                      key={i}
                                                      type="button"
                                                      className={`${el === "accept"
                                                        ? "btn btn-primary ms-2"
                                                        : "btn btn-light"
                                                        } ${status && "disabled-cursor"}`}
                                                      value={
                                                        el === "accept"
                                                          ? t("acceptButton_i18n")
                                                          : t("denyButton_i18n")
                                                      }
                                                      disabled={status}
                                                      onClick={() => {
                                                        handleRequest(
                                                          el === "accept"
                                                          ? notificationType === TYPE_OF_ACTION.KID_REQUEST
                                                            ? TYPE_OF_ACTION.PARENT_APPROVED
                                                            : notificationType === TYPE_OF_ACTION.KID_ADD_HUMAN_PARENT
                                                              ? TYPE_OF_ACTION.ACCEPTING_TO_BECOMING_PARENT
                                                              : notificationType === TYPE_OF_ACTION.PAIR_GMAIL_TO_TELEGRAM
                                                                ? TYPE_OF_ACTION.CONFIRM_PAIR_TO_TELEGRAM
                                                                : TYPE_OF_ACTION.KID_APPROVED
                                                          : notificationType === TYPE_OF_ACTION.KID_REQUEST
                                                            ? TYPE_OF_ACTION.PARENT_DENIED
                                                            : notificationType === TYPE_OF_ACTION.KID_ADD_HUMAN_PARENT
                                                              ? TYPE_OF_ACTION.DENIED_TO_BECOMING_PARENT
                                                              :notificationType === TYPE_OF_ACTION.PAIR_GMAIL_TO_TELEGRAM
                                                                ? TYPE_OF_ACTION.DENIED_PAIR_TO_TELEGRAM 
                                                                : TYPE_OF_ACTION.KID_DENIED,
                                                            value,
                                                          notificationId,
                                                          request,
                                                          el,
                                                          iconDetails
                                                        );
                                                      }
                                                      }
                                                    />
                                                  }
                                                  {
                                                    (status && el === "accept") && <p className="app-btn">
                                                      <img src={approve} alt="" />
                                                      Approved</p>
                                                  }
                                                  {
                                                    (status && el === "deny") && <p className="den-btn">
                                                      <img src={denite} alt="" />
                                                      Denied</p>
                                                  }
                                                </>
                                              );
                                          })}
                                        </div>

                                      )}
                                  </div>
                                  <div style={{ color: "grey" }} className="d-flex justify-content-end">
                                    {new Date(createdAt?.seconds * 1000).toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })}
                                  </div>
                                </div>
                                {/* 
                                {(message.includes("APPROVED") || message.includes("DENIED")) && (<div className="d-flex justify-content-between align-items-center">
                                  <div className="button-main">
                                    {
                                      message.includes("APPROVED") && (
                                        <p className="app-btn">
                                          <img src={approve} alt="" />
                                          Approved</p>
                                      )
                                    }
                                    {
                                      message.includes("DENIED") && (
                                        <p className="den-btn">
                                          <img src={denite} alt="" />

                                          Denied</p>
                                      )
                                    }
                                  </div>
                                  <div style={{
                                    color: "grey"
                                  }} className="d-flex justify-content-end">
                                    {new Date(createdAt?.seconds * 1000).toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit' })}
                                  </div>
                                </div>)
                                } */}
                              </div>
                            </li>
                          </div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <li>{t("none_i18n")}</li>
                )}
                {/* </ul> */}
              </InfiniteScroll>
            ) : (
              <img 
                src={noNotificationsImage} 
                alt="No Notifications" 
                width={"30%"}
              />
            )}
          </div>
        </div>
      </div >
    </div >
  );
}
