import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-scroll";
import {
    Button,
    Collapse,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
} from "reactstrap";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Sidebar from "component/Presentation/User/Sidebar.jsx";
import { ReactComponent as NotificationIcon } from "assets/images/header-icons-notification.svg";
import { ReactComponent as NotificationIconDefult } from "assets/images/header-icons-notification-defult.svg";
import { ReactComponent as DiamondIcon } from "assets/images/header-icons-diamondIcon.svg";
import { ReactComponent as LogoutIcon } from "assets/images/header-icons-logout.svg";
import { ReactComponent as TranslateIcon } from "assets/images/header-icons-language.svg";
import { ReactComponent as TechSupportIcon } from "assets/images/tech_support.svg"
import { showPageTitle, USER_TYPE, LS_VARIABLE } from "description/constant";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { handleSetSeen } from "shared/CommanFunction";
import menuicon from 'assets/images/dots-blue.svg'
import menuicondefult from 'assets/images/dots-blue-defult.svg'
import { useParams } from "react-router-dom";
import { red } from "@mui/material/colors";
import header from "component/Containers/NewLandingPage/Header.js";
import SidebarMenu from "component/Presentation/User/SidebarMenu";

export default function Header({ handleAddClass, addClassFlag }) {
    const history = useHistory();
    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const { t } = useTranslation();
    const {
        pathname,
        handleHideMenu,
        toggle,
        isOpen,
        data,
        // handleSelect,
        // language,
        loginStatus,
        handleLogin,
        push,
        handleLogout,
        userInfo,
        handleAddClassName,
        notification,
        handleOpenUserDetails,
        notifications,
    } = header({ handleAddClass, addClassFlag });
    const paramsData = pathname.split('/')
    const pageTitle = paramsData[4];

    useEffect(() => {
        if (pathname.includes('/u/')) {
            const loggedUserInfo = JSON.parse(
                localStorage.getItem(LS_VARIABLE.LOGGED_USER_INFO)
            );
            if (!loggedUserInfo) {
                history.push("/not-authorized");
            }
        }
    }, [pathname]);
    //

    return (
        <>
            {
                !['/not-authorized', "/AppleBlockedURL"].includes(pathname) ? <header
                    className={`header-wrap ${[
                        "/",
                        "/u",
                        "/u/notifications",
                        "/u/premium",
                        "/u/logout",
                        "/u/add-kid",
                        "/u/paired-telegram",
                        "/u/add-parent",
                        "/u/add-AI-parent",
                        "/u/myparents/0/AIbrain",
                        "/u/language",
                        "/login",
                    ].includes(pathname)
                        ? ""
                        : "not-home"
                        }`}
                >
                    <Navbar fixed="top" light expand="md" className="relative" >
                        <div
                            style={{
                                position: "absolute",
                                top: "100%",
                                left: 0,
                                width: "100%",

                            }}
                            onClick={() => {
                                return push(`/u/notifications`);
                            }}
                        >
                            <div className="" style={{
                                cursor: "pointer",
                                height: "5px",
                                opacity: 1,
                                backgroundColor: notification ? "red" : "rgb(255, 230, 153)"
                            }}></div>
                            <div className="" style={{
                                cursor: "pointer",
                                height: "5px",
                                opacity: 0.5,
                                backgroundColor: notification ? "red" : "rgb(255, 230, 153)"
                            }}></div>
                        </div>

                        <div
                            className={pathname !== "/" ? "container-fluid" : "container"}
                            onClick={handleHideMenu}
                            style={{ padding: "12px 8px" }}
                        >
                            {pathname === "/" ? (
                                <React.Fragment>
                                    <NavbarBrand>{t("Teleteens_i18n")}</NavbarBrand>
                                    <NavbarToggler
                                        className={`${["he", "ar", "fa"].includes(i18next?.language)
                                            ? "me-auto"
                                            : "ms-auto "
                                            }`}
                                        onClick={toggle}
                                    />
                                    <Collapse
                                        isOpen={isOpen}
                                        navbar
                                        className="justify-content-end"
                                    >
                                        <Nav navbar>
                                            {data.map((val, index) => (
                                                <>
                                                    < NavItem key={index} className="nav-item" >
                                                        <Link
                                                            to={val.link}
                                                            spy={true}
                                                            smooth={true}
                                                            activeClass="active"
                                                            duration={200}
                                                            className="nav-link"
                                                        >
                                                            {t(`${val.label}`)}
                                                        </Link>
                                                    </NavItem>
                                                </>
                                            ))}
                                        </Nav>
                                    </Collapse>
                                    {!loginStatus ? (
                                        <Button
                                            className="ms-2"
                                            color="primary"
                                            onClick={handleLogin}
                                        >
                                            Login
                                        </Button>
                                    ) : (
                                        <Button
                                            className="ms-2 py-1 ml-4"
                                            color="primary"
                                            onClick={handleLogout}
                                        >
                                            {t("logout_i18n")}
                                        </Button>
                                    )}
                                </React.Fragment>
                            ) : (
                                ![
                                    "/u/add-kid",
                                    "/u/paired-telegram",
                                    "/u/add-parent",
                                    "/u/add-AI-parent",
                                    // "/u/myparents/0/AIbrain",
                                    "/u/notifications",
                                    "/u",
                                    "/u/premium",
                                    "/u/logout",
                                    "/login",
                                    "/u/language",
                                ].includes(pathname) && (
                                    <div className="page-heading">
                                        <Button
                                            onClick={handleAddClassName}
                                            type="button"
                                            className="navbar-toggler bg-transparent border-0 p-0 toggle-white"
                                        >
                                            <span className="navbar-toggler-icon"></span>
                                        </Button>
                                        {/* <h4 className="page-title">{showPageTitle[pathname]}</h4> */}
                                        <h4 className="page-title">{showPageTitle[pageTitle]}</h4>
                                    </div>
                                )
                            )}
                            {[
                                "/u",
                                "/u/notifications",
                                "/u/premium",
                                "/u/logout",
                                "/u/add-kid",
                                "/u/paired-telegram",
                                "/u/add-parent",
                                "/u/add-AI-parent",
                                // "/u/myparents/0/AIbrain",
                                "/u/language",
                            ].includes(pathname) && (
                                    <div className="header-icon">
                                        <div
                                            className="notification-icon"
                                            onClick={() => {
                                                return push(`/u/notifications`);
                                            }}
                                        >
                                            {/* <img
                    src={pathname === "/notification" ? BlueBellIcon : BellIcon}
                    alt=""
                  /> */}
                                            {notification ?
                                                <NotificationIcon /> : <NotificationIconDefult />
                                            }

                                            {
                                                notification !== 0 && <span
                                                    className={"notification-count"}
                                                    style={{
                                                        background: "#FE3B30",
                                                        top: "-7px",
                                                        right: "9px",
                                                    }}
                                                >
                                                    {notification <= 999 ? notification : `999+`}
                                                </span>
                                            }


                                        </div>

                                        <div className="dropdown">
                                            <button
                                                onClick={() => setIsOpenMenu(!isOpenMenu)}
                                                onBlur={() => setIsOpenMenu(false)}
                                                className="btn btn-secondary dropdown-toggle"
                                                type="button"
                                                id="dropdownMenuButton1"
                                                aria-expanded={isOpenMenu}
                                            >
                                                {isOpenMenu ? <img src={menuicon} alt="menu icon" /> : <img src={menuicondefult} alt="default menu icon" />}
                                            </button>
                                            <ul className={`dropdown-menu${isOpenMenu ? ' show' : ''}`} aria-labelledby="dropdownMenuButton1">
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        onMouseDown={(e) => e.preventDefault()}
                                                        onClick={() => {
                                                            if (pathname !== "/u/premium") {
                                                                push("/u/premium");
                                                            }
                                                            handleSetSeen(userInfo?.email, notifications);
                                                            setIsOpenMenu(!isOpenMenu)
                                                        }}
                                                    >
                                                        <div className="dropdown-box my-2 d-flex align-items-center">
                                                            <DiamondIcon />
                                                            <span className="ms-2">{t("Premium_i18n")}</span>
                                                        </div>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        onMouseDown={(e) => e.preventDefault()}
                                                        onClick={() => {
                                                            if (pathname !== "/u/language") {
                                                                push("/u/language");
                                                            }
                                                            handleSetSeen(userInfo?.email, notifications);
                                                            setIsOpenMenu(!isOpenMenu)
                                                        }}
                                                    >
                                                        <div className="dropdown-box my-2 d-flex align-items-center">
                                                            <TranslateIcon />
                                                            <span className="ms-2">{t("changeLanguage_i18n")}</span>
                                                        </div>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        onMouseDown={(e) => e.preventDefault()}
                                                        onClick={() => {
                                                            if (pathname !== "/support") {
                                                                push("/support");
                                                            }
                                                            handleSetSeen(userInfo?.email, notifications);
                                                            setIsOpenMenu(!isOpenMenu)
                                                        }}
                                                    >
                                                        <div className="dropdown-box my-2 d-flex align-items-center">
                                                            <TechSupportIcon />
                                                            <span className="ms-2">{t("techSupport__i18n")}</span>
                                                        </div>
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        className="dropdown-item"
                                                        onMouseDown={(e) => e.preventDefault()}
                                                        onClick={() => {
                                                            handleLogout();
                                                            push("/login");
                                                            setIsOpenMenu(!isOpenMenu)
                                                        }}
                                                    >
                                                        <div className="dropdown-box my-2 d-flex align-items-center">
                                                            <LogoutIcon />
                                                            <span className="ms-2">{t("logout_i18n")}</span>
                                                        </div>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>

                                        {/* <div
                    className="diamond-icon mx-3"
                    onClick={() => {
                      pathname === "/notification" &&
                        handleSetSeen(userInfo?.email, notifications);
                      return push("/diamond");
                    }}
                  >
                    <DiamondIcon />
                  </div>
                  <div
                    className="logout-icon"
                    onClick={() => {
                      pathname === "/notification" &&
                        handleSetSeen(userInfo?.email, notifications);
                      return push("/logout");
                    }}
                  >
                    <LogoutIcon />
                  </div>
                  <div
                    className="logout-icon ms-3"
                    onClick={() => {
                      pathname === "/notification" &&
                        handleSetSeen(userInfo?.email, notifications);
                      return push("/language");
                    }}
                  >
                    <TranslateIcon />
                  </div> */}
                                    </div>
                                )}
                            {loginStatus ? (
                                userInfo && userInfo?.picture && userInfo?.picture ? (
                                    <Avatar
                                        onClick={() => {
                                            pathname === "/u/notifications" &&
                                                handleSetSeen(userInfo?.email, notifications);
                                            return handleOpenUserDetails();
                                        }}
                                        src={userInfo?.picture}
                                        style={{
                                            backgroundColor: "#0dcaf0",
                                            verticalAlign: "middle",
                                        }}
                                        size="large"
                                    >
                                        {" "}
                                    </Avatar>
                                ) : (
                                    <Avatar
                                        onClick={() => {
                                            pathname === "/u/notifications" &&
                                                handleSetSeen(userInfo?.email, notifications);
                                            return handleOpenUserDetails();
                                        }}
                                        style={{
                                            backgroundColor: "#0dcaf0",
                                            verticalAlign: "middle",
                                        }}
                                        size="large"
                                    >
                                        {(userInfo?.first_name &&
                                            userInfo?.first_name.trim().charAt(0)) || <UserOutlined />}
                                    </Avatar>
                                )
                            ) : (
                                ""
                            )}
                        </div>
                    </Navbar>
                </header> : null

            }

            {
                !["/u/add-kid", "/u/paired-telegram", "/u/notifications", "/u/add-parent", "/", "/u/language", "/login", "/not-authorized", "/AppleBlockedURL"].includes(
                    pathname
                ) && (<>
                    <Sidebar
                        {...{
                            addClassFlag: isOpen,
                            userType: USER_TYPE.PARENT,
                            t,
                            toggle,
                        }}
                    />
                </>
                )
            }
            {
                ![
                    "/u/add-kid",
                    "/u/add-parent",
                    "/u/paired-telegram",
                    "/u/add-AI-parent",
                    "/u/myparents/0/AIbrain",
                    "/u/notifications",
                    "/",
                    "/u/language",
                    "/u",
                    "/u/premium",
                    "/u/logout",
                    "/login",
                    "/not-authorized",
                    "/AppleBlockedURL",
                    ""
                ].includes(pathname) &&
                <>
                    <SidebarMenu {...{ toggle }} />
                </>
            }

            {
                [
                    "/u/myparents/0/AIbrain",
                ].includes(pathname) &&
                <>
                    <SidebarMenu {...{ toggle }} />
                </>
            }

        </>
    );
}
