import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  // comment config is mobile config so
  // apiKey: "AIzaSyC8GVohm7MYWZSFK7Fxx1uGqZAQJ74t9P4",
  // authDomain: "teleteens.co",
  // databaseURL: "telegram-app-62f38.firebaseio.com",
  // projectId: "telegram-app-62f38",
  // storageBucket: "telegram-app-62f38.appspot.com",
  // messagingSenderId: "44354080259",
  // appId: "1:44354080259:android:944c4affa23f47bf014836",
  // apiKey: "AIzaSyBT5PovK7fU3EyNHJg--7VswRcRQGc5Haw",
  // authDomain: "telegram-app-62f38.firebaseapp.com",
  // databaseURL: "https://telegram-app-62f38.firebaseio.com",
  // projectId: "telegram-app-62f38",
  // storageBucket: "telegram-app-62f38.appspot.com",
  // messagingSenderId: "44354080259",
  // appId: "1:44354080259:web:1e1ebc48170cdec6014836",
  // measurementId: "G-K6KE3TVK1K",

  apiKey: "AIzaSyBPrgAoYS5G-JF31crq9jFq7bZSG6oG1hU",
  authDomain: "lockteens-cloud.firebaseapp.com",
  projectId: "lockteens-cloud",
  storageBucket: "lockteens-cloud.appspot.com",
  databaseURL: "https://lockteens-cloud.firebaseio.com",
  messagingSenderId: "699022554164",
  appId: "1:699022554164:web:beeaa1e149968847234661",
  measurementId: "G-CHXLZ11GX4"
};

firebase.initializeApp(config);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => auth.signInWithPopup(provider);
export const signInWithEmailAndPassword = (email, password) => auth.signInWithEmailAndPassword(email, password);
export const createUserWithEmailAndPassword = (email, password) => auth.createUserWithEmailAndPassword(email, password);
export const currentUserIdToken = () => auth.currentUser.getIdToken();
export const db = firebase.firestore();

export default db;
