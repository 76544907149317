import React from "react";
import { Alert } from "antd";
import selfLock from "component/Containers/User/SelfLock";
import { USER_TYPE } from "description/constant";
import { useTranslation } from "react-i18next";

export default function SelfLock() {
  const { t } = useTranslation();
  const { message, typeOfAlert, handleCloseAlert, waiting, showLoader, handleShowDetails, selectedCategory } =
    selfLock();

  return (
    <>
      {selectedCategory === USER_TYPE.CHILD ? (
        <div className="row mb-3" style={{ marginTop: '64px', marginLeft: '112px', overflow: 'hidden' }}>
          {t("notAllowed_i18n")}
        </div>
      ) : (
        <div className="sidebar-right-side side-bar-menu">
          {message && (
            <Alert variant={typeOfAlert} onClose={handleCloseAlert} dismissible>
              {message}
            </Alert>
          )}
          {waiting ? showLoader() : <div>{handleShowDetails()}</div>}
        </div>
      )}
    </>
  );
}
