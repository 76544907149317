import React, { useEffect, useState } from "react";
import { ReactComponent as TimeZone } from "assets/images/timezone.svg";
import { ReactComponent as MorningModeIcon } from "assets/images/morning-mode.svg";
import { ReactComponent as Location } from "assets/images/location.svg";
import { ReactComponent as Punish } from "assets/images/punish.svg";
import { ReactComponent as Reward } from "assets/images/reward.svg";
import { useTranslation } from "react-i18next";
import Picker from "react-mobile-picker-scroll";
import { TYPE_OF_STATUS, USER_TYPE } from "description/constant";
import Morning from "component/Containers/User/MorningMode.js";

import { Button } from "reactstrap";
import Countdown from "./Coundown.jsx"

export default function MorningMode() {
    const { t } = useTranslation();

    const getReasonTimer = (reasonObj) => {
        let updatedData = reasonObj?.filter(name => typeof name == 'object')[0]
        return updatedData ? updatedData['reasonTimer'] : null
    }

    const { timeZone,
        selectedCategory,
        showMapScreen,
        handleTimeZoneChange,
        fromOptions,
        untillOptions,
        fromValue,
        untillValue,
        showUntill,
        dayOfWeek,
        handleDaysCheckboxChange,
        handleUntillChange,
        onPickerHover,
        onRemovePickerHover,
        updateUntillHours,
        handleFromChange,
        impactZoneRadius,
        impactZoneRadiusChange,
        onRewardChange,
        onPunishmentChange,
        isAllDataFilled,
        handleCreateMorningMode,
        handleDeleteMorningMode,
        reward,
        beginHour,
        endHour,
        location,
        punishment,
        morningMode,
        morningMode_reason,
        handleOkbutton,
        checkReason } = Morning();

    const morningModeFaqsList = [
        {
            id: 1,
            question: t("morning_mode_q1_faq___i18n"),
            answer: t("morning_mode_a1_faq___i18n")
        },
        {
            id: 2,
            question: t("morning_mode_q2_faq___i18n"),
            answer: t("morning_mode_a2_faq___i18n")
        },
        {
            id: 3,
            question: t("morning_mode_q3_faq___i18n"),
            answer: t("morning_mode_a3_faq___i18n")
        },
        {
            id: 4,
            question: t("morning_mode_q4_faq___i18n"),
            answer: t("morning_mode_a4_faq___i18n")
        },
        {
            id: 5,
            question: t("morning_mode_q5_faq___i18n"),
            answer: t("morning_mode_a5_faq___i18n")
        },
    ]

    const faqs = () => {
        return (
            <div className="accordion accordion-flush" id="faqAccordian">
                {
                    morningModeFaqsList.map((el) => (
                        <div className="accordion-item">
                            <h2 className="accordion-header androied-header" id={"flush-headingfaq" + el.id}>
                                <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapsefaq" + el.id} aria-expanded="false" aria-controls={"flush-collapsefaq" + el.id}>
                                    <p>{el.question}<br /><span>{el.answer.slice(0, 40) + "..."}</span></p>
                                </div>
                            </h2>
                            <div id={"flush-collapsefaq" + el.id} className="accordion-collapse collapse" aria-labelledby={"flush-headingfaq" + el.id} data-bs-parent="#faqAccordian">
                                <div className="accordion-body">
                                    <p>{el.answer}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    const backFAQsAccordian = () => {
        return (
            <div className="accordion accordion-flush" id="backFAQsAccordian">
                <div className="accordion-item pb-0">
                    <h2 className="accordion-header androied-header" id="flush-headingOne-back-faq">
                        <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne-back-faq" aria-expanded="false" aria-controls="flush-collapseOne">
                            <p>{t("backToFaq___i18n")}<br /><span>{t("readItAnytimeYouWant___i18n")}</span></p>
                        </div>
                    </h2>
                    <div id="flush-collapseOne-back-faq" className="accordion-collapse collapse" aria-labelledby="flush-headingOne-back-faq" data-bs-parent="#backFAQsAccordian">
                        <div className="accordion-body p-0">
                            {faqs()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="sidebar-right-side side-bar-menu accordion-menu">
            {selectedCategory === USER_TYPE.CHILD_IN_PARENT ? (
                <div className="row mb-3">{t("notAllowed_i18n")}</div>
            ) : (
                <div className="row night-hour-form justify-content-center">
                    <div className="col-12">
                        {/* timezone accordian */}
                        <div className="accordion" id="timezoneAccordian">
                            <div className="accordion-item shadow-none border-0 p-0">
                                <h2
                                    className="accordion-header androied-header"
                                    id="timezoneAccordianHeading"
                                >
                                    <div
                                        className="accordion-button collapsed shadow-none"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#timezone-collapse"
                                        aria-expanded="false"
                                        aria-controls="timezone-collapse"
                                    >
                                        <TimeZone />
                                        <p>
                                            {t("timeZone___i18n")}
                                            <br />
                                            <span>{timeZone}</span>
                                        </p>
                                    </div>
                                </h2>
                                <div
                                    id="timezone-collapse"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="timezoneAccordianHeading"
                                    data-bs-parent="#timezoneAccordian"
                                >
                                    <div className="accordion-body">
                                        <div className="accordion-details">
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                value={timeZone}
                                                onChange={handleTimeZoneChange}
                                            >
                                                {Intl.supportedValuesOf("timeZone").map(
                                                    (timezoneItem) => (
                                                        <option value={timezoneItem} key={timezoneItem}>{timezoneItem}</option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* morning mode accordion */}
                        <div className="accordion" id="morningmodeAccordian">
                            <div className={"accordion-item shadow-none border-0 p-0 " + checkReason(morningMode_reason)}>
                                {getReasonTimer(morningMode_reason) && checkReason(morningMode_reason) == 'reason-melting'
                                    && <Countdown reasonTimer={getReasonTimer(morningMode_reason)} />}
                                {
                                    (['reason-deniedSimpleLock', 'reason-deniedByHumanParent', 'reason-sentToHumanParent'].includes(checkReason(morningMode_reason))) &&
                                    <div className="ok-button"><button type="button" className="btn btn-light" onClick={handleOkbutton} >Ok</button></div>
                                }
                                <h2
                                    className="accordion-header androied-header"
                                    id="morningmodeHeading"
                                >
                                    <div
                                        className="accordion-button shadow-none"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#item-collapse"
                                        aria-expanded="false"
                                        aria-controls="item-collapse"
                                    >
                                        <MorningModeIcon />
                                        <p>
                                            {t("morningMode___i18n")}
                                            <br />
                                            <span>{t("morningModePunishReward___i18n")}</span>
                                        </p>
                                    </div>
                                </h2>
                                <div
                                    id="item-collapse"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="morningmodeHeading"
                                    data-bs-parent="#morningmodeAccordian"
                                >
                                    <div className="accordion-body">
                                        <div className="accordion-details">
                                            <h5 className="mb-3 accordion-sub-text">
                                                {t("pleaseChooseFourOptions___i18n")}
                                            </h5>
                                            {/* morning mode actions */}
                                            <div
                                                className="accordion accordion-flush"
                                                id="morningmodeAccordianAction"
                                            >
                                                {/* morning mode time frame item */}
                                                <div className="accordion-item shadow-none border-0 p-0">
                                                    <h2
                                                        className="accordion-header androied-header"
                                                        id="morningmodetimeframe"
                                                    >
                                                        <div
                                                            className="accordion-button shadow-none"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#timeframe-collapse"
                                                            aria-expanded="false"
                                                            aria-controls="timeframe-collapse"
                                                        >
                                                            <TimeZone />
                                                            <p>
                                                                {t("timeFrame___i18n")}
                                                                <br />
                                                                <span> {(beginHour && endHour) ? `${beginHour}-${endHour}` : t("whenYouHaveToGo___i18n")}</span>
                                                            </p>
                                                        </div>
                                                    </h2>
                                                    <div
                                                        id="timeframe-collapse"
                                                        className="accordion-collapse collapse "
                                                        aria-labelledby="morningmodetimeframe"
                                                        data-bs-parent="#morningmodeAccordianAction"
                                                    >
                                                        <div className={(morningMode && Object.keys(morningMode).length > 0) ? "accordion-body disable-action" : 'accordion-body'}>
                                                            <div
                                                                className="accordion accordion-flush"
                                                                id="modeAccordian2"
                                                            >
                                                                <div className="accordion-details">
                                                                    <div className="accordion-item pb-0 pt-0 timeslot-items">
                                                                        <h2
                                                                            className="accordion-header androied-header"
                                                                            id="itemHeading"
                                                                        >
                                                                            <div
                                                                                className="accordion-button shadow-none"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={"#item-collapse-from"}
                                                                                data-bs-parent="#modeAccordian2"
                                                                                aria-expanded="false"
                                                                                aria-controls={"item-collapse-from"}
                                                                            // onClick={setNewUntillValue}
                                                                            >
                                                                                <div className="form-check website-detail">
                                                                                    <label
                                                                                        className="form-check-label d-flex"
                                                                                        htmlFor="flexUrlAppleOpen3"
                                                                                    >
                                                                                        <p>
                                                                                            From <br />
                                                                                            <span>
                                                                                                {beginHour}
                                                                                            </span>
                                                                                        </p>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </h2>
                                                                        <div
                                                                            id={"item-collapse-from"}
                                                                            className="accordion-collapse collapse shadow-none "
                                                                            aria-labelledby="itemHeading"
                                                                            data-bs-parent={"#modeAccordian2"}
                                                                        >
                                                                            <div className="accordion-body">
                                                                                <div className="accordion-details">
                                                                                    <div
                                                                                        className="d-picker"
                                                                                        onMouseEnter={onPickerHover}
                                                                                        onMouseLeave={onRemovePickerHover}
                                                                                        onTouchStart={onPickerHover}
                                                                                        onTouchEnd={onRemovePickerHover}
                                                                                    >
                                                                                        <Picker
                                                                                            optionGroups={fromOptions}
                                                                                            valueGroups={fromValue}
                                                                                            onChange={handleFromChange}
                                                                                        />
                                                                                        <Button
                                                                                            data-bs-toggle="collapse"
                                                                                            data-bs-target={
                                                                                                "#item-collapse-untill"
                                                                                            }
                                                                                            aria-expanded="false"
                                                                                            aria-controls={
                                                                                                "item-collapse-untill"
                                                                                            }
                                                                                            onClick={updateUntillHours}
                                                                                        >
                                                                                            Next
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-details">
                                                                    <div
                                                                        className={`accordion-item pb-0 pt-0 timeslot-items ${(showUntill || (morningMode && Object.keys(morningMode).length > 0)) ? "" : "d-none"
                                                                            }`}
                                                                    >
                                                                        <h2
                                                                            className="accordion-header androied-header"
                                                                            id="itemHeading"
                                                                        >
                                                                            <div
                                                                                className="accordion-button collapsed shadow-none"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={"#item-collapse-untill"}
                                                                                aria-expanded="false"
                                                                                data-bs-parent="#modeAccordian2"
                                                                                aria-controls={"item-collapse-untill"}
                                                                            // onClick={updateUntillHours}
                                                                            // onClick={setNewUntillValue}
                                                                            >
                                                                                <div className="form-check website-detail">
                                                                                    <label
                                                                                        className="form-check-label d-flex"
                                                                                        htmlFor="flexUrlAppleOpen3"
                                                                                    >
                                                                                        <p>
                                                                                            Untill <br />
                                                                                            <span>
                                                                                                {endHour}
                                                                                            </span>
                                                                                        </p>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </h2>
                                                                        <div
                                                                            id={"item-collapse-untill"}
                                                                            className="accordion-collapse collapse shadow-none "
                                                                            aria-labelledby="itemHeading"
                                                                            data-bs-parent={"#modeAccordian2"}
                                                                        >
                                                                            <div className="accordion-body">
                                                                                <div className="accordion-details">
                                                                                    <div
                                                                                        className="d-picker"
                                                                                        onMouseEnter={onPickerHover}
                                                                                        onMouseLeave={onRemovePickerHover}
                                                                                        onTouchStart={onPickerHover}
                                                                                        onTouchEnd={onRemovePickerHover}
                                                                                    >
                                                                                        <Picker
                                                                                            optionGroups={untillOptions}
                                                                                            valueGroups={untillValue}
                                                                                            onChange={handleUntillChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="week-check-list">
                                                                {Object.keys(dayOfWeek).sort().map(
                                                                    (day) => (
                                                                        <div
                                                                            key={day}
                                                                            className="day-of-week-check"
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id={`day${day}`}
                                                                                checked={dayOfWeek[day]}
                                                                                onChange={() =>
                                                                                    handleDaysCheckboxChange(day)
                                                                                }
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`day${day}`}
                                                                            >
                                                                                {day === "1_sunday"
                                                                                    ? t("sunday___i18n")
                                                                                    : day === "2_monday"
                                                                                        ? t("monday___i18n")
                                                                                        : day === "3_tuesday"
                                                                                            ? t("tuesday___i18n")
                                                                                            : day === "4_wednesday"
                                                                                                ? t("wednesday___i18n")
                                                                                                : day === "5_thursday"
                                                                                                    ? t("thursday___i18n")
                                                                                                    : day === "6_friday"
                                                                                                        ? t("friday___i18n")
                                                                                                        : t("saturday___i18n")}
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* morning mode location item */}
                                                <div className="accordion-item shadow-none border-0 p-0">
                                                    <h2
                                                        className="accordion-header androied-header"
                                                        id="morningmodelocation"
                                                    >
                                                        <div
                                                            className="accordion-button shadow-none"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#location-collapse"
                                                            aria-expanded="false"
                                                            aria-controls="location-collapse"
                                                        >
                                                            <Location />
                                                            <p>
                                                                {t("location___i18n")}
                                                                <br />
                                                                <span>{impactZoneRadius && location ? `${location.lat}  ${location.lng} ${t("radius___i18n")},  ${impactZoneRadius}` : t("whereYouHaveToGo___i18n")}</span>
                                                            </p>
                                                        </div>
                                                    </h2>
                                                    <div
                                                        id="location-collapse"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="morningmodelocation"
                                                        data-bs-parent="#morningmodeAccordianAction"
                                                    >
                                                        <div className={(morningMode && Object.keys(morningMode).length > 0) ? "accordion-body disable-action" : "accordion-body"}>
                                                            <div className="accordion-details">
                                                                <h5 className="mb-3 accordion-sub-text">
                                                                    {t("morningModeMapImpactZone___i18n")}
                                                                </h5>
                                                                <div
                                                                    className="accordion accordion-flush"
                                                                    id="modeAccordian2"
                                                                >
                                                                    <div className="accordion-details">
                                                                        <div className="accordion-item pb-0 pt-0 timeslot-items location-impact-box">
                                                                            <h2
                                                                                className="accordion-header androied-header"
                                                                                id="itemHeading"
                                                                            >
                                                                                <div
                                                                                    className="accordion-button shadow-none"
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target={"#location-radious"}
                                                                                    data-bs-parent="#modeAccordian2"
                                                                                    aria-expanded="false"
                                                                                    aria-controls={"location-radious"}
                                                                                // onClick={setNewUntillValue}
                                                                                >
                                                                                    <div className="form-check website-detail">
                                                                                        <label
                                                                                            className="form-check-label d-flex"
                                                                                            htmlFor="modeAccordian2"
                                                                                        >
                                                                                            <p>
                                                                                                {t("impactZoneAreaEffect___i18n")} <br />
                                                                                                <span>
                                                                                                    {impactZoneRadius ? `${impactZoneRadius} ` : t("meterRange___i18n")}

                                                                                                </span>
                                                                                            </p>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </h2>
                                                                            <div
                                                                                id={"location-radious"}
                                                                                className="accordion-collapse collapse shadow-none "
                                                                                aria-labelledby="itemHeading"
                                                                                data-bs-parent={"#modeAccordian2"}
                                                                            >
                                                                                <div className="accordion-body">
                                                                                    <div className="accordion-details">
                                                                                        <div className="form-check website-detail">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="radio"
                                                                                                name="impactZoneRadio"
                                                                                                id="100mImpact"
                                                                                                onChange={() =>
                                                                                                    impactZoneRadiusChange("100m")
                                                                                                }
                                                                                                checked={
                                                                                                    impactZoneRadius === "100m" ? true : false
                                                                                                }
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label d-flex"
                                                                                                htmlFor="100mImpact"
                                                                                            >
                                                                                                <p>
                                                                                                    {t("oneHundredMeters___i18n")}<br />
                                                                                                    <span>
                                                                                                        {t("acceptLocationlabel___i18n")}
                                                                                                    </span>{" "}
                                                                                                </p>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="form-check website-detail">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="radio"
                                                                                                name="impactZoneRadio"
                                                                                                id="500mImpact"
                                                                                                onChange={() =>
                                                                                                    impactZoneRadiusChange("500m")
                                                                                                }
                                                                                                checked={
                                                                                                    impactZoneRadius === "500m" ? true : false
                                                                                                }
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label d-flex"
                                                                                                htmlFor="500mImpact"
                                                                                            >
                                                                                                <p>
                                                                                                    {t("fiveHundredMeters___i18n")}<br />
                                                                                                    <span>
                                                                                                        {t("acceptLocationlabel___i18n")}
                                                                                                    </span>{" "}
                                                                                                </p>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="form-check website-detail">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="radio"
                                                                                                name="impactZoneRadio"
                                                                                                id="1000mImpact"
                                                                                                onChange={() =>
                                                                                                    impactZoneRadiusChange("1000m")
                                                                                                }
                                                                                                checked={
                                                                                                    impactZoneRadius === "1000m" ? true : false
                                                                                                }
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label d-flex"
                                                                                                htmlFor="1000mImpact"
                                                                                            >
                                                                                                <p>
                                                                                                    {t("oneThousandMeters___i18n")}<br />
                                                                                                    <span>
                                                                                                        {t("acceptLocationlabel___i18n")}
                                                                                                    </span>{" "}
                                                                                                </p>
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="form-check website-detail">
                                                                                            <input
                                                                                                className="form-check-input"
                                                                                                type="radio"
                                                                                                name="impactZoneRadio"
                                                                                                id="2000mImpact"
                                                                                                onChange={() =>
                                                                                                    impactZoneRadiusChange("2000m")
                                                                                                }
                                                                                                checked={
                                                                                                    impactZoneRadius === "2000m" ? true : false
                                                                                                }
                                                                                            />
                                                                                            <label
                                                                                                className="form-check-label d-flex"
                                                                                                htmlFor="2000mImpact"
                                                                                            >
                                                                                                <p>
                                                                                                    {t("twoThousandMeters___i18n")}<br />
                                                                                                    <span>
                                                                                                        {t("acceptLocationlabel___i18n")}
                                                                                                    </span>{" "}
                                                                                                </p>
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="morning-mode-location">
                                                                    {showMapScreen()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* morning mode punish item */}
                                                <div className="accordion-item shadow-none border-0 p-0">
                                                    <h2
                                                        className="accordion-header androied-header"
                                                        id="morningmodepunish"
                                                    >
                                                        <div
                                                            className="accordion-button shadow-none"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#punish-collapse"
                                                            aria-expanded="false"
                                                            aria-controls="punish-collapse"
                                                        >
                                                            <Punish />
                                                            <p>
                                                                {t("punish___i18n")}
                                                                <br />
                                                                <span> {(punishment) ? `${punishment}` : t("punishAfterNotComingOnTime___i18n")}</span>
                                                            </p>
                                                        </div>
                                                    </h2>
                                                    <div
                                                        id="punish-collapse"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="morningmodepunish"
                                                        data-bs-parent="#morningmodeAccordianAction"
                                                    >
                                                        <div className={(morningMode && Object.keys(morningMode).length > 0) ? "accordion-body disable-action" : "accordion-body"}>
                                                            <div className="accordion-details">
                                                                <div className="form-check website-detail">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="morningModePunish"
                                                                        id="defaultPunish"
                                                                        onChange={() =>
                                                                            onPunishmentChange("normalPunish")
                                                                        }
                                                                        checked={
                                                                            punishment === "normalPunish" ? true : false
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="form-check-label d-flex"
                                                                        htmlFor="defaultPunish"
                                                                    >
                                                                        <p>
                                                                            {t("defaultPunishment___i18n")} <br />
                                                                            <span>
                                                                                {t("defaultPunishmentOption___i18n")}
                                                                            </span>{" "}
                                                                        </p>
                                                                    </label>
                                                                </div>
                                                                <div className="form-check website-detail">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="morningModePunish"
                                                                        onChange={() =>
                                                                            onPunishmentChange("noPunish")
                                                                        }
                                                                        checked={
                                                                            punishment === "noPunish" ? true : false
                                                                        }
                                                                        id="nothingPunish"
                                                                    />
                                                                    <label
                                                                        className="form-check-label d-flex"
                                                                        htmlFor="nothingPunish"
                                                                    >
                                                                        <p>
                                                                            {t("noPunishment___i18n")} <br />
                                                                            <span>{t("noPunishmentOption___i18n")}</span>{" "}
                                                                        </p>
                                                                    </label>
                                                                </div>
                                                                <div className="form-check website-detail">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="morningModePunish"
                                                                        onChange={() =>
                                                                            onPunishmentChange("smallPunish")
                                                                        }
                                                                        checked={
                                                                            punishment === "smallPunish" ? true : false
                                                                        }
                                                                        id="smallPunish"
                                                                    />
                                                                    <label
                                                                        className="form-check-label d-flex"
                                                                        htmlFor="smallPunish"
                                                                    >
                                                                        <p>
                                                                            {t("smallPunishment___i18n")} <br />
                                                                            <span>
                                                                                {t("smallPunishmentOption___i18n")}
                                                                            </span>{" "}
                                                                        </p>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* morning mode reward item */}
                                                <div className="accordion-item shadow-none border-0 p-0">
                                                    <h2
                                                        className="accordion-header androied-header"
                                                        id="morningmodereward"
                                                    >
                                                        <div
                                                            className="accordion-button shadow-none"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#reward-collapse"
                                                            aria-expanded="false"
                                                            aria-controls="reward-collapse"
                                                        >
                                                            <Reward />
                                                            <p>
                                                                {t("reward___i18n")}
                                                                <br />
                                                                <span> {(reward) ? `${reward}` : "The reward after coming on time"}</span>
                                                            </p>
                                                        </div>
                                                    </h2>
                                                    <div
                                                        id="reward-collapse"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="morningmodereward"
                                                        data-bs-parent="#morningmodeAccordianAction"
                                                    >
                                                        <div className={(morningMode && Object.keys(morningMode).length > 0) ? "accordion-body disable-action" : "accordion-body"}>
                                                            <div className="accordion-details">
                                                                <div className="form-check website-detail">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="morningModeReward"
                                                                        id="defaultReward"
                                                                        onChange={() =>
                                                                            onRewardChange("normalReward")
                                                                        }
                                                                        checked={
                                                                            reward === "normalReward" ? true : false
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="form-check-label d-flex"
                                                                        htmlFor="defaultReward"
                                                                    >
                                                                        <p>
                                                                            {t("defaultReward___i18n")} <br />
                                                                            <span>
                                                                                {t("defaultRewardOption___i18n")}
                                                                            </span>{" "}
                                                                        </p>
                                                                    </label>
                                                                </div>
                                                                <div className="form-check website-detail">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="morningModeReward"
                                                                        onChange={() =>
                                                                            onRewardChange("bigReward")
                                                                        }
                                                                        checked={
                                                                            reward === "bigReward" ? true : false
                                                                        }
                                                                        id="bigReward"
                                                                    />
                                                                    <label
                                                                        className="form-check-label d-flex"
                                                                        htmlFor="bigReward"
                                                                    >
                                                                        <p>
                                                                            {t("bigReward___i18n")} <br />
                                                                            <span>{t("bigRewardOption___i18n")}</span>{" "}
                                                                        </p>
                                                                    </label>
                                                                </div>
                                                                <div className="form-check website-detail">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="morningModeReward"
                                                                        onChange={() =>
                                                                            onRewardChange("noReward")
                                                                        }
                                                                        checked={
                                                                            reward === "noReward" ? true : false
                                                                        }
                                                                        id="noReward"
                                                                    />
                                                                    <label
                                                                        className="form-check-label d-flex"
                                                                        htmlFor="noReward"
                                                                    >
                                                                        <p>
                                                                            {t("noReward___i18n")}<br />
                                                                            <span>
                                                                                {t("noRewardOption___i18n")}
                                                                            </span>{" "}
                                                                        </p>
                                                                    </label>
                                                                </div>
                                                                <div className="form-check website-detail">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="morningModeReward"
                                                                        onChange={() =>
                                                                            onRewardChange("timelyReward")
                                                                        }
                                                                        checked={
                                                                            reward === "timelyReward" ? true : false
                                                                        }
                                                                        id="timelyReward"
                                                                    />
                                                                    <label
                                                                        className="form-check-label d-flex"
                                                                        htmlFor="timelyReward"
                                                                    >
                                                                        <p>
                                                                            {t("timelyReward___i18n")}<br />
                                                                            <span>
                                                                                {t("timelyRewardOption___i18n")}
                                                                            </span>{" "}
                                                                        </p>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    {
                                                        (morningMode && Object.keys(morningMode).length > 0) ?
                                                            <input
                                                                type="button"
                                                                value="Delete morning mode"
                                                                disabled={!isAllDataFilled()}
                                                                onClick={
                                                                    handleDeleteMorningMode
                                                                }
                                                                className={`btn btn-danger `}
                                                            /> :
                                                            <input
                                                                type="button"
                                                                value="Save"
                                                                disabled={!isAllDataFilled()}
                                                                onClick={
                                                                    handleCreateMorningMode
                                                                }
                                                                className={`btn btn-primary `}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {backFAQsAccordian()}
                    </div>
                </div>
            )}
        </div>
    );
}
