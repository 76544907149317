export const appBlackListAndroidItems = [
    {
        "appItem": [
            {
                "packageName": "org.telegram.messenge",
                "name": "Telegram",
                "previewImage": "https://play-lh.googleusercontent.com/ZU9cSsyIJZo6Oy7HTHiEPwZg0m2Crep-d5ZrfajqtsH-qgUXSqKpNA2FpPDTn-7qA5Q=w240-h480"
            }
        ]
    },
    {
        "appItem": [
            {
                "packageName": "com.alibaba.aliexpresshd",
                "name": "AliExpress",
                "previewImage": "https://play-lh.googleusercontent.com/8XePOjdAbTm12TJ4Cu3-DnQxT7kG-ptsu6yqXMusO5nbCs1s9GeVPwDMqMAMi4rxTYSV=w240-h480"
            }
        ]
    },
    {
        "appItem": [
            {
                "packageName": "com.instagram.android",
                "name": "Instagram",
                "previewImage": "https://play-lh.googleusercontent.com/VRMWkE5p3CkWhJs6nv-9ZsLAs1QOg5ob1_3qg-rckwYW7yp1fMrYZqnEFpk0IoVP4LM=w240-h480"
            }
        ]
    }

]