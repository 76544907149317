/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import  { auth, signInWithGoogle, currentUserIdToken, db} from "config/firebase";
import { LS_VARIABLE } from "description/constant";
import { nightModeItems } from "dummyData/nightModeItems"
import { appBlackListAndroidItems } from "dummyData/appBlackListAndroidItems"
import { useRouter } from "hooks/use-router";
import { getData, getInitialData } from "shared/CommanFunction";

export default function loginButton() {
  const dispatch = useDispatch();
  const { push } = useRouter();
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const { loginStatus, userInfo, notifications } = useSelector(
    (state) => state.users
  );

  const handleLogin = async () => {
    try {
      const result = await signInWithGoogle();
      const currentUserFBToken = await currentUserIdToken();
      localStorage.setItem(
        LS_VARIABLE.GOOGLE_RESULT,
        JSON.stringify(result)
      );
      localStorage.setItem(
        LS_VARIABLE.FB_USER_TOKEN,
        currentUserFBToken
      );
      if (result?.additionalUserInfo?.profile) {
        setLoading(true);
        const profile = result?.additionalUserInfo?.profile;
        const {
          email,
          family_name,
          given_name,
          id,
          name,
          picture,
          verified_email,
        } = profile;
        localStorage.setItem(
          LS_VARIABLE.IDS,
          JSON.stringify([email?.toString()])
        );
        const databaseUrl = `Google users/${email}`;
        const initialData = getInitialData(email);
        const snapshotData = await (await db.doc(databaseUrl).get()).data();
        if (snapshotData) {
          const temp = snapshotData
            ? {
              firstName: snapshotData?.firstName || null,
              lastName: snapshotData?.lastName || null,
              email: snapshotData?.email || email || null,
              id: snapshotData?.id || null,
              name: snapshotData?.name || null,
              picture: snapshotData?.picture || null,
              verified_email: snapshotData?.verified_email || null,
            }
            : {};
          localStorage.setItem(
            LS_VARIABLE.LOGGED_USER_INFO,
            JSON.stringify(temp)
          );
          const data = getData(initialData, snapshotData);
          if (!snapshotData.timeZone)
            temp.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          await db
            .collection("Google users")
            .doc(email.toString())
            .set({ ...data, ...temp }, { merge: true });

          if ((snapshotData.nightModeItems && snapshotData.nightModeItems.length >= 0) || (!Object.entries(snapshotData.nightModeItems).length > 0)) {
            await db
              .collection("Google users")
              .doc(email.toString())
              .set({ nightModeItems }, { merge: true });
          }
          if (!snapshotData.appBlackListAndroid || snapshotData.appBlackListAndroid.length <= 0) {
            await db
              .collection("Google users")
              .doc(email.toString())
              .set({ appBlackListAndroid: appBlackListAndroidItems }, { merge: true });
          }
          dispatch({
            type: "SET_USER_DATA",
            payload: temp ? temp : {},
          });

        } else {
          const payload = {
            firstName: given_name || null,
            lastName: family_name || null,
            email: email || null,
            id: id || null,
            name: name || null,
            picture: picture || null,
            verified_email: verified_email || null,
          };
          localStorage.setItem(
            LS_VARIABLE.LOGGED_USER_INFO,
            JSON.stringify(payload)
          );
          dispatch({
            type: "SET_USER_DATA",
            payload: payload ? payload : {},
          });

          await db
            .collection("Google users")
            .doc(email.toString())
            .set({ ...initialData, ...payload }, { merge: true });

          await db
            .collection("Google users")
            .doc(email.toString())
            .set({ nightModeItems, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, appBlackListAndroid: appBlackListAndroidItems }, { merge: true });
          // await db
          //   .collection("Google users")
          //   .doc(email.toString())
          //   .set({ ...initialData, ...payload }, { merge: true });
        }


        dispatch({
          type: "SET_LOGIN_FLAG",
          payload: true,
        });
        setLoading(false);
        history.push("/u");
      }
    } catch (error) {
      console.error(`error`, error);
    }
  };

  const handleLogout = () => {
    try {
      auth.signOut();
      dispatch({
        type: "SET_LOGIN_FLAG",
        payload: false,
      });
      dispatch({
        type: "PURGE",
      });
      localStorage.removeItem(LS_VARIABLE.LOGGED_USER_INFO);
      localStorage.removeItem(LS_VARIABLE.DEVICE);
      localStorage.removeItem(LS_VARIABLE.IDS);
      dispatch({
        type: "SET_USER_DATA",
        payload: {},
      });
      history.push("/login");
    } catch (error) {
      console.error(`error`, error);
    }
  };
  const handleOpenUserDetails = () => {
    push("/u");
  };

  const handleGoToLoginPage = () => {
    push("/login");
  };

  return {
    loginStatus,
    handleLogout,
    userInfo,
    handleOpenUserDetails,
    notifications,
    handleLogin,
    loading,
    handleGoToLoginPage,
  };
}
