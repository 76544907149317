import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import i18n from "../src/component/i18n/i18n";
import { I18nextProvider } from "react-i18next";
import "../src/assets/css/style.css";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./redux";
import { Provider } from "react-redux";

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
