import React from "react";
import { Button } from "reactstrap";
import loginButton from "component/Containers/NewLandingPage/LoginButton";
const LoginBtn = ({ className }) => {
  const { handleGoToLoginPage } = loginButton();

  return (
    <>
      <Button
        className={`ml-4 login-btn ${className}`}
        color="primary"
        onClick={handleGoToLoginPage}
      >
        Login
      </Button>
    </>
  );
};

export default LoginBtn;
