/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
// User can set simple, frozen, and GPS lock in self-lock functionality.
// I get the information of user current status from typeofLock variable, I get the typeofLock from the index page by making conditions on userSettingInfo. If a user is not in any type of lock then the user is able to self-lock, else a lock screen or timers are shown on the screen.

import React, { useEffect, useState } from "react";
import { db } from "config/firebase";
import Map from "component/Containers/User/Map";
import { formatDistanceToNowStrict, fromUnixTime, differenceInSeconds, addSeconds } from "date-fns";
import {
  selfLockPageIcons,
  TYPE_OF_STATUS,
  TYPE_OF_ACTION,
  TYPE_OF_LOCK,
  TIMES,
} from 'description/constant'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { handleDeniedActionReasons } from "shared/CommanFunction";
import { useSelector, useDispatch } from "react-redux";
import waitingIcon from "assets/images/waiting.gif";
import { useTranslation } from "react-i18next";

export default function selfLock() {
  const { t } = useTranslation();
  const hoursOfOneDay = 24;
  const minutesOfOneHour = 60;
  const secondsOfOneMinute = 60;
  const millisecondsOfOneSecond = 1000;
  const lockTimeLabel = [
    {
      title: "2 minutes",
      label: "2 minutes",
      value: TIMES["2 minutes"] * secondsOfOneMinute * millisecondsOfOneSecond,
    },
    {
      title: "5 minutes",
      label: "5 minutes",
      value: TIMES["5 minutes"] * secondsOfOneMinute * millisecondsOfOneSecond,
    },
    {
      title: "1 hour",
      label: "1 hour",
      value:
        TIMES["1 hour"] *
        minutesOfOneHour *
        secondsOfOneMinute *
        millisecondsOfOneSecond,
    },
    {
      title: "3 hours",
      label: "3 hours",
      value:
        TIMES["3 hours"] *
        minutesOfOneHour *
        secondsOfOneMinute *
        millisecondsOfOneSecond,
    },
    {
      title: "6 hours",
      label: "6 hours",
      value:
        TIMES["6 hours"] *
        minutesOfOneHour *
        secondsOfOneMinute *
        millisecondsOfOneSecond,
    },
    {
      title: "24 hours",
      label: "24 hours",
      value:
        TIMES["24 hours"] *
        minutesOfOneHour *
        secondsOfOneMinute *
        millisecondsOfOneSecond,
    },
    {
      title: "2 days",
      label: "2 days",
      value:
        TIMES["2 days"] *
        hoursOfOneDay *
        minutesOfOneHour *
        secondsOfOneMinute *
        millisecondsOfOneSecond,
    },
    {
      title: "7 days",
      label: "7 days",
      value:
        TIMES["7 days"] *
        hoursOfOneDay *
        minutesOfOneHour *
        secondsOfOneMinute *
        millisecondsOfOneSecond,
    },
    {
      title: "30 days",
      label: "30 days",
      value:
        TIMES["30 days"] *
        hoursOfOneDay *
        minutesOfOneHour *
        secondsOfOneMinute *
        millisecondsOfOneSecond,
    },
    { title: "no freeze back", label: "No freeze", value: "noFreeze" },
  ];
  const [simpleLockTime, setSimpleLockTime] = useState();
  const [meltTime, setMeltTime] = useState();
  const [refreezeTime, setRefreezeTime] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [location, setLocation] = useState();
  const [waiting, setWaiting] = useState(false);
  const [typeOfAlert, setTypeOfAlert] = useState("");
  const [viewFaqs, setViewFaqs] = useState(false)

  const dispatch = useDispatch();

  const { selectedId, timers, userInfo, selectedCategory } = useSelector((state) => state.users);
  const userSettingInfo = useSelector(
    (state) => state.users.userList[selectedId]
  );

  useEffect(() => {
    getUpdatedStatus();
  }, [loader]);

  const changeTypeOfLock = (val) =>
    dispatch({
      type: "SET_LOCK_TYPE",
      payload: val,
    });

  useEffect(() => {
    changeTypeOfLock(userSettingInfo?.typeOfLock || "");
    // getTimerCount(userSettingInfo, timers, dispatch);
  }, [userSettingInfo]);

  useEffect(() => {
    waiting && setWaiting(false);
  }, [userSettingInfo?.typeOfLock]);

  // It is function for getting the status of action which is currently executed by user.
  const getUpdatedStatus = () => {
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions`;
    db.collection(databaseUrl)
      .where("status", "==", TYPE_OF_STATUS.PENDING)
      .where("name", "in", [
        TYPE_OF_ACTION.NULL_TO_SIMPLE_LOCK,
        TYPE_OF_ACTION.NULL_TO_FROZEN_LOCK,
        TYPE_OF_ACTION.FROZEN_TO_MELTING_LOCK,
        TYPE_OF_ACTION.MELTING_TO_FROZEN_LOCK,
        TYPE_OF_ACTION.MELTED_TO_NULL_LOCK,
        TYPE_OF_ACTION.GPS_LOCK,
      ])
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length) {
          setLoader(true);
          getUpdatedStatus();
        } else {
          setLoader(false);
          isSubmit &&
            db
              .collection(
                `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions`
              )
              .where("name", "in", [
                TYPE_OF_ACTION.NULL_TO_SIMPLE_LOCK,
                TYPE_OF_ACTION.NULL_TO_FROZEN_LOCK,
                TYPE_OF_ACTION.FROZEN_TO_MELTING_LOCK,
                TYPE_OF_ACTION.MELTING_TO_FROZEN_LOCK,
                TYPE_OF_ACTION.MELTED_TO_NULL_LOCK,
                TYPE_OF_ACTION.GPS_LOCK,
              ])
              .get()
              .then((snapshot) => {
                if (snapshot && snapshot.docs.length) {
                  const { status, reason, name } = snapshot.docs
                    .map((doc) => doc.data())
                    .pop();
                  if (status === TYPE_OF_STATUS.DENIED) {
                    waiting && setWaiting(false);
                    let msg = handleDeniedActionReasons(reason, t);
                    setAlert(msg, "error");
                  } else {
                    if (
                      [
                        TYPE_OF_ACTION.MELTING_TO_FROZEN_LOCK,
                        TYPE_OF_ACTION.MELTED_TO_NULL_LOCK,
                      ].includes(name)
                    ) {
                      timers.map((id) => clearInterval(id));
                      timers.length = 0;
                      dispatch({
                        type: "SET_LOCK_TYPE",
                        payload: "",
                      });
                      dispatch({
                        type: "SET_TIMERS",
                        payload: [],
                      });
                    }
                    setSimpleLockTime();
                    setMeltTime();
                    setRefreezeTime();
                    setLocation();
                    setAlert(t("requestApproved_i18n"), "success");
                  }
                }
              });
          setIsSubmit(false);
        }
      });
  };

  const setAlert = async (message, type) => {
    await setTypeOfAlert(type);
    await setMessage(message);
  };

  //These are the functions for updating the state of lock times or data.
  const handleSimpleLockTime = (value) => {
    if (value === simpleLockTime) {
      setSimpleLockTime();
    } else {
      setSimpleLockTime(value);
    }
  };
  const handleMeltTime = (value) => {
    if (value === meltTime) {
      setMeltTime();
    } else {
      setMeltTime(value);
    }
  };
  const handleRefreezeTime = (value) => {
    if (value === refreezeTime) {
      setRefreezeTime();
    } else {
      setRefreezeTime(value);
    }
  };
  const handleLocation = (loc) => {
    setLocation(loc);
  };

  const selfLockFaqsList = [
    {
      id: 1,
      question: t("frozen_ice_lock_q1_faq___i18n"),
      answer: t("frozen_ice_lock_a1_faq___i18n")
    },
    {
      id: 2,
      question: t("frozen_ice_lock_q2_faq___i18n"),
      answer: t("frozen_ice_lock_a2_faq___i18n")
    },
    {
      id: 3,
      question: t("frozen_ice_lock_q3_faq___i18n"),
      answer: t("frozen_ice_lock_a3_faq___i18n")
    },
    {
      id: 4,
      question: t("frozen_ice_lock_q4_faq___i18n"),
      answer: t("frozen_ice_lock_a4_faq___i18n")
    },
    {
      id: 5,
      question: t("frozen_ice_lock_q5_faq___i18n"),
      answer: t("frozen_ice_lock_a5_faq___i18n")
    },
    {
      id: 6,
      question: t("frozen_ice_lock_q6_faq___i18n"),
      answer: t("frozen_ice_lock_a6_faq___i18n")
    },
  ]

  const faqs = () => {
    return (
      <div className="accordion accordion-flush" id="faqAccordian">
        {
          selfLockFaqsList.map((el) => (
            <div className="accordion-item">
              <h2 className="accordion-header androied-header" id={"flush-headingfaq" + el.id}>
                <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapsefaq" + el.id} aria-expanded="false" aria-controls={"flush-collapsefaq" + el.id}>
                  <p>{el.question}<br /><span>{el.answer.slice(0, 40) + "..."}</span></p>
                </div>
              </h2>
              <div id={"flush-collapsefaq" + el.id} className="accordion-collapse collapse" aria-labelledby={"flush-headingfaq" + el.id} data-bs-parent="#faqAccordian">
                <div className="accordion-body">
                  <p>{el.answer}</p>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    )
  }

  const backFAQsAccordian = () => {
    return (
      <div className="accordion accordion-flush" id="backFAQsAccordian">
        <div className="accordion-item pb-0">
          <h2 className="accordion-header androied-header" id="flush-headingOne-back-faq">
            <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne-back-faq" aria-expanded="false" aria-controls="flush-collapseOne">
              <p>{t("backToFaq___i18n")}<br /><span>{t("readItAnytimeYouWant___i18n")}</span></p>
            </div>
          </h2>
          <div id="flush-collapseOne-back-faq" className="accordion-collapse collapse" aria-labelledby="flush-headingOne-back-faq" data-bs-parent="#backFAQsAccordian">
            <div className="accordion-body p-0">
              {faqs()}
            </div>
          </div>
        </div>
      </div>
    )
  }
  const date = new Date().getTime();

  //It is function for pass the actions when user set any type of lock.
  const handleSave = (type, value) => {
    setIsSubmit(true);
    setLoader(true);
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION
      }/${userInfo?.email.toString()}/actions/${date} - ${type}`;
    const payload = {
      userId: userInfo?.email,
      actionId: `${date}-${type}`,
      actionType:
        type === TYPE_OF_ACTION.GPS_LOCK
          ? `${type}:${userInfo?.email}`
          : `${type}:${value}`,
      status: TYPE_OF_STATUS.PENDING,
      createdTime: new Date(),
      name: `${type}`,
      gpsLocation: { latitude: location?.lat, longitude: location?.lng } || {},
    };
    if (type !== TYPE_OF_ACTION.GPS_LOCK) {
      delete payload.gpsLocation;
    }
    db.doc(databaseUrl).set(payload);
    setWaiting(true);
  };

  // It is function for user's request to stop or release the timer or lock.
  const handleRequest = (type) => {
    setIsSubmit(true);
    setLoader(true);
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION
      }/${userInfo?.email.toString()}/actions/${date} - ${type}`;
    db.doc(databaseUrl).set({
      userId: userInfo?.email,
      actionId: `${date}-${type}`,
      actionType: `${type}:${userInfo?.email}`,
      status: TYPE_OF_STATUS.PENDING,
      createdTime: new Date(),
      name: `${type}`,
    });
  };

  //This function is called when user is not in any type of lock and can set the self-lock.
  const showNormal = () => {
    return (
      <div className="self-lock-wrapper accordion-menu">
        <div className="accordion-menu-header">
          <h3>Self Lock</h3>
        </div>
        <div className="accordion accordion-flush" id="selfLockAccordian">
          <div className="accordion-item">
            <h2 className="accordion-header androied-header" id="flush-headingOne">
              <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                {selfLockPageIcons['simple-lock']}
                <p>{t("simpleLockHeader_i18n")}<br /><span>{t("lockYourSelfTimer___i18n")}</span></p>
              </div>
            </h2>
            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#selfLockAccordian">
              <div className="accordion-body">

                <div className="self-lock">
                  <h5 className="mb-3 accordion-sub-text">{t("simpleLockPrevent___i18n")}</h5>
                  {lockTimeLabel
                    .filter((el) => el.value !== "noFreeze")
                    .map((el, i) => {
                      return (
                        <div
                          className="form-check"
                          onClick={() => handleSimpleLockTime(el.value)}
                        >
                          <input className="form-check-input" type="radio" name="selflock" id={"simple" + i}
                            checked={simpleLockTime === el.value ? true : false}
                          />
                          <label className="form-check-label d-flex" for={i}>
                            {el.title}
                          </label>
                        </div>
                      );
                    })}

                  <input
                    type="button"
                    value="Start self lock"
                    disabled={simpleLockTime ? false : true}
                    onClick={() =>
                      handleSave(TYPE_OF_ACTION.NULL_TO_SIMPLE_LOCK, simpleLockTime)
                    }
                    className={`btn ${!simpleLockTime ? "disabled-btn" : "active-btn"
                      }`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header androied-header" id="flush-headingTwo">
              <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseOne">
                {selfLockPageIcons['freeze-lock']}
                <p>{t("frozenLockHeader_i18n")}<br /><span>{t("sameAsSimpleLock___i18n")}</span></p>
              </div>
            </h2>
            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#selfLockAccordian">
              <div className="accordion-body">
                <div className="title-text-box">
                  <h5 className="accordion-sub-text">{t("beforeYouStartPleaseReadThisQuestion___i18n")}</h5>
                </div>
                {
                  !viewFaqs &&
                  <div>
                    {faqs()}
                    <div className="accordian-btn-box">
                      <button className="btn btn-primary" onClick={() => setViewFaqs(true)}>Ok, got it</button>
                    </div>
                  </div>
                }
                {viewFaqs && backFAQsAccordian()}
                {viewFaqs &&
                  <>
                    <div className="self-lock">
                      <h5 className="accordion-sub-text">{t("meltTitle_i18n")}</h5>
                      {lockTimeLabel
                        .filter((el) => el.value !== "noFreeze")
                        .map((el, i) => {
                          return (
                            <div
                              className="form-check"
                              onClick={() => handleMeltTime(el.label)}
                            >
                              <input className="form-check-input" type="radio" name="melt" id={"melt" + i}
                                checked={meltTime === el.label ? true : false}
                              />
                              <label className="form-check-label d-flex" for={i}>
                                {el.title}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                    <div className="self-lock">
                      <h5 className="accordion-sub-text">{t("freezeTitle_i18n")}</h5>
                      {lockTimeLabel.map((el, i) => {
                        return (
                          <div
                            className="form-check"
                            onClick={() => handleRefreezeTime(el.label)}
                          >
                            <input className="form-check-input" type="radio" name="freeze" id={"freeze" + i}
                              checked={refreezeTime === el.label ? true : false}
                            />
                            <label className="form-check-label d-flex" for={i}>
                              {el.title}
                            </label>
                          </div>
                        );
                      })}
                      <input
                        type="button"
                        value="Start self lock"
                        disabled={meltTime && refreezeTime ? false : true}
                        onClick={() =>
                          handleSave(
                            TYPE_OF_ACTION.NULL_TO_FROZEN_LOCK,
                            refreezeTime !== "No freeze"
                              ? `${meltTime}_${refreezeTime}`
                              : meltTime
                          )
                        }
                        className={`btn ${(!meltTime || !refreezeTime) ? "disabled-btn" : "active-btn"
                          }`}
                      />
                    </div>
                  </>
                }

              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header androied-header" id="flush-headingThree">
              <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseOne">
                {selfLockPageIcons['gps-lock']}
                <p>{t("gpsLockHeader_i18n")}<br /><span>Under construction</span></p>
              </div>
            </h2>
            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#selfLockAccordian">
              <div className="accordion-body">
                {/* <div className="self-lock">
                  <h3 className="mb-3">{t("gpsLockHeader_i18n")}</h3>
                  <div className="gps-lock mb-4">
                    <Map
                      defaultLocation={{}}
                      handleLocation={handleLocation}
                      location={location}
                      flag={true}
                    />
                  </div>
                  <input
                    type="button"
                    value={t("save_i18n")}
                    disabled={location ? false : true}
                    onClick={() => handleSave(TYPE_OF_ACTION.GPS_LOCK, location)}
                    className={`btn btn-primary ${!location && "disabled-cursor"}`}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTime = (remainingTime) => {
    const days =
      Math.floor(remainingTime / 86400).toString().length === 1
        ? `0${Math.floor(remainingTime / 86400)}`
        : Math.floor(remainingTime / 86400);
    const hours =
      (Math.floor(remainingTime / 3600) % 24).toString().length === 1
        ? `0${Math.floor(remainingTime / 3600) % 24}`
        : Math.floor(remainingTime / 3600) % 24;
    const minutes =
      Math.floor((remainingTime % 3600) / 60).toString().length === 1
        ? `0${Math.floor((remainingTime % 3600) / 60)}`
        : Math.floor((remainingTime % 3600) / 60);
    const seconds =
      (remainingTime % 60).toString().length === 1
        ? `0${remainingTime % 60}`
        : remainingTime % 60;

    return (
      <div className="time-wrapper">
        <div className="time">
          <div className="time-col">
            {days} <span>{t("day_i18n")}</span>
          </div>
          <div className="time-col">
            {hours}
            <span>{t("hour_i18n")}</span>
          </div>
          <div className="time-col">
            {minutes}
            <span>{t("min_i18n")}</span>
          </div>
          <div className="time-col">
            {seconds}
            <span>{t("sec_i18n")}</span>
          </div>
        </div>
      </div>
    );
  };

  //This is timer function which is used to show timer according to duration passed in arguments. I used the react-countdown-circle-timer package for it.
  //When timer is finished it goes for checking typeofLock and according to change the screen.
  const timerFn = (duration) => {
    const timerProps = {
      isPlaying: true,
      size: 225,
      strokeWidth: 6,
    };
    return (
      <>
        <CountdownCircleTimer
          {...timerProps}
          rotation="counterclockwise"
          onComplete={() => {
            dispatch({
              type: "SET_LOCK_TYPE",
              payload: null,
            });
            setWaiting(true);
          }}
          colors={[["#1640aa"]]}
          duration={duration}
          initialRemainingTime={duration}
          children={({ remainingTime }) => renderTime(remainingTime)}
        ></CountdownCircleTimer>
      </>
    );
  };

  const showSimpleTimer = () => {
    if (userSettingInfo?.simpleToNullTargetDate) {
      const originalDate = fromUnixTime(userSettingInfo?.simpleToNullTargetDate.seconds);
      const duration = differenceInSeconds(originalDate, new Date());
      return (
        <>
          <div className="self-lock-wrap">
            <h2>{t("simpleLockScreenTitle_i18n")}</h2>
            <div className="timer">
              {duration > 0 && timerFn(duration)}
            </div>
          </div>
        </>
      );
    }
  };

  //This screen is render when user is in refreezing timer, during the timer period user can unlock by clicking on remove button.
  const showMeltTimer = () => {
    if (userSettingInfo?.meltedToFrozenTargetDate) {
      const duration = differenceInSeconds(fromUnixTime(userSettingInfo?.meltedToFrozenTargetDate?.seconds), new Date());
      return (
        <div className="self-lock-wrap">
          <h2>{t("inActiveIceLockScreenTitle_i18n")}</h2>
          <p>{`${userSettingInfo?.frozenToMeltedTimeSpan
            ? userSettingInfo.frozenToMeltedTimeSpan
            : "Not added"
            } to melt the lock.`}</p>
          <p>{`${userSettingInfo?.meltedToFrozenTimeSpan
            ? userSettingInfo.meltedToFrozenTimeSpan
            : "Not added"
            } to freeze the lock again.`}</p>
          <hr className="mt-4 d-block" />
          <div className="timer mb-3">
            {duration > 0 && timerFn(duration)}
          </div>
          <input
            type="button"
            value="delete self lock"
            onClick={() =>
              handleRequest(TYPE_OF_ACTION.MELTED_TO_NULL_LOCK)
            }
            className="btn btn-red btn-lg px-5"
          />
        </div>
      );
    }
  };

  //This screen is render when user is in frozen ice timer, user can stop timer and go to startFreeze screen by clicking on stop button.
  const showFreezeTimer = () => {
    if (userSettingInfo?.frozenToMeltedTargetDate) {
      const duration = differenceInSeconds(fromUnixTime(userSettingInfo?.frozenToMeltedTargetDate?.seconds), new Date());
      return (
        <div className="self-lock-wrap">
          <h2>{t("frozenInIceLockScreenTitle_i18n")}</h2>
          <p>{`${userSettingInfo?.frozenToMeltedTimeSpan
            ? userSettingInfo.frozenToMeltedTimeSpan
            : t("notAdded___i18n")
            } ${t("toMeltTheLock___i18n")}`}</p>
          <p>{`${userSettingInfo?.meltedToFrozenTimeSpan
            ? userSettingInfo.meltedToFrozenTimeSpan
            : t("notAdded___i18n")
            } ${t("toFreezeTheLockAgain___i18n")}`}</p>
          <hr className="mt-4 d-block" />
          <div className="timer mb-3">
            {duration > 0 && timerFn(duration)}
          </div>
          <input
            type="button"
            value="stop"
            onClick={() => handleRequest(TYPE_OF_ACTION.MELTING_TO_FROZEN_LOCK)}
            className="btn btn-red btn-lg px-5"
          />

        </div>
      );
    }
  };

  //It shows user is in gps lock and unlock location on map.
  const showGpsLockScreen = () => {
    return (
      <>
        <div>{t("gpsLockScreenTitle_i18n")}</div>
        <div>{t("gpsUnlockInstruction_i18n")}</div>
        <div>
          <Map
            defaultLocation={{
              lat: userSettingInfo?.gpsLocation?.latitude,
              lng: userSettingInfo?.gpsLocation?.longitude,
            }}
            handleLocation={handleLocation}
            location={location}
            flag={false}
          />
        </div>
      </>
    );
  };

  //It shows the start button for start the frozen ice timer and melt timer.
  const showStartFreezeScreen = () => {
    return (
      <>
        <div className="self-lock-wrap">
          <h2>{t("frozenInIceLockScreenTitle_i18n")}</h2>
          <p>{`${userSettingInfo?.frozenToMeltedTimeSpan
            ? userSettingInfo.frozenToMeltedTimeSpan
            : "Not added"
            } to melt the lock.`}</p>
          <p>{`${userSettingInfo?.meltedToFrozenTimeSpan
            ? userSettingInfo.meltedToFrozenTimeSpan
            : "Not added"
            } to freeze the lock again.`}</p>
          <hr className="mt-4 d-block" />
          <input
            type="button"
            value="Melt ice"
            onClick={() => handleRequest(TYPE_OF_ACTION.FROZEN_TO_MELTING_LOCK)}
            className="btn btn-primary btn-lg px-5"
          />
        </div>
        <div className="frozen-timer">
          {backFAQsAccordian()}
        </div>
      </>
    );
  };

  //Shows the screen according to the typeofLock.
  const handleShowDetails = () => {
    switch (userSettingInfo?.typeOfLock) {
      case TYPE_OF_LOCK.SIMPLE_LOCK:
        return showSimpleTimer();
      case TYPE_OF_LOCK.MELTING:
        return showFreezeTimer();
      case TYPE_OF_LOCK.MELTED:
        return showMeltTimer();
      case TYPE_OF_LOCK.GPS:
        return showGpsLockScreen();
      case TYPE_OF_LOCK.FROZEN:
        return showStartFreezeScreen();
      default:
        return showNormal();
    }
  };

  //It is for automatically remove alert after 5 seconds.
  message &&
    setTimeout(() => {
      setMessage("");
      setTypeOfAlert("");
    }, 3000);

  //It is function for the close the alert notification.
  const handleCloseAlert = () => {
    setMessage("");
    setTypeOfAlert("");
  };

  const showLoader = () => {
    return (
      <div className="waiting-wrap">
        <img src={waitingIcon} alt="wait" />
        <span>{t("loading_i18n")}</span>
      </div>
    );
  };

  //It shows the alert notification and screen according to handleShowDetails function.
  return {
    message,
    typeOfAlert,
    handleCloseAlert,
    waiting,
    showLoader,
    handleShowDetails,
    selectedCategory
  };
}
