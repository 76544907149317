import React, { useEffect, useState } from "react";
import { arrowDown, arrowDownLight } from "component/Containers/NewLandingPage/imports";
import Downloads from "./Downloads.jsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LoginBtn from "./ui/LoginBtn.jsx";
import LanguageSwitcher from "./ui/LanguageSwitcher.jsx";

const Navbar = ({ close }) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", handleResize);
    window.addEventListener("resize", handleResize);
  });

  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const toggleDownloads = (e) => {
    if ((e.target.target = "_blank") && !isOpen) setOpen(true);
    else if (isOpen) setOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && !event.target.closest('.downloads')) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <nav className="nav">
      <ul className="nav__list">
        <li className="nav__item nav__item--download">
          <span
            className="nav__link nav__link--download"
            onClick={toggleDownloads}
          >
            {t("Download_i18n")}
            { isMobile ? <img
                className={isOpen ? "arrow-down active" : "arrow-down"}
                src={arrowDown}
                alt=""
              /> : <img
              className={isOpen ? "arrow-down active" : "arrow-down"}
              src={arrowDownLight}
              alt=""
            /> 
            }
            {isOpen ? <Downloads /> : ""}
          </span>
        </li>
        <li className="nav__item">
          <Link onClick={close} to="/premium" className="nav__link">
            {t("Premium_i18n")}
          </Link>
        </li>
        <li className="nav__item">
          <Link onClick={close} to="/faq" className="nav__link">
            {t("FAQ_i18n")}
          </Link>
        </li>
        <li className="nav__item">
          <Link onClick={close} to="/support" className="nav__link">
            {t("Support_i18n")}
          </Link>
        </li>
      </ul>

      <div className="actions">
      <LoginBtn />
      <LanguageSwitcher />
    </div>
    </nav>
  );
};

export default Navbar;
