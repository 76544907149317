import React from "react";
import { Alert, Spin } from "antd";
import { useTranslation } from "react-i18next";
import addAiParent from "component/Containers/User/AddAiParent";
// import { Form } from "reactstrap";
// import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


export default function AddAiParent() {
    const { t } = useTranslation();
    // const { t } = useTranslation();
    const { message, typeOfAlert, handleCloseAlert, onSubmit, loader, AiParents, onChange, prompt, handleKeyDown } =
        addAiParent();


    const AIParentFaqsList = [
        {
            id: 1,
            question: t("AI_q1_faq___i18n"),
            answer: t("AI_a1_faq___i18n")
        },
        {
            id: 2,
            question: t("AI_q2_faq___i18n"),
            answer: t("AI_a2_faq___i18n")
        },
        {
            id: 3,
            question: t("AI_q3_faq___i18n"),
            answer: t("AI_a3_faq___i18n")
        },
        {
            id: 4,
            question: t("AI_q4_faq___i18n"),
            answer: t("AI_a4_faq___i18n")
        },
        {
            id: 5,
            question: t("AI_q5_faq___i18n"),
            answer: t("AI_a5_faq___i18n")
        },

    ]

    const faqs = () => {
        return (
            <div className="accordion accordion-flush" id="faqAccordian">
                {
                    AIParentFaqsList.map((el) => (
                        <div className="accordion-item">
                            <h2 className="accordion-header androied-header" id={"flush-headingfaq" + el.id}>
                                <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapsefaq" + el.id} aria-expanded="false" aria-controls={"flush-collapsefaq" + el.id}>
                                    <p>{el.question}<br /><span>{el.answer.slice(0, 40) + "..."}</span></p>
                                </div>
                            </h2>
                            <div id={"flush-collapsefaq" + el.id} className="accordion-collapse collapse" aria-labelledby={"flush-headingfaq" + el.id} data-bs-parent="#faqAccordian">
                                <div className="accordion-body">
                                    <p>{el.answer}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    const backFAQsAccordian = () => {
        return (
            <div className="accordion accordion-flush" id="backFAQsAccordian">
                <div className="accordion-item pb-0">
                    <h2 className="accordion-header androied-header" id="flush-headingOne-back-faq">
                        <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne-back-faq" aria-expanded="false" aria-controls="flush-collapseOne">
                            <p>{t("backToFaq___i18n")}<br /><span>{t("readItAnytimeYouWant___i18n")}</span></p>
                        </div>
                    </h2>
                    <div id="flush-collapseOne-back-faq" className="accordion-collapse collapse" aria-labelledby="flush-headingOne-back-faq" data-bs-parent="#backFAQsAccordian">
                        <div className="accordion-body p-0">
                            {faqs()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="ai-parent-wrp sidebar-right-side mt-5 overflow-hidden">
                {message && (
                    <div>
                        <Alert
                            message={message}
                            type={typeOfAlert}
                            closable
                            showIcon
                            onClose={handleCloseAlert}
                        />
                    </div>
                )}
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ai-parent-sugetions">
                                <p>Here you can invite an AI to become your parent.</p>
                                <p>This AI will approve/deny new websites.</p>
                                <p>What kind of an AI parent would you like to get ?</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center my-4">
                        {/* {Object.keys(AiParents).map((key) => (
            <div className="col-md-6" key={key}>
                <div className="ai-parent-box h-100">
                <div className="form-check h-100">
                    <input
                    className="form-check-input"
                    type="radio"
                    name="aiParent"
                    id={key}
                    value={AiParents[key]}
                    onChange={onChange}
                    />
                    <label className="form-check-label h-100" htmlFor={key} role="button">
                    {AiParents[key]}
                    </label>
                </div>
                </div>
            </div>
            ))} */}
                        <div className="textInputContainer">

                            <textarea
                                value={prompt?.trim() ? prompt : ""}
                                onChange={onChange}
                                onKeyDown={handleKeyDown}
                                placeholder="You will not approve websites that have user-generated content.
            You will not approve websites that are offering continuous and varied content, often designed to be addictive or highly engaging.
            You will not approve websites that are meant for the age 18+ only.&#13;

            You will approve anything else."
                            />
                            <div className="col-sm-12">
                                <button className={prompt?.trim() ? 'btn btn-primary' : 'btn btn-disable'} onClick={onSubmit}>Create AI Parent</button>

                                {loader && <Spin
                                    indicator={<LoadingOutlined />}
                                    className="mx-2"
                                ></Spin>}

                            </div>
                        </div>

                        {/* <div class="col-sm-12 text-center mt-5">
                <button className={ prompt ? 'btn btn-primary': 'btn btn-disable'} onClick={onSubmit}>Invite Parent </button>
            
           {loader && <Spin
              indicator={<LoadingOutlined />}
              className="mx-2"
            ></Spin>}
                
            </div> */}
                    </div>
                    {backFAQsAccordian()}
                </div>
            </div>
        </>
    );
}
