// This is a map component that I use to show maps on self-lock functionality.
// I get the location(longitude, latitude) from clicking on the map and send it to the self-lock component.
// When a user is in GPS lock, I get the default location from the userSettingInfo and I show the marker of unlock location of user on the map according to the latitude and longitude.

import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

export function MapContainer({ google, defaultLocation, handleLocation, location, flag }) {
  const addMarker = (loc, map) => {
    handleLocation({ lat: loc.lat(), lng: loc.lng() });
    map.panTo(loc);
  };
  return (
    <Map
      google={google}
      containerStyle={{
        // position: "static",
        width: "100%",
        height: "100%",
      }}
      style={{
        width: "70%",
        height: "70%",
      }}
      className="map-box"
      initialCenter={flag ? location : defaultLocation}
      zoom={flag ? 18 : 18}
      disableDefaultUI={true}
      onClick={flag ? (t, map, c) => addMarker(c.latLng, map) : null}
    >
      <Marker position={flag ? location : defaultLocation} />
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDn7SHonkdtndVfaxieG3RFaBNS8VRCYBg",
})(MapContainer);
