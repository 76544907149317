import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Image } from "antd";
import sidebar from "component/Containers/User/Sidebar";
import { componentList, sidebarIcon, } from "description/constant";
import { handleSidebarTitle } from "shared/CommanFunction";

export default function Sidebar({ addClassFlag, toggle, t }) {
  const { pathname, profile, selectedCategory, push } = sidebar();
  const paramsData = pathname.split('/')
  const role = paramsData[2]
  const id = paramsData[3]
  return (
    <div>
      <div
        className={`b-sidebar-backdrop ${addClassFlag && "show"}`}
        onClick={toggle}
      ></div>
      <div
        className={`SideBar d-flex flex-column flex-shrink-0 text-black bg-light 
            ${addClassFlag && "show"}`}
      >
        <ul
          className="nav nav-pills sidebar_menu flex-column mb-auto p-0"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item profile-button" role="presentation">
            <div
              className={`nav-link sidebar_title bg-transparent ${pathname.replace("/", "") === "home" && "active"
                }`}
              id="home-lists-tab"
              data-bs-toggle="pill"
              data-bs-target="#home-lists"
              type="button"
              role="tab"
              aria-controls="home-lists"
              aria-selected={
                pathname.replace("/", "") === "home" ? true : false
              }
            >
              {profile && profile?.picture ? (
                <Avatar
                  style={{
                    backgroundColor: "#01579b",
                    verticalAlign: "middle",
                  }}
                  src={<Image src={profile?.picture} />}
                >
                  {" "}
                </Avatar>
              ) : (
                <Avatar
                  style={{
                    backgroundColor: "#01579b",
                    verticalAlign: "middle",
                  }}
                >
                  {(profile?.firstName &&
                    profile?.firstName.trim().charAt(0)) || <UserOutlined />}
                </Avatar>
              )}
              <div>
                <p className="profile-name">
                  {(profile?.firstName && profile?.firstName) || t("none_i18n")}{" "}
                </p>
                <p className="profile-id">{profile?.email || ""}
                </p>
              </div>
            </div>
          </li>
          {componentList[selectedCategory].map((el, i) => {
            return (
              <li
                key={i}
                className={
                  el === "approval-lists" ? "nav-item mt-3" : "nav-item"
                }
                role="presentation"
                onClick={() => {
                  toggle();
                  // return push(`/${el}`);
                  return push(`/u/${role}/${id}/${el}`);
                }}
              >
                <button
                  className={`nav-link ${pathname.replace("/", "") === el && "active"
                    }`}
                  id={`${el}-tab`}
                  data-bs-toggle="pill"
                  data-bs-target={`#${el}`}
                  type="button"
                  role="tab"
                  aria-controls={el}
                  aria-selected={
                    pathname.replace("/", "") === el ? true : false
                  }
                >
                  {" "}
                  {sidebarIcon[el]}
                  <h6>{handleSidebarTitle(el, t)}</h6>
                </button>
              </li>
            );
          })}

        </ul>
        <div className="version-info">
          <span>Version 25.8.23</span>
        </div>
      </div>
    </div>
  );
}
