import React from "react";
import {
  iosIcon,
  androidIcon,
} from "component/Containers/NewLandingPage/imports";
import { useTranslation } from "react-i18next";

const Downloads = () => {
  const { t } = useTranslation();

  return (
    <div className="downloads">
      <ul className="donwloads__list">
        <li className="donwloads__item">
          <img src={iosIcon} alt="" className="downloads__item-icon" />
          <span className="downloads__item-link">
            <a
              href="https://apps.apple.com/mn/app/teleteens/id1516268121"
              target="_blank"
              rel="noreferrer"
            >
              iOS App
            </a>
          </span>
        </li>
        <li className="donwloads__item">
          <img src={androidIcon} alt="" className="downloads__item-icon" />
          <span className="downloads__item-link">
            <a
              href="https://firebasestorage.googleapis.com/v0/b/webpage-teleteens.appspot.com/o/Teleteens%20X%208.6.3%20v2.apk?alt=media&token=f8f166f4-4691-418e-ac01-f8080309bd25"
              target="_blank"
              rel="noreferrer"
            >
              Android App
            </a>
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Downloads;
