import React from "react";
import { TYPE_OF_STATUS, USER_TYPE, settingPageIcons } from "description/constant.js";
import { Spin, Alert, Tooltip } from "antd";
// import { Button } from "reactstrap";
import { LoadingOutlined } from "@ant-design/icons";
// import ManageKid from "../../Presentation/User/ManageKid.jsx";
import settings from "component/Containers/User/Settings";
import { useTranslation } from "react-i18next";
import manageKid from "component/Containers/User/ManageKid";
import Coundown from "./Coundown.jsx"

export default function Settings() {
    const { t } = useTranslation();
    const {
        userSettingInfo,
        handleDisabledCheckBox,
        selectedCategory,
        // openAlert,
        // handleShowChannelNames,
        deniedSettingRequest,
        // handleDeniedActionReasons,
        // handleCloseAlert,
        loader,
        handleCallActions,
        selectedId,
        userInfo,
        // pendingRequestList,
        blockAppleLogout,
        blockAndroidLogout,
        handleChangeSetting,
        urlDefaultPolicyApple,
        appFuturePolicyAndroid,
        appDefaultPolicyAndroid,
        urlDefaultPolicyAndroid,
        urlDefaultPolicyAndroidReason,
        urlDefaultPolicyAppleReason,
        blockAndroidLogoutReason,
        blockAppleLogoutReason,
        appDefaultPolicyAndroidReason,
        appFuturePolicyAndroidReason,
        deleteAccountReason,
        handleOkbutton,
        checkReason,
        // handleDefaultPolicyDisabled,
        handleDeleteActions
    } = settings();
    const { handleUpdateManageKid, manageKidFlag } = manageKid({
        handleCallActions,
        userInfo,
        userSettingInfo
    });

    const getReasonTimer = (reasonObj) => {
        let updatedData = reasonObj.filter(name => typeof name == 'object')[0]
        return updatedData ? updatedData['reasonTimer'] : null
    }

    return (
        <div className="sidebar-right-side side-bar-menu accordion-menu setting-section">
            {
                selectedCategory === USER_TYPE.CHILD_IN_PARENT
                    ? (
                        <div className="row mb-3">{t("notAllowed_i18n")}</div>
                    ) : (
                        <>
                            {/* {openAlert && (
            <div className="mb-3">
              <Alert
                message={
                  handleShowChannelNames(deniedSettingRequest?.name, t) ||
                  "Fail"
                }
                description={`${
                  deniedSettingRequest &&
                  deniedSettingRequest?.name &&
                  deniedSettingRequest?.reason
                    ? handleDeniedActionReasons(deniedSettingRequest?.reason, t)
                    : deniedSettingRequest?.status === TYPE_OF_STATUS.REQUESTED
                    ? "Your action is currently in requested status"
                    : t("somethingWentWrong_i18n")
                }.`}
                type={
                  deniedSettingRequest?.status === TYPE_OF_STATUS.REQUESTED
                    ? "info"
                    : "error"
                }
                closable
                showIcon
                onClose={handleCloseAlert}
              />
            </div>
          )} */}
                            <div>
                                <div className="accordion-menu-header">
                                    <h3>{t("generalSetting___i18n")}</h3>
                                </div>
                                <div
                                    className="accordion accordion-flush"
                                    id="generalSettingAccordian"
                                >
                                    {
                                        selectedCategory === USER_TYPE.PARENT_IN_CHILD
                                            ? (
                                                <>
                                                    <div className="accordion-item">
                                                        <h2
                                                            className="accordion-header androied-header"
                                                            id="removekid-heading"
                                                        >
                                                            <div
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#remove-kid-collapse"
                                                                aria-expanded="false"
                                                                aria-controls="remove-kid-collapse"
                                                            >
                                                                {settingPageIcons["removeKid"]}
                                                                <p>
                                                                    {" "}
                                                                    Remove parenthood
                                                                    <br />
                                                                    <span>
                                                                        Remove your supervision from the kid
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </h2>
                                                        <div
                                                            id="remove-kid-collapse"
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby="removekid-heading"
                                                            data-bs-parent="#generalSettingAccordian"
                                                        >
                                                            <div className="accordion-body">
                                                                <button className="btn active-btn" onClick={() => handleCallActions(selectedId, "removeKid")}>
                                                                    {t("removeKidButton_i18n")}
                                                                    {loader && <Spin indicator={<LoadingOutlined />}></Spin>}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2
                                                            className="accordion-header androied-header"
                                                            id="managekid-heading"
                                                        >
                                                            <div
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#managekid-collapse"
                                                                aria-expanded="false"
                                                                aria-controls="managekid-collapse"
                                                            >
                                                                {settingPageIcons["manageKid"]}
                                                                <p>
                                                                    {" "}
                                                                    Management kid policy
                                                                    <br />
                                                                    <span>
                                                                        choose if to control/not control your kid
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </h2>
                                                        <div
                                                            id="managekid-collapse"
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby="managekid-heading"
                                                            data-bs-parent="#generalSettingAccordian"
                                                        >
                                                            <div className="accordion-body">
                                                                <div className="form-check website-detail">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="notManageKid"
                                                                        id="notManageKid"
                                                                        onChange={() =>
                                                                            handleUpdateManageKid("manageKidAccess")
                                                                        }
                                                                        checked={!manageKidFlag}
                                                                    />
                                                                    <label
                                                                        className="form-check-label d-flex"
                                                                        htmlFor="notManageKid"
                                                                    >
                                                                        <p>
                                                                            Don't manage your kid<br />
                                                                            <span>He is still your kid but you can't manage him</span>{" "}
                                                                        </p>
                                                                        {/* <span className="loading-spinner">
                            {handleDisabledCheckBox("manageKidAccess","false") && manageKidFlag && <Spin indicator={<LoadingOutlined />}></Spin>}
                          </span> */}
                                                                    </label>
                                                                </div>
                                                                <div className="form-check website-detail">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="ManageKid"
                                                                        id="ManageKid"
                                                                        onChange={() =>
                                                                            handleUpdateManageKid("manageKidAccess")
                                                                        }
                                                                        checked={manageKidFlag}
                                                                    />
                                                                    <label
                                                                        className="form-check-label d-flex"
                                                                        htmlFor="ManageKid"
                                                                    >
                                                                        <p>
                                                                            Manage your kid<br />
                                                                            <span>Default option</span>{" "}
                                                                        </p>
                                                                        {/* <span className="loading-spinner">
                            {handleDisabledCheckBox("manageKidAccess","true") && !manageKidFlag && <Spin indicator={<LoadingOutlined />}></Spin>}
                          </span> */}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <Button
                  color="primary"
                  className="remove-btn"
                  disabled={loader}
                  onClick={() => handleCallActions(selectedId, "removeKid")}
                >
                  {" "}
                  {t("removeKidButton_i18n")}{" "}
                  {loader && <Spin indicator={<LoadingOutlined />}></Spin>}
                </Button> */}
                                                    {/* <hr />
                <ManageKid
                  handleCallActions={handleCallActions}
                  userId={userSettingInfo?.userId}
                  handleDisabledCheckBox={handleDisabledCheckBox}
                  userInfo={userInfo}
                  pendingRequestList={pendingRequestList}
                  userSettingInfo={userSettingInfo}
                /> */}
                                                </>
                                            )
                                            : (
                                                <div className={'accordion-item ' + checkReason(deleteAccountReason)}>
                                                    {
                                                        getReasonTimer(deleteAccountReason) && checkReason(deleteAccountReason) == 'reason-melting' && <Coundown reasonTimer={getReasonTimer(deleteAccountReason)} />}
                                                    {
                                                        (['reason-deniedSimpleLock', 'reason-deniedByHumanParent', 'reason-sentToHumanParent'].includes(checkReason(deleteAccountReason))) &&
                                                        <div className="ok-button"><button type="button" className="btn btn-light" onClick={() => handleOkbutton("deleteAccount", deleteAccountReason)}>Ok</button></div>
                                                    }
                                                    <h2
                                                        className="accordion-header androied-header"
                                                        id="flush-headingOne"
                                                    >
                                                        <div
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#general-collapseOne"
                                                            aria-expanded="false"
                                                            aria-controls="general-collapseOne"
                                                        >
                                                            {settingPageIcons["deleteAccount"]}
                                                            <p>
                                                                {" "}
                                                                {t("deleteYourAccount___i18n")}
                                                                <br />
                                                                <span>
                                                                    {t("actionCantUndone___i18n")}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </h2>
                                                    <div
                                                        id="general-collapseOne"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="flush-headingOne"
                                                        data-bs-parent="#generalSettingAccordian"
                                                    >
                                                        <div className="accordion-body">
                                                            <button className="btn active-btn" onClick={() =>
                                                                handleDeleteActions()
                                                            }>Delete
                                                                {/* {handleDisabledCheckBox("Delete account","deleteMyAccount") && <Spin indicator={<LoadingOutlined />}></Spin>} */}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                </div>
                            </div>
                            <div>
                                <div className="accordion-menu-header">
                                    <h3>{t("defaultAndroidPolicy_i18n")}</h3>
                                </div>
                                <div
                                    className="accordion accordion-flush"
                                    id="androidSettingAccordian"
                                >
                                    <div className={'accordion-item ' + checkReason(urlDefaultPolicyAndroidReason)} >
                                        {getReasonTimer(urlDefaultPolicyAndroidReason) && checkReason(urlDefaultPolicyAndroidReason) == 'reason-melting'
                                            && <Coundown reasonTimer={getReasonTimer(urlDefaultPolicyAndroidReason)} />}
                                        {
                                            (['reason-deniedSimpleLock', 'reason-deniedByHumanParent', 'reason-sentToHumanParent'].includes(checkReason(urlDefaultPolicyAndroidReason))) &&
                                            <div className="ok-button"><button type="button" className="btn btn-light" onClick={() => handleOkbutton("urlDefaultPolicyAndroid", urlDefaultPolicyAndroidReason)}>Ok</button></div>
                                        }
                                        <h2
                                            className="accordion-header androied-header"
                                            id="flush-headingOne"
                                        >
                                            <div
                                                type="button"
                                                className="accordion-button collapsed"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseOne"

                                            >
                                                {settingPageIcons["language"]}
                                                <p>
                                                    {" "}
                                                    {t("websitePolicy___i18n")}{console.log(checkReason(urlDefaultPolicyAndroidReason))}
                                                    <br />
                                                    <span>
                                                        {urlDefaultPolicyAndroid === "open"
                                                            ? "Open everything"
                                                            : "Block everything"}
                                                    </span>
                                                </p>
                                            </div>
                                        </h2>
                                        <div
                                            id="flush-collapseOne"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne"
                                            data-bs-parent="#androidSettingAccordian"
                                        >
                                            <div className="accordion-body">
                                                <div className="form-check website-detail">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexUrlAndroidDefault"
                                                        id="flexUrlAndroidDefaultOpen"
                                                        onChange={() =>
                                                            handleChangeSetting("urlDefaultPolicyAndroid", "open")
                                                        }
                                                        checked={
                                                            urlDefaultPolicyAndroid === "open" ? true : false
                                                        }
                                                    />
                                                    <label
                                                        className="form-check-label d-flex"
                                                        htmlFor="flexUrlAndroidDefaultOpen"
                                                    >
                                                        <p>
                                                            {t("openEveryThing___i18n")} <br />
                                                            <span>{t("notRecommended___i18n")}</span>{" "}
                                                        </p>
                                                        {/* <span className="loading-spinner">
                          {handleDisabledCheckBox(
                            "urlDefaultPolicyAndroid",
                            "open"
                          ) && <Spin indicator={<LoadingOutlined />}></Spin>}
                        </span> */}
                                                    </label>
                                                </div>
                                                <div className="form-check website-detail">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexUrlAndroidDefault"
                                                        onChange={() =>
                                                            handleChangeSetting(
                                                                "urlDefaultPolicyAndroid",
                                                                "blockALL"
                                                            )
                                                        }
                                                        checked={
                                                            urlDefaultPolicyAndroid === "blockALL" ? true : false
                                                        }
                                                        id="flexUrlAndroidDefaultBlockAll"
                                                    />
                                                    <label
                                                        className="form-check-label d-flex"
                                                        htmlFor="flexUrlAndroidDefaultBlockAll"
                                                    >
                                                        <p>
                                                            {t("blockEveryThing___i18n")} <br />
                                                            <span>{t("exceptWhiteList___i18n")}</span>{" "}
                                                        </p>
                                                        {/* <span className="loading-spinner">
                          {handleDisabledCheckBox(
                            "urlDefaultPolicyAndroid",
                            "blockALL"
                          ) && <Spin indicator={<LoadingOutlined />}></Spin>}
                        </span> */}
                                                    </label>
                                                    <Tooltip
                                                        placement="bottom"
                                                        title={
                                                            t("blockEveryThingTooltipMessage___i18n")
                                                        }
                                                    >
                                                        <span className="approval-list-icon">
                                                            {settingPageIcons["whitelist"]}
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'accordion-item ' + checkReason(appDefaultPolicyAndroidReason)}>
                                        {getReasonTimer(appDefaultPolicyAndroidReason) && checkReason(appDefaultPolicyAndroidReason) == 'reason-melting'
                                            && <Coundown reasonTimer={getReasonTimer(appDefaultPolicyAndroidReason)} />}
                                        {
                                            (['reason-deniedSimpleLock', 'reason-deniedByHumanParent', 'reason-sentToHumanParent'].includes(checkReason(appDefaultPolicyAndroidReason))) &&
                                            <div className="ok-button"><button type="button" className="btn btn-light" onClick={() => handleOkbutton("appDefaultPolicyAndroid", appDefaultPolicyAndroidReason)}>Ok</button></div>
                                        }
                                        <h2
                                            className="accordion-header androied-header"
                                            id="flush-headingTwo"
                                        >
                                            <div
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseTwo"
                                            >
                                                {settingPageIcons["android"]}
                                                <p>
                                                    {t("appPolicy___i18n")}
                                                    <br />
                                                    <span>
                                                        {appDefaultPolicyAndroid === "open"
                                                            ? "Open everything"
                                                            : "Block everything"}
                                                    </span>
                                                </p>
                                            </div>
                                        </h2>
                                        <div
                                            id="flush-collapseTwo"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingTwo"
                                            data-bs-parent="#androidSettingAccordian"
                                        >
                                            <div className="accordion-body">

                                                <div className="form-check website-detail">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexAppAndroidPolicy"
                                                        id="flexAppAndroidPolicyOpen"
                                                        onChange={() =>
                                                            handleChangeSetting("appDefaultPolicyAndroid", "open")
                                                        }
                                                        checked={
                                                            appDefaultPolicyAndroid === "open" ? true : false
                                                        }
                                                    />
                                                    <label
                                                        className="form-check-label d-flex"
                                                        htmlFor="flexAppAndroidPolicyOpen"
                                                    >
                                                        <p>
                                                            {t("openEveryThing___i18n")} <br />
                                                            <span>{t("exceptBlackList___i18n")}</span>{" "}
                                                        </p>
                                                        {/* <span className="loading-spinner">
                          {handleDisabledCheckBox(
                            "appDefaultPolicyAndroid",
                            "open"
                          ) && <Spin indicator={<LoadingOutlined />}></Spin>}
                        </span> */}
                                                    </label>
                                                </div>
                                                <div className="form-check website-detail">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexAppAndroidPolicy"
                                                        onChange={() =>
                                                            handleChangeSetting(
                                                                "appDefaultPolicyAndroid",
                                                                "blockALL"
                                                            )
                                                        }
                                                        checked={
                                                            appDefaultPolicyAndroid === "blockALL" ? true : false
                                                        }
                                                        id="flexAppAndroidPolicyBlockAll"
                                                    />
                                                    <label
                                                        className="form-check-label d-flex"
                                                        htmlFor="flexAppAndroidPolicyBlockAll"
                                                    >
                                                        <p>
                                                            {t("blockEveryThing___i18n")} <br />
                                                            <span>{t("underConstruction___i18n")}</span>{" "}
                                                        </p>
                                                        {/* <span className="loading-spinner">
                          {handleDisabledCheckBox(
                            "appDefaultPolicyAndroid",
                            "blockALL"
                          ) && <Spin indicator={<LoadingOutlined />}></Spin>}
                        </span> */}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'accordion-item ' + checkReason(appFuturePolicyAndroidReason)}>
                                        {getReasonTimer(appFuturePolicyAndroidReason) && checkReason(appFuturePolicyAndroidReason) == 'reason-melting'
                                            && <Coundown reasonTimer={getReasonTimer(appFuturePolicyAndroidReason)} />}
                                        {
                                            (['reason-deniedSimpleLock', 'reason-deniedByHumanParent', 'reason-sentToHumanParent'].includes(checkReason(appFuturePolicyAndroidReason))) &&
                                            <div className="ok-button"><button type="button" className="btn btn-light" onClick={() => handleOkbutton("appFuturePolicyAndroid", appFuturePolicyAndroidReason)}>Ok</button></div>
                                        }
                                        <h2
                                            className="accordion-header androied-header"
                                            id="flush-headingThree"
                                        >
                                            <div
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseThree"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseThree"
                                            >
                                                {settingPageIcons["android"]}
                                                <p>
                                                    {t("installAppPolicy___i18n")}
                                                    <br />
                                                    <span>
                                                        {appFuturePolicyAndroid === "open"
                                                            ? "Open everything"
                                                            : "Block everything"}
                                                    </span>
                                                </p>
                                            </div>
                                        </h2>
                                        <div
                                            id="flush-collapseThree"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingThree"
                                            data-bs-parent="#androidSettingAccordian"
                                        >
                                            <div className="accordion-body">
                                                <div className="form-check website-detail">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexFutureSwitchCheck"
                                                        id="flexFutureSwitchCheckOpen"
                                                        onChange={() =>
                                                            handleChangeSetting("appFuturePolicyAndroid", "open")
                                                        }
                                                        checked={
                                                            appFuturePolicyAndroid === "open" ? true : false
                                                        }
                                                    />
                                                    <label
                                                        className="form-check-label d-flex"
                                                        htmlFor="flexFutureSwitchCheckOpen"
                                                    >
                                                        <p>
                                                            {t("openEveryThing___i18n")} <br />
                                                            <span>{t("newInstallationAccepted___i18n")}</span>{" "}
                                                        </p>
                                                        {/* <span className="loading-spinner">
                          {handleDisabledCheckBox(
                            "appFuturePolicyAndroid",
                            "open"
                          ) && <Spin indicator={<LoadingOutlined />}></Spin>}
                        </span> */}
                                                    </label>
                                                </div>
                                                <div className="form-check website-detail">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexFutureSwitchCheck"
                                                        onChange={() =>
                                                            handleChangeSetting(
                                                                "appFuturePolicyAndroid",
                                                                "blockALL"
                                                            )
                                                        }
                                                        checked={
                                                            appFuturePolicyAndroid === "blockALL" ? true : false
                                                        }
                                                        id="flexFutureSwitchCheckBlockAll"
                                                    />
                                                    <label
                                                        className="form-check-label d-flex"
                                                        htmlFor="flexFutureSwitchCheckBlockAll"
                                                    >
                                                        <p>
                                                            {t("blockEveryThing___i18n")} <br />
                                                            <span>{t("newInstallationBlocked___i18n")}</span>{" "}
                                                        </p>
                                                        {/* <span className="loading-spinner">
                          {handleDisabledCheckBox(
                            "appFuturePolicyAndroid",
                            "blockALL"
                          ) && <Spin indicator={<LoadingOutlined />}></Spin>}
                        </span> */}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'accordion-item ' + checkReason(blockAndroidLogoutReason)}>
                                        {getReasonTimer(blockAndroidLogoutReason) && checkReason(blockAndroidLogoutReason) == 'reason-melting'
                                            && <Coundown reasonTimer={getReasonTimer(blockAndroidLogoutReason)} />}
                                        {
                                            (['reason-deniedSimpleLock', 'reason-deniedByHumanParent', 'reason-sentToHumanParent'].includes(checkReason(blockAndroidLogoutReason))) &&
                                            <div className="ok-button"><button type="button" className="btn btn-light" onClick={() => handleOkbutton("blockAndroidLogout", blockAndroidLogoutReason)}>Ok</button></div>
                                        }
                                        <h2 className="accordion-header androied-header" id="log-out">
                                            <div
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#flush-collapseFour"
                                                aria-expanded="false"
                                                aria-controls="flush-collapseFour"
                                            >
                                                {settingPageIcons["logout"]}
                                                <p>
                                                    {t("hideLogoutOption___i18n")}
                                                    <br />
                                                    <span>{blockAndroidLogout ? "Hidden" : "Show"}</span>
                                                </p>
                                            </div>
                                        </h2>
                                        <div
                                            id="flush-collapseFour"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="log-out"
                                            data-bs-parent="#androidSettingAccordian"
                                        >
                                            <div className="accordion-body">
                                                <div className="form-check website-detail">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexAndroidLogout"
                                                        id="flexAndroidLogoutShown"
                                                        onChange={() =>
                                                            handleChangeSetting("blockAndroidLogout", false)
                                                        }
                                                        checked={blockAndroidLogout ? false : true}
                                                    />
                                                    <label
                                                        className="form-check-label d-flex"
                                                        htmlFor="flexAndroidLogoutShown"
                                                    >
                                                        <p>
                                                            {t("show___i18n")} <br />
                                                            <span>{t("notRecommendedAllowBypass___i18n")}</span>{" "}
                                                        </p>
                                                        {/* <span className="loading-spinner">
                          {blockAndroidLogout &&
                            handleDisabledCheckBox(
                              "blockAndroidLogout",
                              "blockAndroidLogout"
                            ) && <Spin indicator={<LoadingOutlined />}></Spin>}
                        </span> */}
                                                    </label>
                                                </div>
                                                <div className="form-check website-detail">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexAndroidLogout"
                                                        onChange={() =>
                                                            handleChangeSetting("blockAndroidLogout", true)
                                                        }
                                                        checked={blockAndroidLogout ? true : false}
                                                        id="flexAndroidLogoutHidden"
                                                    />
                                                    <label
                                                        className="form-check-label d-flex"
                                                        htmlFor="flexAndroidLogoutHidden"
                                                    >
                                                        <p>
                                                            {t("hidden___i18n")} <br />
                                                            <span>
                                                                {t("notRecommendedBlockBypass___i18n")}
                                                            </span>{" "}
                                                        </p>
                                                        {/* <span className="loading-spinner">
                          {!blockAndroidLogout &&
                            handleDisabledCheckBox(
                              "blockAndroidLogout",
                              "blockAndroidLogout"
                            ) && <Spin indicator={<LoadingOutlined />}></Spin>}
                        </span> */}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="accordion-menu-header">
                                    <h3>{t("defaultPolicy_i18n")}</h3>
                                </div>
                                <div
                                    className="accordion accordion-flush"
                                    id="appleSettingAccordian"
                                >
                                    <div className={'accordion-item ' + checkReason(urlDefaultPolicyAppleReason)}>
                                        {getReasonTimer(urlDefaultPolicyAppleReason) && checkReason(urlDefaultPolicyAppleReason) == 'reason-melting'
                                            && <Coundown reasonTimer={getReasonTimer(urlDefaultPolicyAppleReason)} />}
                                        {
                                            (['reason-deniedSimpleLock', 'reason-deniedByHumanParent', 'reason-sentToHumanParent'].includes(checkReason(urlDefaultPolicyAppleReason))) &&
                                            <div className="ok-button"><button type="button" className="btn btn-light" onClick={() => handleOkbutton("urlDefaultPolicyApple", urlDefaultPolicyAppleReason)}>Ok</button></div>
                                        }
                                        <h2
                                            className="accordion-header androied-header"
                                            id="flush-headingOne"
                                        >
                                            <div
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#apple-collapseOne"
                                                aria-expanded="false"
                                                aria-controls="apple-collapseOne"
                                            >
                                                {settingPageIcons["language"]}
                                                <p>
                                                    {" "}
                                                    {t("websitePolicy___i18n")}
                                                    <br />
                                                    <span>
                                                        {urlDefaultPolicyApple === "open"
                                                            ? "Open everything"
                                                            : urlDefaultPolicyApple === "blockALL"
                                                                ? "Block everything"
                                                                : "Block image "}
                                                    </span>
                                                </p>
                                            </div>
                                        </h2>
                                        <div
                                            id="apple-collapseOne"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingOne"
                                            data-bs-parent="#appleSettingAccordian"
                                        >
                                            <div className="accordion-body">
                                                <div className="form-check website-detail">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexUrlApple"
                                                        id="flexUrlAppleOpen"
                                                        onChange={() =>
                                                            handleChangeSetting("urlDefaultPolicyApple", "open")
                                                        }
                                                        checked={
                                                            urlDefaultPolicyApple === "open" ? true : false
                                                        }
                                                    />
                                                    <label
                                                        className="form-check-label d-flex"
                                                        htmlFor="flexUrlAppleOpen"
                                                    >
                                                        <p>
                                                            {t("openEveryThing___i18n")} <br />
                                                            <span>{t("notRecommended___i18n")}</span>{" "}
                                                        </p>
                                                        {/* <span className="loading-spinner">
                          {handleDisabledCheckBox(
                            "urlDefaultPolicyApple",
                            "open"
                          ) && <Spin indicator={<LoadingOutlined />}></Spin>}
                        </span> */}
                                                    </label>
                                                </div>
                                                <div className="form-check website-detail">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexUrlApple"
                                                        id="flexUrlAppleBlockAll"
                                                        onChange={() =>
                                                            handleChangeSetting(
                                                                "urlDefaultPolicyApple",
                                                                "blockALL"
                                                            )
                                                        }
                                                        checked={
                                                            urlDefaultPolicyApple === "blockALL" ? true : false
                                                        }
                                                    />
                                                    <label
                                                        className="form-check-label d-flex"
                                                        htmlFor="flexUrlAppleBlockAll"
                                                    >
                                                        <p>
                                                            {t("blockEveryThing___i18n")}
                                                            <br />
                                                            <span>{t("exceptWhiteList___i18n")}</span>{" "}
                                                        </p>
                                                        {/* <span className="loading-spinner">
                          {handleDisabledCheckBox(
                            "urlDefaultPolicyApple",
                            "blockALL"
                          ) && <Spin indicator={<LoadingOutlined />}></Spin>}
                        </span>   */}
                                                    </label>
                                                    <Tooltip
                                                        placement="bottom"
                                                        title={
                                                            t("blockEveryThingTooltipMessage___i18n")
                                                        }
                                                    >
                                                        <span className="approval-list-icon">
                                                            {settingPageIcons["whitelist"]}
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                {/* <div className="form-check website-detail">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexUrlApple"
                        id="flexUrlAppleBlockCss"
                        onChange={() =>
                          handleChangeSetting(
                            "urlDefaultPolicyApple",
                            "blockCSS"
                          )
                        }
                        checked={
                          urlDefaultPolicyApple === "blockCSS" ? true : false
                        }
                      />
                      <label
                        className="form-check-label d-flex"
                        htmlFor="flexUrlAppleBlockCss"
                      >
                        <p>
                          Block image <br />
                          <span>
                            No image or video. except the whitelist
                          </span>{" "}
                        </p>
                        {/* <span className="loading-spinner">
                          {handleDisabledCheckBox(
                            "urlDefaultPolicyApple",
                            "blockCSS"
                          ) && <Spin indicator={<LoadingOutlined />}></Spin>}
                          </span>   * /}
                      </label>
                      <Tooltip
                          placement="bottom"
                          title={
                            t("blockEveryThingTooltipMessage___i18n")
                          }
                        >
                          <span className="approval-list-icon">
                            {settingPageIcons["whitelist"]}
                          </span>
                        </Tooltip>
                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'accordion-item ' + checkReason(blockAppleLogoutReason)}>
                                        {getReasonTimer(blockAppleLogoutReason) && checkReason(blockAppleLogoutReason) == 'reason-melting'
                                            && <Coundown reasonTimer={getReasonTimer(blockAppleLogoutReason)} />}
                                        {
                                            (['reason-deniedSimpleLock', 'reason-deniedByHumanParent', 'reason-sentToHumanParent'].includes(checkReason(blockAppleLogoutReason))) &&
                                            <div className="ok-button"><button type="button" className="btn btn-light" onClick={() => handleOkbutton("blockAppleLogout", blockAppleLogoutReason)}>Ok</button></div>
                                        }
                                        <h2
                                            className="accordion-header androied-header"
                                            id="flush-headingTwo"
                                        >
                                            <div
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#apple-collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="apple-collapseTwo"
                                            >
                                                {settingPageIcons["logout"]}
                                                <p>
                                                    {t("hideLogoutOption___i18n")}
                                                    <br />
                                                    <span>{blockAppleLogout ? "Hidden" : "Show"}</span>
                                                </p>
                                            </div>
                                        </h2>
                                        <div
                                            id="apple-collapseTwo"
                                            className="accordion-collapse collapse"
                                            aria-labelledby="flush-headingTwo"
                                            data-bs-parent="#appleSettingAccordian"
                                        >
                                            <div className="accordion-body">
                                                <div className="form-check website-detail">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexAppleLogout"
                                                        id="flexAppleLogoutShow"
                                                        onChange={() =>
                                                            handleChangeSetting("blockAppleLogout", false)
                                                        }
                                                        checked={blockAppleLogout ? false : true}
                                                    />
                                                    <label
                                                        className="form-check-label d-flex"
                                                        htmlFor="flexAppleLogoutShow"
                                                    >
                                                        <p>
                                                            {t("show___i18n")} <br />
                                                            <span>{t("notRecommendedAllowBypass___i18n")}</span>{" "}
                                                        </p>
                                                        {/* <span className="loading-spinner">
                          {blockAppleLogout &&
                            handleDisabledCheckBox(
                              "blockAppleLogout",
                              "blockAppleLogout"
                            ) && <Spin indicator={<LoadingOutlined />}></Spin>}
                        </span> */}
                                                    </label>
                                                </div>
                                                <div className="form-check website-detail">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexAppleLogout"
                                                        onChange={() =>
                                                            handleChangeSetting("blockAppleLogout", true)
                                                        }
                                                        checked={blockAppleLogout ? true : false}
                                                        id="flexAppleLogoutHidden"
                                                    />
                                                    <label
                                                        className="form-check-label d-flex"
                                                        htmlFor="flexAppleLogoutHidden"
                                                    >
                                                        <p>
                                                            {t("hidden___i18n")} <br />
                                                            <span>
                                                                {t("recommendedBlockBypassPossible___i18n")}
                                                            </span>{" "}
                                                        </p>
                                                        {/* <span className="loading-spinner">
                          {!blockAppleLogout &&
                            handleDisabledCheckBox(
                              "blockAppleLogout",
                              "blockAppleLogout"
                            ) && <Spin indicator={<LoadingOutlined />}></Spin>}
                        </span> */}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
        </div>
    );
}
