import React, { useEffect, useState } from "react";
import LoginBtn from "./ui/LoginBtn.jsx";
import LanguageSwitcher from "./ui/LanguageSwitcher.jsx";
import Navbar from "./Navbar.jsx";

const MobileMenu = ({ isOpen, close }) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 728) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", handleResize);
    window.addEventListener("resize", handleResize);
  });
  return (
    <div className={!isOpen ? "mobile__menu" : "mobile__menu open"}>
      <Navbar close={close} />
      {/* <div className="mobile__menu-actions">
        <LoginBtn />
        <LanguageSwitcher />
      </div> */}
    </div>
  );
};

export default MobileMenu;
