/* eslint-disable react-hooks/rules-of-hooks */
import { LS_VARIABLE, USER_TYPE } from "description/constant";
import { useSelector } from "react-redux";
import { useRouter } from "hooks/use-router";

export default function sidebar() {
    const { selectedCategory, selectedId } = useSelector((state) => state.users);
    const userSettingInfo = useSelector((state) => state.users.userList[selectedId]);
    const userInfo = JSON.parse(localStorage.getItem(LS_VARIABLE.LOGGED_USER_INFO));
    const { push, pathname } = useRouter();
    const profile = [USER_TYPE.PARENT_IN_CHILD, USER_TYPE.CHILD_IN_PARENT].includes(selectedCategory)
        ? userSettingInfo?.userInfo || {
            firstName: userSettingInfo?.firstName,
            email: userSettingInfo?.email,
        }
        : userInfo;

    return { pathname, profile, selectedCategory, push };
}
