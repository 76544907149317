import React from "react";
import { useTranslation } from "react-i18next";
import {
  heroLogo,
  appStoreIcon,
  googlePlayIcon,
  lockteenLogo,
} from "component/Containers/NewLandingPage/imports";

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className="hero">
      <div className="container">
        <div className="hero__inner">
          <div className="hero__content">
            <div className="hero__logo">
              <img src={lockteenLogo} alt="" />
            </div>
            {/* <div className="hero__info">
              { <h2 className="hero__title">{t("HeroTitle_i18n")}</h2> }
              { <p className="hero__text">{t("HeroText_i18n")}</p> }
            </div> */}
            <div className="hero__apps">
              <ul className="hero__apps-list">
                <li className="hero__apps-item">
                  <a
                    href="https://apps.apple.com/mn/app/teleteens/id1516268121"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="hero__apps-icon"
                      src={appStoreIcon}
                      alt="Apple store"
                    />
                  </a>
                  <span className="hero__apps-title">iOS</span>
                </li>
                <li className="hero__apps-item">
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/webpage-teleteens.appspot.com/o/Teleteens%20X%208.6.3%20v2.apk?alt=media&token=f8f166f4-4691-418e-ac01-f8080309bd25 "
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="hero__apps-icon"
                      src={googlePlayIcon}
                      alt="Google Play"
                    />
                  </a>
                  <span className="hero__apps-title">Android</span>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="hero__image">
            <img src={heroImage} alt="" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
