import React, { useEffect, useState } from 'react';

const Countdown = ({ reasonTimer, frontColor = "#fff", frontWidth = 3, backColor = "#6c757d", backWidth = 1, rounded = true, innerColor = "none", size = "small" }) => {
  const [value, setValue] = useState(0);
  const [countdown, setCountdown] = useState('');

  const startCountdown = () => {
    console.log(reasonTimer)
    const targetDate = new Date(reasonTimer.targetDate.seconds * 1000);
    const startDate = new Date(reasonTimer.startDate.seconds * 1000);
    const duration = targetDate - startDate;

    const interval = setInterval(() => {
      const now = new Date();
      const remainingTime = targetDate - now;

      if (remainingTime <= 0) {
        clearInterval(interval);
        setCountdown('Completed!');
      } else {
        const seconds = Math.floor((remainingTime / 1000) % 60);
        const minutes = Math.floor((remainingTime / 1000 / 60) % 60);
        const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));

        setCountdown(`${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);

        const progress = ((duration - remainingTime) / duration) * 100;
        setValue(progress);
      }
    }, 1000);
  }

  useEffect(() => {
    startCountdown();
    // return () => {
    //   // Clean up the interval on component unmount
    //   clearInterval(interval);
    // };
  }, []);

  const calcValue = (283 * value) / 100;

  return (
    <div className="countdown-container">
      <div className={['progress', size].join(' ')}>
        <svg strokeWidth={backWidth} className="progress__back" viewBox="0 0 100 100" stroke={backColor} fill={innerColor}>
          <circle cx="50" cy="50" r="45"></circle>
        </svg>
        <svg strokeLinecap={rounded ? 'round' : ''} strokeWidth={frontWidth} strokeDashoffset={calcValue} stroke={frontColor} className="progress__front" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="45"></circle>
        </svg>
        <div className="countdown-timer">
          {countdown}
        </div>
      </div>
    </div>
  );
}

export default Countdown;
