import React from "react";
import { Alert , Spin } from "antd";
import removeAiParent from "component/Containers/User/RemoveAiParent";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function RemoveAiParent() {
    const {
        message,
        typeOfAlert,
        handleCloseAlert,
        onSubmit,
        loader,
        parentsAi,
    } = removeAiParent();
    const { t } = useTranslation();

    const AIParentFaqsList = [
        {
            id: 1,
            question: t("AI_q1_faq___i18n"),
            answer: t("AI_a1_faq___i18n")
        },
        {
            id: 2,
            question: t("AI_q2_faq___i18n"),
            answer: t("AI_a2_faq___i18n")
        },
        {
            id: 3,
            question: t("AI_q3_faq___i18n"),
            answer: t("AI_a3_faq___i18n")
        },
        {
            id: 4,
            question: t("AI_q4_faq___i18n"),
            answer: t("AI_a4_faq___i18n")
        },
        {
            id: 5,
            question: t("AI_q5_faq___i18n"),
            answer: t("AI_a5_faq___i18n")
        },
    ]
    const faqs = () => {
        return (
            <div className="accordion accordion-flush" id="faqAccordian">
                {
                    AIParentFaqsList.map((el) => (
                        <div className="accordion-item">
                            <h2 className="accordion-header androied-header" id={"flush-headingfaq" + el.id}>
                                <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapsefaq" + el.id} aria-expanded="false" aria-controls={"flush-collapsefaq" + el.id}>
                                    <p>{el.question}<br /><span>{el.answer.slice(0, 40) + "..."}</span></p>
                                </div>
                            </h2>
                            <div id={"flush-collapsefaq" + el.id} className="accordion-collapse collapse" aria-labelledby={"flush-headingfaq" + el.id} data-bs-parent="#faqAccordian">
                                <div className="accordion-body">
                                    <p>{el.answer}</p>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    const backFAQsAccordian = () => {
        return (
            <div className="accordion accordion-flush" id="backFAQsAccordian">
                <div className="accordion-item pb-0">
                    <h2 className="accordion-header androied-header" id="flush-headingOne-back-faq">
                        <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne-back-faq" aria-expanded="false" aria-controls="flush-collapseOne">
                            <p>{t("backToFaq___i18n")}<br /><span>{t("readItAnytimeYouWant___i18n")}</span></p>
                        </div>
                    </h2>
                    <div id="flush-collapseOne-back-faq" className="accordion-collapse collapse" aria-labelledby="flush-headingOne-back-faq" data-bs-parent="#backFAQsAccordian">
                        <div className="accordion-body p-0">
                            {faqs()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="ai-parent-wrp sidebar-right-side side-bar-menu mt-5 overflow-hidden">
                {message && (
                    <div>
                        <Alert
                            message={message}
                            type={typeOfAlert}
                            closable
                            showIcon
                            onClose={handleCloseAlert}
                        />
                    </div>
                )}
                {parentsAi.length > 0 ? (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-5">
                                    <h3 style={{ fontSize: "19px", fontWeight: "700" }}>{t("generalSetting___i18n")}</h3>
                                </div>
                                <div className="ai-parent-sugetions">
                                    {t("yourAIParent___i18n")}
                                </div>
                                <div className="ai-prompt" dangerouslySetInnerHTML={{ __html: JSON.parse(parentsAi[0].prompt).replace(/\n/g, '<br />') }}></div>

                                <div class="col-sm-12 my-5">
                                    <button className={"btn btn-danger"} onClick={(e) => onSubmit(e, parentsAi[0].id, parentsAi[0].prompt)}>
                                        {t("removeAiParent___i18n")}
                                    </button>
                                    {loader && (
                                        <Spin indicator={<LoadingOutlined />} className="mx-2"></Spin>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-12">
                                {backFAQsAccordian()}
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="container-fluid">
                            <div className="row">{t("currentlyDontAiParent__i18n")}</div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}
