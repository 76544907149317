import { useEffect, useState } from "react";
import db from "config/firebase";
import { handleDeniedActionReasons } from "shared/CommanFunction";
import { useSelector } from "react-redux";
import Map from "component/Containers/User/Map";
import { modeTimes , scheduleList, TYPE_OF_STATUS, TYPE_OF_ACTION } from "description/constant";
import firebase from 'firebase/app'

export default function Morning() {

  // location
  const [location, setLocation] = useState();
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);
  const [impactZoneRadius,setImpactZoneRadius]=useState("");
  const [punishment, setPunishment] = useState("");
  const [reward, setReward] = useState("")
  const { selectedCategory, selectedId, userInfo } = useSelector((state) => state.users);
  const userSettingInfo = useSelector((state) => state.users.userList[selectedId]);
  const {timeZone,morningMode} = userSettingInfo || null;
  const morningMode_reason = userSettingInfo?.morningMode_reason || []


  const getLocation = () => {
    if (!navigator.geolocation) {
        setStatus('Geolocation is not supported by your browser');
    } else {
        setStatus('Locating...');
        navigator.geolocation.getCurrentPosition((position) => {
               
                setLat(position.coords.latitude);
                setLng(position.coords.longitude);
        }, () => {
              setStatus('Unable to retrieve your location');
        });
    }
  }
  const handleLocation = (loc) => {
    setLocation(loc);
  };
  const showMapScreen = () => {
    return (
      <>
        {/* <h6>{status}</h6> */}
        <div>
           <Map
              defaultLocation={{
                  lat,
                  lng
              }}
              handleLocation={handleLocation}
              location={location}
              flag={true}
            />
        </div>
      </>
    );
  };

  const impactZoneRadiusChange = (value)=>{
    setImpactZoneRadius(value);
  }
  const onPunishmentChange = (value)=>{
    setPunishment(value);
  }
  const onRewardChange = (value)=>{
    setReward(value);
  }
  useEffect(() => {
      getLocation();
  },[])

  const setInitialData=()=>{
    setBeginHour("")
    setEndHour()
    setLocation()
    setPunishment("")
    setReward("")
    setDayOfWeek(dayOfWeekData)
    setImpactZoneRadius("")
    setShowUntill(false)
  }

  useEffect(()=>{
    if(morningMode && Object.keys(morningMode).length>0){
      setBeginHour(morningMode.beginHour)
      setDayOfWeek(morningMode.dayOfWeek)
      setEndHour(morningMode.endHour)
      setLocation(morningMode.location)
      setPunishment(morningMode.punishment)
      setReward(morningMode.reward)
      setImpactZoneRadius(morningMode.impactZoneRadius)
    } else setInitialData()
  },[morningMode])

  // time values  
  let fromOptionsGroup = { ...modeTimes };
  let blankValuesGroup = {
    hour: "00",
    minute: "00",
  };
  fromOptionsGroup.hour = fromOptionsGroup.hour.filter((hour) => hour !== "24");
  let dayOfWeekData ={
    "1_sunday": false, // Sunday
    "2_monday": false, // Monday
    "3_tuesday": false, // Tuesday
    "4_wednesday": false, // Wednesday
    "5_thursday": false, // Thursday
    "6_friday": false, // Friday
    "7_saturday": false, // Saturday
  }
  const [fromOptions, setFromOptions] = useState({ ...fromOptionsGroup });
  const [untillOptions, setUntillOptions] = useState({ ...modeTimes });
  const [fromValue, setFromValue] = useState(blankValuesGroup);
  const [untillValue, setUntillValue] = useState(blankValuesGroup);
  const [showUntill, setShowUntill] = useState(false);

  const[beginHour,setBeginHour] = useState('')
  const [endHour, setEndHour] = useState('')

  const [dayOfWeek, setDayOfWeek] = useState(dayOfWeekData);


  const handleDaysCheckboxChange = (day) => {
    setDayOfWeek((prevDays) => ({
      ...prevDays,
      [day]: !prevDays[day],
    }));
  };
  
  const handleUntillChange = (name, value) => {
    if (name == "hour" && fromValue.hour == value) {
      let updated_min = [...modeTimes.minute].filter(
        (item) => parseInt(item) > fromValue.minute
      );
      setUntillValue({ hour: value, minute: updated_min[0] });
      setUntillOptions({ ...untillOptions, minute: updated_min });
    } else if (
      (name == "hour" && value == "24") ||
      (name == "minute" && untillValue.hour == "24")
    ) {
      setUntillValue({ hour: "24", minute: "00" });
      setUntillOptions({ ...untillOptions, minute: ["00"] });
    } else {
      setUntillValue({ ...untillValue, [name]: value });
      setUntillOptions({
        ...untillOptions,
        minute: [...modeTimes.minute],
      });
    }
  };
  useEffect(()=>{
    setEndHour(`${untillValue.hour}:${untillValue.minute}`)
  },[untillValue])
  const onPickerHover = () => {
    document.body.classList.add("remove-scroll");
  };
  const onRemovePickerHover = () => {
    document.body.classList.remove("remove-scroll");
  };
  const updateUntillHours = () => {
    setBeginHour(`${fromValue.hour}:${fromValue.minute}`)
    setShowUntill(true);
    let tempOption = { ...modeTimes };
    if (fromValue.minute == "45")
      tempOption.hour = tempOption.hour.filter(
        (item) => parseInt(item) > parseInt(fromValue.hour)
      );
    else {
      tempOption.hour = tempOption.hour.filter(
        (item) => parseInt(item) >= parseInt(fromValue.hour)
      );
      tempOption.minute = tempOption.minute.filter(
        (item) => parseInt(item) > parseInt(fromValue.minute)
      );
    }
    setUntillValue({
      hour: tempOption.hour[0],
      minute: tempOption.minute[0],
    });
    setUntillOptions(tempOption);
  };
  const handleFromChange = (name, value) => {
    setFromValue({ ...fromValue, [name]: value });
    setShowUntill(false);
  };
  const handleTimeZoneChange = (event)=>{
    let value=event.target.value
    const date = new Date().getTime();
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date}-${TYPE_OF_ACTION.CHANGE_TIME_ZONE}`;
    db.doc(databaseUrl).set({
      userId: userSettingInfo?.email,
      actionId: `${date}-${TYPE_OF_ACTION.CHANGE_TIME_ZONE}`,
      actionType: `${TYPE_OF_ACTION.CHANGE_TIME_ZONE}:${value}`,
      name:"change time zone",
      status: TYPE_OF_STATUS.PENDING,
      createdTime: new Date(),
      reason:'' ,
      detail:null
    });
  }

  const hasDaySelected=() =>{
    for (const key in dayOfWeek) {
      if (dayOfWeek[key] === true) {
        return true;
      }
    }
    return false;
  }

  const isAllDataFilled = () =>{
    if(fromValue && untillValue && hasDaySelected() && impactZoneRadius && location && punishment && reward) return true
    else return false;
  } 

  const handleCreateMorningMode = async() =>{
    const date = new Date().getTime();
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date}-${TYPE_OF_ACTION.ADD_MORNING_MODE}`;
    db.doc(databaseUrl).set({
      actionId: `${date}-${TYPE_OF_ACTION.ADD_MORNING_MODE}`,
      actionType: `${TYPE_OF_ACTION.ADD_MORNING_MODE}`,
      name:"addMorningMode",
      reason:'', 
      status: TYPE_OF_STATUS.PENDING,
      userId: userSettingInfo?.email,
      morningModeRequestDetails:{
        beginHour,
        endHour,
        dayOfWeek:dayOfWeek,
        location,
        impactZoneRadius,
        punishment,
        reward
      },
      createdTime: new Date()
    });
    const userSettingRef = db.collection("Google users").doc(userSettingInfo?.email.toString());

    const updateData = {
      morningMode_reason: firebase.firestore.FieldValue.arrayUnion('pending')
    };

    try {
      await userSettingRef.set(updateData, { merge: true });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }
  const handleDeleteMorningMode = async() =>{
    const date = new Date().getTime();
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date}-${TYPE_OF_ACTION.REMOVE_MORNING_MODE}`;
    db.doc(databaseUrl).set({
      actionId: `${date}-${TYPE_OF_ACTION.REMOVE_MORNING_MODE}`,
      actionType: `${TYPE_OF_ACTION.REMOVE_MORNING_MODE}`,
      name:"removeMorningMode",
      reason:'', 
      status: TYPE_OF_STATUS.PENDING,
      userId: userSettingInfo?.email,
      morningModeRequestDetails:{
        beginHour,
        endHour,
        dayOfWeek:dayOfWeek,
        location,
        impactZoneRadius,
        punishment,
        reward
      },
      createdTime: new Date()
    });
    const userSettingRef = db.collection("Google users").doc(userSettingInfo?.email.toString());

    const updateData = {
      morningMode_reason: firebase.firestore.FieldValue.arrayUnion('pending')
    };

    try {
      await userSettingRef.set(updateData, { merge: true });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }
  const handleOkbutton = async () => {
    let reason = checkReason(morningMode_reason).split('-')[1]
    const userSettingRef = db.collection("Google users").doc(userSettingInfo?.email.toString());

    const updateData = {
      morningMode_reason: firebase.firestore.FieldValue.arrayRemove(reason)
    };

    try {
      await userSettingRef.set(updateData, { merge: true });
    } catch (error) {
      console.error("Error updating document:", error);
    }

  };
  const checkReason=(reason)=> {
    if (reason && reason.length > 0) {
      if (reason.filter(name => typeof name != 'object' && name.includes('premium'))[0]) {
        return "reason-premium";
      }
      else if (reason.filter(name =>  typeof name != 'object' && name.includes('deniedByHumanParent'))[0]) {
        return "reason-deniedByHumanParent";
      } 
       else if (reason.filter(name =>  typeof name != 'object' &&name.includes('deniedSimpleLock'))[0]) {
        return "reason-deniedSimpleLock";
      } 
      else if (reason.filter(name => typeof name != 'object' && name.includes('pending'))[0]) {
        return "reason-pending";
      }
      else if (reason.filter(name => typeof name != 'object'&& name.includes('melting'))[0]) {
        return "reason-melting";
      }
      else if (reason.filter(name => typeof name != 'object' && name.includes('sentToHumanParent'))[0]) {
        return "reason-sentToHumanParent";
      }    
      else if (reason.filter(name =>  typeof name != 'object' &&name.includes('morning'))[0]) {
        return "reason-morning";
      } else if (reason.filter(name =>  typeof name != 'object' &&name.includes('night'))[0]) {
        return "reason-night";
      }
    }
    return "";
  }

  return {
    timeZone,
    selectedCategory,
    handleTimeZoneChange,
    showMapScreen,
    fromOptions,
    untillOptions,
    fromValue,
    untillValue,
    showUntill,
    dayOfWeek,
    handleDaysCheckboxChange,
    handleUntillChange,
    onPickerHover,
    onRemovePickerHover,
    updateUntillHours,
    handleFromChange,
    impactZoneRadius,
    impactZoneRadiusChange,
    onRewardChange,
    onPunishmentChange,
    reward,
    punishment,
    isAllDataFilled,
    handleCreateMorningMode,
    beginHour,
    endHour,
    location,
    morningMode,
    handleDeleteMorningMode,
    checkReason,
    morningMode_reason,
    handleOkbutton
  };
}