import { useMemo } from "react";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";

export function useRouter() {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const params = useParams()
  return useMemo(
    () => ({
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      match,
      location,
      history,
      params
    }),
    [match, params, location, history]
  );
}
