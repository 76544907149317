import React from "react";
import HowItWorks from "../HowItWorks.jsx";
import PremiumHero from "../PremiumHero.jsx";
import PremiumPricing from "../PremiumPricing.jsx";
import { useTranslation } from "react-i18next";
import howItWorksPremium from "component/Containers/NewLandingPage/HowItWorksPremium";
import featuresPremium from "component/Containers/NewLandingPage/FeaturesPremium";
import Features from "../Features.jsx";
import BuyNow from "../BuyNow.jsx";

const PremiumView = () => {
  const { t } = useTranslation();
  const { textsPremium } = howItWorksPremium();
  const { features } = featuresPremium();

  return (
    <main className="main">
      <div className="premium">
        <PremiumHero />
        <div className="premium-in-dark">
          <PremiumPricing title={t("PremiumPricingTitle_i18n")} />
        </div>
        <HowItWorks
          list={textsPremium}
          title={t("HowItWorksPremiumTitle_i18n")}
        />
        <Features list={features} />
        <BuyNow />
      </div>
    </main>
  );
};

export default PremiumView;
