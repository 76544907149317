import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Spin, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useRouter } from "hooks/use-router";
import loginButton from "component/Containers/NewLandingPage/LoginButton";
import { handleSetSeen } from "shared/CommanFunction";

import { lockteenLogo } from "component/Containers/NewLandingPage/imports";
import { handleLoginWithCredentials } from "component/Containers/NewLandingPage/PublicLogin";

export default function LoginPage() {
    const { t } = useTranslation();
    const { pathname } = useRouter();
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        loginStatus,
        handleLogout,
        userInfo,
        handleOpenUserDetails,
        notifications,
        handleLogin,
        loading
    } = loginButton();

    return (
        <div className="login-wrap">
            {loading
                ? <div className="wishlist-loader-section login-loader"><Spin indicator={<LoadingOutlined />}></Spin></div>
                : <>
                    <img src={lockteenLogo} alt="" />
                    <span className="d-flex flex-column">
                        {loginStatus && (
                            <Button
                                className="position-relative nav__item ms-2 me-2 login-google"
                                onClick={() => {
                                    pathname === "/u/notifications" && handleSetSeen(userInfo?.email, notifications);
                                    return handleOpenUserDetails();
                                }}
                            >
                                <img src={userInfo?.picture} alt="" className="google-icon" style={{ borderRadius: "10px" }} />
                                {t("enter_i18n")}
                            </Button>
                        )}

                        {!loginStatus ? (
                            <>
                                <Button className="login-google" color="primary" onClick={handleLogin}>
                                    Google
                                </Button>
                                <Button className="login-google mt-2" color="primary" onClick={() => handleLoginWithCredentials(dispatch, history)}>
                                    Public Sign In
                                </Button>
                            </>
                        ) : (
                            <Button
                                className="position-relative nav__item m-2 login-google"
                                onClick={handleLogout}
                            >
                                <img className="google-icon" src="https://cdn.iconscout.com/icon/free/png-256/free-login-1767822-1502402.png" alt="" />
                                {t("logout_i18n")}
                            </Button>
                        )}
                    </span>
                </>
            }
        </div>
    );
}
