import React from 'react'
import { Link } from 'react-router-dom'

const NotAuthorize = () => {
    return (
        <>
            <div className='notauthorize'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4'>
                            <div className='notauthorize-txt text-center'>
                                <h2>You are not logged in</h2>
                                <p>Please login with your<br></br> Google account to continue</p>
                                <Link to="/login">
                                    <button className='notauthorize-btn'>Back to login page</button>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )
}

export default NotAuthorize