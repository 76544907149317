/* eslint-disable react-hooks/rules-of-hooks */
import i18next from "i18next";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import db from "config/firebase";
import {
  changeLanguage,
  getLanguageCode,
  getLanguageCountry,
} from "shared/CommanFunction";

export default function language() {
  const { userInfo } = useSelector((state) => state.users);
  const parentSettingInfo = useSelector(
    (state) => state.users.userList[userInfo?.email]
  );
  const [selected, setSelected] = useState(
    getLanguageCountry(parentSettingInfo?.language) || "US"
  );

  const handleSelect = async (code) => {
    setSelected(code);
    const flag = getLanguageCode(code);
    changeLanguage(flag, i18next);
    await db
      .collection(process.env.REACT_APP_FIREBASE_COLLECTION)
      .doc(userInfo?.email?.toString())
      .set({ language: flag || "en" }, { merge: true });
  };
  return { selected, handleSelect };
}
