import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, Input, Form, FormGroup, Label, Col, ModalFooter } from 'reactstrap';
import BottomMenu from "component/Containers/User/bottomMenu"
import EditModal from "component/Containers/User/EditModal"
import axios from 'axios';
import db from "config/firebase";
import { TYPE_OF_STATUS, TYPE_OF_ACTION } from "description/constant";
import { useTranslation } from 'react-i18next';


export default function Blacklists() {

  const { selectedId, userInfo } = useSelector((state) => state.users);
  const userSettingInfo = useSelector((state) => state.users.userList[selectedId]);

  const { appBlackListAndroid } = userSettingInfo || { appBlackListAndroid: [] };
  let timerId;
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [apps, setApps] = useState('')
  const [allApps, setAllApps] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [errMsg, setErrMsg] = useState('')
  const [packageName, setPackageName] = useState('')
  const [name, setName] = useState('')
  const [previewImage, setPreviewImage] = useState('')
  const [savedApps, setSavedApps] = useState({})
  const [tempApp, setTempApp] = useState({});
  const [longPress, setLongPress] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    if (currentIndex != 0) onIndexChange();
  }, [currentIndex])

  const onIndexChange = async () => {
    setPackageName(allApps[currentIndex - 1])
    setName('')
    setPreviewImage('')
    // this.url = this.all_urls[this.currentIndex - 1];
    // this.favIconUrl= "",
    // this.imageUrl= "",
    if (Object.keys(savedApps).includes(allApps[currentIndex - 1])) {
      setPackageName(allApps[currentIndex - 1])
      setPreviewImage(savedApps[allApps[currentIndex - 1]].previewImage)
      setName(savedApps[allApps[currentIndex - 1]].name)
    } else {
      try {
        let params = {
          Package: allApps[currentIndex - 1]
        };
        const {
          data,
        } = await axios.get(
          `https://image-extractor-bot.herokuapp.com/Fetch_Android_App`,
          { params }
        );

        let { appProfilePicture, appLink, appPackageName } = data.data;

        setPreviewImage(appProfilePicture)
        setName(appPackageName.split('.')[2])
      } catch (error) {
        console.log(error)
        // this.parseError = error;
      }
    }
    setIsLoading(false)
    // this.isLoading = false;
    // this.parsing = false;
  }
  const toggle = () => {
    setIsAddMode(false)
    setAllApps([])
    setApps('')
    setCurrentIndex(0)
    setShowModal(!showModal);
  }
  const editModaltoggle = () => setShowEditModal(!showEditModal);

  const handleMenuCallback = React.useCallback((newValue) => {
    setIsMenuVisible(newValue);
  }, []);

  const handleEditModelCallback = React.useCallback((newValue) => {
    setIsMenuVisible(false);
    setShowEditModal(newValue);
  }, []);
  const handleDeleteApp = () => {
    const date = new Date().getTime();
    setIsMenuVisible(false);
    console.log(tempApp)
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date}-${TYPE_OF_ACTION.DELETE_BLACKLIST_APP_ANDROID}`;
    db.doc(databaseUrl).set({
      userId: userSettingInfo?.email,
      actionId: `${date}-${TYPE_OF_ACTION.DELETE_BLACKLIST_APP_ANDROID}`,
      actionType: `${TYPE_OF_ACTION.DELETE_BLACKLIST_APP_ANDROID}:${tempApp.packageName}`,
      name: "delete appItem for black list android ",
      status: TYPE_OF_STATUS.PENDING,
      createdTime: new Date(),
      requestDetail: tempApp
    });
  };
  const onEditApp = async () => {
    const updatedAppBlackListAndroidItems = appBlackListAndroid.map(item => {
      if (item.appItem[0].packageName == tempApp.packageName) {
        return {
          appItem: [tempApp]
        };
      } else {
        return item;
      }
    });
    await db.collection("Google users").doc(userSettingInfo?.email.toString()).set({ appBlackListAndroid: updatedAppBlackListAndroidItems }, { merge: true });
    setShowEditModal(false)
  }
  const IsJsonString = (str) => {
    try {
      var json = JSON.parse(str);
      return (typeof json === 'object');
    } catch (e) {
      return false;
    }
  }
  const isValidAndroidPackageId = (packageId) => {
    const androidPackageIdRegex = /^[a-z][a-z\d_]*(\.[a-z\d_]+)+[0-9A-Za-z_]$/;
    return androidPackageIdRegex.test(packageId);
  }
  const onBlackListAppSave = () => {
    const date = new Date().getTime();
    let obj = {
      "appItem": [
        { packageName, name, previewImage }
      ]
    }
    console.log(obj)
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date}-${TYPE_OF_ACTION.ADD_BLACKLIST_APP_ANDROID}`;
    db.doc(databaseUrl).set({
      userId: userSettingInfo?.email,
      actionId: `${date}-${TYPE_OF_ACTION.ADD_BLACKLIST_APP_ANDROID}`,
      actionType: `${TYPE_OF_ACTION.ADD_BLACKLIST_APP_ANDROID}:${packageName}`,
      name: "add appItem for black list android ",
      status: TYPE_OF_STATUS.PENDING,
      createdTime: new Date(),
      requestDetail: obj["appItem"][0]

    });

    db.collection("Google users").doc(userSettingInfo?.email?.toString())
      .set({
        appBlackListAndroid: [...appBlackListAndroid, obj]
      }, { merge: true });
    setSavedApps({ ...savedApps, [packageName]: obj["appItem"][0] })
  }
  const handleMultipleAddApps = async () => {
    // this.submitted = true; 
    // setIsAddMode(true)
    let all_urls = []
    setAllApps([])
    setSavedApps({})
    if (apps && apps != '') {
      if (IsJsonString(apps)) {

        // Parse the 'apps' JSON string to an array of objects
        const parsedApps = JSON.parse(apps);

        // here we assume the JSON is more complex and it's like
        // [{"app_package":"com.android.vending"},{"app_package":"com.android.vending"},{"app_package":"com.android.vending"}]
        if (Array.isArray(parsedApps) && parsedApps.some(app => typeof app === 'object')) {
          // Filter the array of objects to keep only those with valid Android package IDs
          all_urls = parsedApps
            .filter(app => app.app_package && isValidAndroidPackageId(app.app_package))
            // Map the filtered array of objects to an array of strings containing only valid 'app_package' values
            .map(app => app.app_package);
        }
        else {
          // here we assume the JSON is more basic and it's like
          // ["com.android.vending","com.android.vending","com.android.vending"]
          all_urls = JSON.parse(apps).filter((url) => isValidAndroidPackageId(url))
        }
      }
      else {
        apps.split(" ").map((url) =>
          isValidAndroidPackageId(url) ? all_urls.push(url) : null
        );
      }

      if (all_urls && all_urls.length > 0) {
        setAllApps(all_urls)
        setCurrentIndex(1)
        setIsAddMode(true)
        setErrMsg("")
      } else setErrMsg("Please enter valid input")
    }
    else setErrMsg("Please enter app names")
  }
  const onSaveAndNext = () => {
    // this.submitted = true;
    onBlackListAppSave()
    if (currentIndex != allApps.length) setCurrentIndex(currentIndex + 1)
  }
  const onSaveAndPrevious = () => {
    // this.submitted = true;
    // this.onIconSave();
    onBlackListAppSave()
    setCurrentIndex(currentIndex - 1)

  }
  const onNext = () => {
    setCurrentIndex(currentIndex + 1)
  }
  const onPrevious = () => {
    setCurrentIndex(currentIndex - 1)
  }
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleMultipleAddApps();
    }
  }
  const handleTouchStart = () => {
    // setLongPress(false);
    timerId = setTimeout(() => {
      setLongPress(true);
    }, 500);
  };

  // const handleTouchMove = () => {
  //   clearTimeout(timeoutRef.current);
  //   setLongPress(false);
  // };

  const handleTouchEnd = (appData) => {
    clearTimeout(timerId);
    if (longPress) {
      console.log(appData)
      setLongPress(false);
      setTempApp(appData)
      setIsMenuVisible(true)
    }
    setLongPress(false);
  };
  return (
    <>

      <div className="side-bar-menu">
        <div className="mt-3">
          <div className="btn-custom">
            Android <span className="plusicon" onClick={toggle}>
              +
            </span>
          </div>
          <Modal className="blacklistmodal" isOpen={showModal} toggle={toggle}>
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
              {
                !isAddMode ? <div className="modalbodywrap blacklist-01">
                  <Form>
                    <Input
                      id="appName"
                      name="appName"
                      placeholder={t("enterApps___i18n")}
                      type="text"
                      value={apps}
                      onKeyPress={handleKeyPress}
                      onChange={(e) => setApps(e.target.value)}
                    />
                    <Button
                      color="primary"
                      onClick={handleMultipleAddApps}
                    >
                      Go
                    </Button>
                  </Form>
                  <span className='modal-error'>{errMsg}</span>
                </div> :
                  <div className="modalbodywrap blacklist-02 done">
                    <p className="b-number">{currentIndex}/{allApps.length}</p>
                    <Form>

                      <div className="blacklist_wrap">
                        <div className="blacklist_icon_text">

                          <Input
                            id="Name"
                            name="Name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="blacklist_icon_image">
                          <img src={previewImage} />
                        </div>

                      </div>
                    </Form>
                    <br />
                    <Form className="package-form">

                      <FormGroup row>
                        <Label
                          htmlFor="packageName"
                          lg={3}
                        >
                          Package Name:
                        </Label>
                        <Col lg={9}>
                          <Input
                            id="packageName"
                            name="packageName"
                            placeholder="Enter Package Name"
                            type="text"
                            value={packageName}
                            onChange={(e) => setPackageName(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label
                          htmlFor="Name"
                          lg={3}
                        >
                          Name:
                        </Label>
                        <Col lg={9}>
                          <Input
                            id="Name"
                            name="Name"
                            placeholder="Enter Name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label
                          htmlFor="previewImage"
                          lg={3}
                        >
                          Image:
                        </Label>
                        <Col lg={9}>
                          <Input
                            id="previewImage"
                            name="previewImage"
                            placeholder="Enter Preview Image"
                            type="text"
                            value={previewImage}
                            onChange={(e) => setPreviewImage(e.target.value)}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                    {isLoading && <div className='model-loader'>
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    }
                    <div className={`modal-arrow-one ${currentIndex <= 1 || Object.keys(savedApps).includes(packageName) ? 'disable' : ''}`} onClick={onSaveAndPrevious}>
                      <img src="https://whitelist-lockteens.web.app/img/done.66c343c7.svg" alt="" className="first-arrow-bottom" />

                      <img src="https://whitelist-lockteens.web.app/img/undo.be0e6111.svg" alt="" className="last-img" />
                    </div>
                    <div onClick={onSaveAndNext} className={`modal-arrow-two ${Object.keys(savedApps).includes(packageName) ? 'disable' : ''}`}>
                      <img src="https://whitelist-lockteens.web.app/img/done.66c343c7.svg" alt="" className="first-arrow-bottom" />
                      {currentIndex != allApps.length && <img src="https://whitelist-lockteens.web.app/img/redo.cdd37f9e.svg" alt="" className="last-img" />}
                    </div>
                    <div className={`modal-arrow-third ${currentIndex <= 1 ? 'disable' : ''}`} onClick={onPrevious}>
                      <img src="https://whitelist-lockteens.web.app/img/undo.be0e6111.svg" alt="" />
                    </div>
                    <div className={`modal-arrow-last ${currentIndex >= allApps.length ? 'disable' : ''}`} onClick={onNext}>
                      <img src="https://whitelist-lockteens.web.app/img/redo.cdd37f9e.svg" alt="" />
                    </div>
                  </div>
              }
            </ModalBody>

          </Modal>
        </div>

        <div className="blacklist_icon">
          {
            appBlackListAndroid && appBlackListAndroid.map(appItem => {
              return appItem["appItem"].map(appData => {
                return (
                  <div className="blacklist_wrap" onTouchStart={handleTouchStart}
                    onTouchEnd={() => handleTouchEnd(appData)}
                    onMouseDown={handleTouchStart}
                    onMouseUp={() => handleTouchEnd(appData)}
                  >
                    <div className="blacklist_icon_text">
                      <p className="m-0 view-name w-100">{appData.name}</p>
                    </div>
                    <div className="blacklist_icon_image">
                      <img src={appData.previewImage} draggable="false" />
                    </div>
                  </div>
                )
              })
            })
          }
        </div>
        <EditModal
          showEditModal={showEditModal}
          editModaltoggle={editModaltoggle}
          tempApp={tempApp}
          setTempApp={setTempApp}
          onEditApp={onEditApp}
        />
        <BottomMenu
          isVisible={isMenuVisible}
          handleMenuCallback={handleMenuCallback}
          handleEditModelCallback={handleEditModelCallback}
          handleDeleteApp={handleDeleteApp}
        />
      </div>
    </>
  );
}
