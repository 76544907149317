import React from "react";
import {
  iconGmail,
  iconTelegram,
} from "component/Containers/NewLandingPage/imports";
import { useTranslation } from "react-i18next";
import { supportContacts } from "component/Containers/NewLandingPage/Support";
import legalInfo from "component/Containers/NewLandingPage/Legal";
import i18next from "i18next";

const SupportView = () => {
  const { t } = useTranslation();
  const { gmail, tgSupport, tgChannel } = supportContacts;

  const { legal } = legalInfo();

  const renderLegalInfo = legal.map((item) => {
    return (
      <li className="legal__item">
        <a
          href={item.link}
          target="_blank"
          rel="noreferrer"
          className="legal__link"
        >
          {i18next.t(item.title)}
        </a>
      </li>
    );
  });

  return (
    <main>
      <div className="support__view">
        <div className="container">
          <div className="support__view-inner">
            <div className="support__item">
              <h3 className="support__item-title">
                {t("SupportIndividualTitle_i18n")}
              </h3>
              <p className="support__item-text">
                {t("SupportIndividualText_i18n")}
              </p>
              <a href={`mailto:${gmail}`} compo className="support__item-link">
                <img src={iconGmail} alt="" />
                {gmail}
              </a>
            </div>
            <div className="support__item">
              <h3 className="support__item-title">
                {t("SupportGroupTitle_i18n")}
              </h3>
              <p className="support__item-text">{t("SupportGroupText_i18n")}</p>
              <a
                href={`https://t.me/${tgSupport.slice(1)}`}
                target="_blank"
                rel="noreferrer"
                className="support__item-link"
              >
                <img src={iconTelegram} alt="" />
                {tgSupport}
              </a>
              <a
                href={`https://t.me/${tgChannel.slice(1)}`}
                target="_blank"
                rel="noreferrer"
                className="support__item-link"
              >
                <img src={iconTelegram} alt="" />
                {tgChannel}
              </a>
            </div>
          </div>
          <div className="legal">
            <p className="legal-title">{t("SupportLegalTitle_i18n")}</p>
            <ul className="legal__list">{renderLegalInfo}</ul>
          </div>
        </div>
      </div>
    </main>
  );
};

export default SupportView;
