import { currentUserIdToken, db, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "config/firebase";
import { LS_VARIABLE } from "description/constant";
import { getInitialData, getData } from "shared/CommanFunction";
import { appBlackListAndroidItems } from "dummyData/appBlackListAndroidItems";
import { nightModeItems } from "dummyData/nightModeItems";

export const handleLoginWithCredentials = async (dispatch, history) => {
    const email = 'newUserLockteensCom@gmail.com';
    const password = 'newUserLockteensCom@gmail.com';

    try {
        const result = await signInWithEmailAndPassword(email, password);
        const currentUserFBToken = await currentUserIdToken();

        localStorage.setItem(
            LS_VARIABLE.GOOGLE_RESULT,
            JSON.stringify(result)
        );
        localStorage.setItem(
            LS_VARIABLE.FB_USER_TOKEN,
            currentUserFBToken
        );

        if (result.user) {
            const profileData = {
                email: email,
                firstName: "User",
                lastName: "Public",
                id: result.user.uid,
                name: "User Public",
                picture: "",
                verified_email: true,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            };

            localStorage.setItem(
                LS_VARIABLE.IDS,
                JSON.stringify([email])
            );

            const databaseUrl = `Google users/${email}`;
            const initialData = getInitialData(email);
            const snapshotData = await (await db.doc(databaseUrl).get()).data();

            if (snapshotData) {
                const temp = snapshotData
                    ? {
                        firstName: snapshotData?.firstName || profileData.firstName,
                        lastName: snapshotData?.lastName || profileData.lastName,
                        email: snapshotData?.email || profileData.email,
                        id: snapshotData?.id || profileData.id,
                        name: snapshotData?.name || profileData.name,
                        picture: snapshotData?.picture || profileData.picture,
                        verified_email: snapshotData?.verified_email || profileData.verified_email,
                        timeZone: snapshotData?.timeZone || profileData.timeZone
                    }
                    : {};
                localStorage.setItem(
                    LS_VARIABLE.LOGGED_USER_INFO,
                    JSON.stringify(temp)
                );
                const data = getData(initialData, snapshotData);
                await db.doc(databaseUrl).set({ ...data, ...temp }, { merge: true });

                if (!snapshotData.nightModeItems || !snapshotData.nightModeItems.length) {
                    await db.doc(databaseUrl).set({ nightModeItems }, { merge: true });
                }

                if (!snapshotData.appBlackListAndroid || !snapshotData.appBlackListAndroid.length) {
                    await db.doc(databaseUrl).set({ appBlackListAndroid: appBlackListAndroidItems }, { merge: true });
                }

                dispatch({
                    type: "SET_USER_DATA",
                    payload: temp ? temp : {},
                });

            } else { //create doc if there is no user
                const payload = {
                    firstName: profileData.firstName,
                    lastName: profileData.lastName,
                    email: profileData.email,
                    id: profileData.id,
                    name: profileData.name,
                    picture: profileData.picture,
                    verified_email: profileData.verified_email,
                    timeZone: profileData.timeZone
                };

                localStorage.setItem(
                    LS_VARIABLE.LOGGED_USER_INFO,
                    JSON.stringify(payload)
                );
                dispatch({
                    type: "SET_USER_DATA",
                    payload: payload ? payload : {},
                });

                console.log('saving')
                await db.doc(databaseUrl).set({ ...initialData, ...payload, nightModeItems, appBlackListAndroid: appBlackListAndroidItems }, { merge: true });
            }

            dispatch({
                type: "SET_LOGIN_FLAG",
                payload: true,
            });
            history.push("/u");
        }
    } catch (error) {
        console.error(`error`, error);
    }
};
