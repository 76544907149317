import React, { useState } from "react";
import FeatureItem from "./FeatureItem.jsx";
import i18next from "i18next";
import { Trans } from "react-i18next";

const Features = ({ list }) => {

  const renderFeatures = list.map((list, i) => {
    return (
      <FeatureItem
        title={i18next.t(list.title)}
        text={<Trans i18nKey={list.text} />}
        img={list.image}
        key={i}
      />
    );
  });

  return (
    <div className="features">
      <div className="container">
        <div className="features__inner">{renderFeatures}</div>
      </div>
    </div>
  );
};

export default Features;
