import React from "react";
import { Tooltip } from "antd";
import { componentList, sidebarIcon } from "description/constant";
import sidebar from "component/Containers/User/Sidebar";
// import { ColumnHeightOutlined } from "@ant-design/icons";
import { useParams } from 'react-router-dom';
export default function SidebarMenu({ toggle }) {
    const { pathname, selectedCategory, push } = sidebar();
    // console.log("params==>", params)
    // console.log("pathname===>", pathname)
    // const params1 = useParams()
    const paramsData = pathname.split('/')
    const role = paramsData[2]
    const id = paramsData[3]

    if (pathname === "/u/myparents/0/AIbrain") {
        return (
            <div className="sidebar-view">
                <ul>
                    <li
                        className={"nav-item mt-5"}
                        role="presentation"
                        onClick={() => {
                            return push("/u/myparents/0/AIbrain");
                        }}
                    >
                        <button
                            className={`nav-link`}
                            id={`AIbrain-tab`}
                            data-bs-toggle="pill"
                            data-bs-target={`#AIbrain`}
                            type="button"
                            role="tab"
                            aria-controls={"AIbrain"}
                            aria-selected={false}
                        >
                            {sidebarIcon["settings"]}
                        </button>                        
                    </li>
                </ul>
            </div>
        )
    }

    return (
        <div className="sidebar-view">
            <ul>
                {componentList[selectedCategory].map((el, i) => {
                    return (
                        <li
                            key={i}
                            className={el === "approval-lists" ? "nav-item mt-3" : "nav-item"}
                            role="presentation"
                            onClick={() => {
                                return push(`/u/${role}/${id}/${el}`);
                            }}
                        >
                            {/* <Tooltip placement="bottom" title={el} color="#CDDEF1"> */}
                            <button
                                className={`nav-link ${pathname.replace("/", "") === el && "active"
                                    }`}
                                id={`${el}-tab`}
                                data-bs-toggle="pill"
                                data-bs-target={`#${el}`}
                                type="button"
                                role="tab"
                                aria-controls={el}
                                aria-selected={
                                    pathname.replace("/", "") === el ? true : false
                                }
                            >
                                {sidebarIcon[el]}
                            </button>
                            {/* </Tooltip> */}
                            {el === "apps" && <span className="mx-3" />}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
