/* eslint-disable react-hooks/rules-of-hooks */
//It is add kid functionality, user can add kid by giving valid kid id.

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import db from "config/firebase";
import {
  TYPE_OF_ACTION,
  TYPE_OF_STATUS,
  REASON_OF_ACTION,
} from "description/constant";
import { useTranslation } from "react-i18next";

export default function addKid() {
  const { t } = useTranslation();
  const [id, setId] = useState();
  const [parentIds, setParentIds] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState("");
  const [typeOfAlert, setTypeOfAlert] = useState("");
  const { userInfo } = useSelector((state) => state.users);
  const userSettingInfo = useSelector(
    (state) => state.users.userList[userInfo?.email]
  );
  const { parents } = userSettingInfo || { parents: {} };

  // get child ids in array
  useEffect(() => {
    if (parents && parents.length) {
      setParentIds(
        parents
          ?.filter((parent) => parent?.isConfirmed)
          ?.map((parent) => parent.parentId.toString())
      );
    } else {
      setParentIds([]);
    }
  }, [userSettingInfo]);

  useEffect(() => {
    getUpdatedStatus();
  }, [loader]);

  // It is function for getting the status of action which is currently executed by user and set error or success message accordingly.
  const getUpdatedStatus = () => {
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions`;
    db.collection(databaseUrl)
      .where("status", "==", TYPE_OF_STATUS.PENDING)
      .where("actionType", "==", `${TYPE_OF_ACTION.KID_ADD_HUMAN_PARENT}:${id}`)
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length) {
          setLoader(true);
          getUpdatedStatus();
        } else {
          console.log('test')
          setLoader(false);
          isSubmit &&
            db
              .collection(
                `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions`
              )
              .where(
                "actionType",
                "==",
                `${TYPE_OF_ACTION.KID_ADD_HUMAN_PARENT}:${id}`
              )
              .get()
              .then((snapshot) => {
                if (snapshot && snapshot.docs.length) {
                  const { status, reason } = snapshot.docs
                    .map((doc) => doc.data())
                    .pop();
                  if (status === TYPE_OF_STATUS.DENIED) {
                    reason === REASON_OF_ACTION.INVALID_PARENT &&
                      setAlert("You are trying to add invalid parent so your request is denied", "error");
                    reason === REASON_OF_ACTION.UNDER_LOCK &&
                      setAlert(t(`underLock_i18n`), "error");
                    reason === REASON_OF_ACTION.ADD_YOURSELF_AS_PARENT &&
                      setAlert('You can not add yourself as parent', "error");
                    reason === REASON_OF_ACTION.PARENT_ALREADY_EXISTS &&
                      setAlert('This account is already your parent', "error");
                  } else {
                    setAlert(t(`requestSentToKid_i18n`), "success");
                  }
                }
              });
          setIsSubmit(false);
        }
      });
  };

  const setAlert = async (message, type) => {
    setTypeOfAlert(type);
    setMessage(message);
  };

  const onChange = (e) => {
    setId(e.target.value);
  };

  // It is onSubmit function, it pass the action if kid id is valid and not already kid.
  const onSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    if (parentIds.includes(id.toString())) {
      setAlert('This account is already your parent', "error");
      setIsSubmit(false);
      setLoader(false);
    } else {
      const date = new Date().getTime();
      const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.email}/actions/${date} - ${TYPE_OF_ACTION.KID_ADD_HUMAN_PARENT}`;
      db.doc(databaseUrl).set({
        userId: userInfo?.email,
        actionId: `${date}-${TYPE_OF_ACTION.KID_ADD_HUMAN_PARENT}`,
        actionType: `${TYPE_OF_ACTION.KID_ADD_HUMAN_PARENT}:${id}`,
        status: TYPE_OF_STATUS.PENDING,
        createdTime: new Date(),
        name: TYPE_OF_ACTION.KID_ADD_HUMAN_PARENT,
        // reason: ""
      });
      setIsSubmit(true);
    }
  };

  //For automatically close the alert after 5 seconds and clearing the field value.
  message &&
    setTimeout(() => {
      setMessage("");
      setTypeOfAlert("");
      document.querySelector(`input[type="email"]`).value = "";
      setId();
    }, 3000);

  //For closing the alert notifications and clearing the field value.
  const handleCloseAlert = () => {
    setMessage("");
    setTypeOfAlert("");
    document.querySelector(`input[type="email"]`).value = "";
    setId();
  };

  const flag = loader ? true : id ? false : true;

  const fields = [
    {
      type: "email",
      className: "form-control",
      onChange: onChange,
      // onKeyPress: onKeyPress,
      required: true,
      placeholderText:t("addParentEmail__i18n")
    },
    {
      type: "submit",
      className: "ms-2 btn btn-primary",
      disabled: flag,
      value: t("sendButton_i18n"),
    },
  ];

  return { message, typeOfAlert, handleCloseAlert, onSubmit, loader, fields };
}
